import * as React from 'react';
import './TestAuditCheck.scss';
import { Box, Icon, popupController, rsToastify } from '@redskytech/framework/ui';
import PromptItem from '../promptItem/PromptItem';
import {
	assemblyStructure,
	baseParts,
	fcuPartNumbers,
	livePayloadPartNumbers,
	payloadPartNumbers,
	trainerPayloadPartNumbers
} from '../../services/assembly/assembly.data';
import serviceFactory from '../../services/serviceFactory';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { useEffect } from 'react';
import { BasePartType, PartAssemblyType } from '../../services/assembly/IAssemblyService';
import router from '../../utils/router';
import useIsMounted from '../../customHooks/useIsMounted';
import { testFixtureTestMap } from '../../services/testFixture/testFixture.data';
import { TestFixtureType, TestPrompt, optionalTestFixtures } from '../../services/testFixture/ITestFixtureService';
import { StatusIconMap, TestStatus } from '../../pages/testDetailsPage/TestDetailsPage';
import AuditBanner, { AuditErrorSection } from '../auditBanner/AuditBanner';
import AuditPopup, { AuditPopupProps } from '../../popups/auditPopup/AuditPopup';

import { vehicleTestKeys } from '../../pages/flightTestPage/sections/VehicleFlightTestList';
import { trainerPayloadTestKeys } from '../../pages/flightTestPage/sections/TrainerPayloadFlightTestList';
import { livePayloadTestKeys } from '../../pages/flightTestPage/sections/LivePayloadFlightTestList';
import { fcuTestKeys } from '../../pages/flightTestPage/sections/FcuFlightTestList';
import { magTestKeys } from '../../pages/magTestPage/MagTestSection';
import { StringUtils } from '../../utils/utils';

export interface TestAuditCheckProps {
	partId: number;
	serialNumber?: string;
	mode: 'NOTICE' | 'POPUP' | 'BOTH' | 'ICON' | 'ICON_SUMMARY';
	testStats?: (value: number, errorList: string[]) => void;
}

interface TestAuditCheckState {
	type: 'CLEAR' | 'ADD';
	payload?: string[];
}

interface ChecklistItem {
	key: string;
	value: boolean | string;
}

interface AssemblyTasksChecked {
	checklist: ChecklistItem[];
}

const testCompletionReducer = (state: string[], action: TestAuditCheckState) => {
	switch (action.type) {
		case 'CLEAR':
			return [];
		case 'ADD':
			if (action.payload) return [...state, ...action.payload];
			else return state;
		default:
			throw new Error();
	}
};

const TestAuditCheck: React.FC<TestAuditCheckProps> = (props) => {
	const assemblyService = serviceFactory.get('AssemblyService');
	const isMounted = useIsMounted();
	const [isHovered, setIsHovered] = React.useState<boolean>(false);
	const [incompleteTests, dispatchIncompleteTests] = React.useReducer(testCompletionReducer, []);
	const [clearPopup, setClearPopup] = React.useState<boolean>(false);
	const [popupOpenSelected, setPopupOpenSelected] = React.useState<boolean>(false);
	const [testsComplete, setTestsComplete] = React.useState<boolean>(true);
	const [iconLevel, setIconLevel] = React.useState<string>('PENDING');
	const [issueCount, setIssueCount] = React.useState<number>(0);
	const [failCount, setFailCount] = React.useState<number>(0);
	const [isTabletChecklist, setIsTabletChecklist] = React.useState<boolean>(false);

	function convertLevelToStatus(level: Omit<TestPrompt['level'], 'default'>): TestStatus {
		switch (level) {
			case 'FAIL':
				return 'ERROR';
			case 'PASS':
				return 'AUDITPASS';
			case 'INCOMPLETE':
				return 'WARNING';
			case 'PENDING':
			default:
				return 'PENDING';
		}
	}

	useEffect(() => {
		setTestsComplete(true);
		dispatchIncompleteTests({ type: 'CLEAR' });
	}, [props.partId]);

	useEffect(() => {
		if (!props.testStats) return;
		props.testStats(incompleteTests.length, incompleteTests);
	}, [incompleteTests]);

	useEffect(() => {
		if (props.partId === 0) return;
		setIssueCount(0);

		(async () => {
			// is the part a tablet?
			try {
				const res = await ApiRequestV1.getPart({ partId: props.partId });
				if (!isMounted) return;
				if (!res) {
					rsToastify.error('Error getting part information', 'Error');
					return;
				}
				// if part is a tablet, check for the tablet checklist , we don't use assembly checklists for anything else right now
				if (res.partNumber === baseParts['TABLET'].partNumbers[0] && props.serialNumber) {
					await checkForTabletChecklist(res.partNumber, props.serialNumber);
				} else {
					await checkAssemblyTests(props.partId);
				}
			} catch (err) {
				rsToastify.error('Error performing checks', 'Error');
				console.error(err);
			}
		})();
	}, [isMounted, props.partId, props.serialNumber]);

	useEffect(() => {
		if (testsComplete)
			if (issueCount === 0) setIconLevel('PASS');
			else if (failCount > 0) setIconLevel('FAIL');
			else setIconLevel('INCOMPLETE');
	}, [issueCount, testsComplete, failCount]);

	useEffect(() => {
		if (clearPopup || testsComplete) return;

		if (props.mode === 'NOTICE') return;
		if (props.mode === 'ICON') return;
		if (props.mode === 'ICON_SUMMARY') return;
		let popupId = popupController.open<AuditPopupProps>(AuditPopup, {
			title: 'Tests Deficient',
			auditErrors: generateAuditList(incompleteTests),
			message: `Do you want to proceed?`,
			confirmButtonText: 'Continue',
			closeButtonText: 'Cancel',
			onConfirm: () => {
				setClearPopup(true);
			},
			onCancel: () => {
				setClearPopup(true);
				window.open('/assembly/details?pi=' + props.partId, '_blank');
			}
		});
		return () => {
			if (popupId) popupController.closeById(popupId);
		};
	}, [clearPopup, testsComplete, props.mode, incompleteTests]);
	useEffect(() => {
		if (!popupOpenSelected) return;

		if (props.mode === 'NOTICE') return;
		if (props.mode === 'POPUP') return;
		let popupId = popupController.open<AuditPopupProps>(AuditPopup, {
			title: 'Tests Deficient',
			auditErrors: generateAuditList(incompleteTests),
			message: `Click View Details to go to the assembly page.`,
			confirmButtonText: 'View Details',
			closeButtonText: 'Cancel',
			onConfirm: () => {
				setPopupOpenSelected(false);
				window.open('/assembly/details?pi=' + props.partId, '_blank');
			},
			onCancel: () => {
				setPopupOpenSelected(false);
			}
		});
		return () => {
			if (popupId) popupController.closeById(popupId);
		};
	}, [popupOpenSelected, testsComplete, props.mode, incompleteTests]);

	async function checkForTabletChecklist(partNumber: string, serialNumber: string) {
		let result = false;
		try {
			let res = await ApiRequestV1.getPartChecklist({
				partNumber: partNumber,
				serialNumber
			});
			let incompleteTests: string[] = [];

			if (res) {
				setIsTabletChecklist(true);
				let parsed: AssemblyTasksChecked =
					res.assemblyTasksChecked && JSON.parse(res.assemblyTasksChecked.replace(/^"(.*)"$/, '$1'));
				if (parsed) {
					let checkListItems = parsed.checklist.filter(
						(listItem: ChecklistItem) => typeof listItem.value === 'boolean'
					);
					result = checkListItems.every((listItem: ChecklistItem) => listItem.value);

					incompleteTests = checkListItems
						.filter((listItem: ChecklistItem) => !listItem.value)
						.map((listItem: ChecklistItem) => listItem.key);
				} else {
					// no checklist items were found, meaning the tablet checklist has never been saved/executed.
					incompleteTests = ['No checklist data found'];
				}
			}

			if (result) {
				setTestsComplete(true);
				setIconLevel('PASS');
			} else {
				setTestsComplete(false);
				if (incompleteTests.length > 0) {
					dispatchIncompleteTests({ type: 'ADD', payload: ['Tablet Checklist:'] });
					dispatchIncompleteTests({ type: 'ADD', payload: incompleteTests });
					setIconLevel('FAIL');
					setIssueCount((prev) => prev + incompleteTests.length);
				} else {
					setIconLevel('INCOMPLETE');
				}
			}
		} catch (err) {
			console.error(err);
		}
		return result;
	}

	// recursive function to check the assembly of a part
	async function checkAssemblyTests(partIdToCheck: number) {
		let res = await ApiRequestV1.getPart({ partId: partIdToCheck });
		if (!isMounted) return;

		let discoveredAssemblyType: PartAssemblyType | BasePartType | undefined;
		const testResSummary = await ApiRequestV1.getTestResultSummary({ partId: partIdToCheck });

		discoveredAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(res.partNumber);
		if (!discoveredAssemblyType) {
			discoveredAssemblyType = assemblyService.getBasePartTypeFromPartNumber(res.partNumber);
			if (discoveredAssemblyType) {
				// This is a base component, and has no children, so just return
				return;
			} else {
				rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
				return;
			}
		}

		// get the full list of the tests based on the code.
		let officialTestList: string[] = [];
		if (discoveredAssemblyType && testFixtureTestMap.hasOwnProperty(discoveredAssemblyType)) {
			let fixture: TestFixtureType;
			for (fixture in testFixtureTestMap[discoveredAssemblyType]) {
				if (
					!testFixtureTestMap[discoveredAssemblyType].hasOwnProperty(fixture) ||
					optionalTestFixtures.includes(fixture)
				)
					continue;
				for (let test of testFixtureTestMap[discoveredAssemblyType][fixture]!.tests) {
					officialTestList.push(test.testName);
				}
			}
		}

		// add payload appropriate flight tests
		if (trainerPayloadPartNumbers.includes(res.partNumber)) {
			trainerPayloadTestKeys.forEach((key) => {
				officialTestList.push(key);
			});
		}

		// add payload appropriate flight tests
		if (livePayloadPartNumbers.includes(res.partNumber)) {
			livePayloadTestKeys.forEach((key) => {
				officialTestList.push(key);
			});
		}

		// add vehicle appropriate flight tests and mag cal
		if (discoveredAssemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
			vehicleTestKeys.forEach((key) => {
				officialTestList.push(key);
			});
			magTestKeys.forEach((key) => {
				officialTestList.push(key);
			});
		}

		// add fcu tests
		if (fcuPartNumbers.includes(res.partNumber)) {
			fcuTestKeys.forEach((key) => {
				officialTestList.push(key);
			});
		}

		if (!testResSummary) {
			rsToastify.error('Error checking assembly tests', 'Error');
			return;
		}

		// At this point there should have been children since we had required subassemblies (so we were missing a child.)
		if (!isMounted) return;

		let anyIncompleteTests = false;
		let anyFailedTests = false;
		let anyMissingTests = false;
		// go through the official test list, and if any of the tests are missing, add them to the missingTests array

		let missingTests: string[] = [];
		let failedTests: string[] = [];
		let incompleteTests: string[] = [];
		officialTestList.forEach((test) => {
			if (!testResSummary.summary.hasOwnProperty(test)) {
				missingTests.push(test);
				anyMissingTests = true;
			} else if (testResSummary.summary[test].status === 'FAIL') {
				failedTests.push(test);
				anyFailedTests = true;
			} else if (testResSummary.summary[test].status === 'INCOMPLETE') {
				incompleteTests.push(test);
				anyIncompleteTests = true;
			}
		});

		if (anyIncompleteTests || anyFailedTests || anyMissingTests) {
			const partLabel = `${assemblyService.getLabelFromPartNumber(res.partNumber)}:`;
			dispatchIncompleteTests({ type: 'ADD', payload: [partLabel] });
		}

		if (anyIncompleteTests) {
			dispatchIncompleteTests({ type: 'ADD', payload: incompleteTests });
			setIssueCount((prev) => prev + incompleteTests.length);
			setTestsComplete(false);
		}
		if (anyFailedTests) {
			dispatchIncompleteTests({ type: 'ADD', payload: failedTests });
			setIssueCount((prev) => prev + failedTests.length);
			setFailCount((prev) => prev + failedTests.length);
			setTestsComplete(false);
		}
		if (anyMissingTests) {
			dispatchIncompleteTests({ type: 'ADD', payload: missingTests });
			setIssueCount((prev) => prev + missingTests.length);
			setTestsComplete(false);
		}

		const assemblyComponents = assemblyStructure[discoveredAssemblyType];

		if (!assemblyComponents) {
			rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
			return;
		}

		// This shouldn't happen unless we have an assembly with no children it is not a base part.
		if (assemblyComponents.length === 0) {
			return;
		}

		// make a dictionary with a key for every item in required subassemblies, all set to false
		let subassemblyChecklist = assemblyComponents.reduce((obj: any, item: PartAssemblyType | BasePartType) => {
			obj[item] = 'false';
			return obj;
		}, {});

		const partChildren = res.children;
		// At this point there should have been children since we had required subassemblies (so we were missing a child.)
		if (subassemblyChecklist.length > 0 && !partChildren) {
			setTestsComplete(false);
			return;
		}
		// check for children, and call it for all of the children
		partChildren.forEach((child) => {
			let childPartAssemblyType: PartAssemblyType | BasePartType | undefined;
			childPartAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(child.childPartNumber);
			if (!childPartAssemblyType) {
				childPartAssemblyType = assemblyService.getBasePartTypeFromPartNumber(child.childPartNumber);
				if (!childPartAssemblyType) {
					rsToastify.error(
						`Unable to recognize child subassembly type. ${child.childPartNumber}`,
						'Unknown Assembly Type'
					);
				}
			}

			if (childPartAssemblyType && subassemblyChecklist.hasOwnProperty(childPartAssemblyType)) {
				subassemblyChecklist[childPartAssemblyType] = 'true';
			}

			// special check to deal with main board assemblies
			if (
				childPartAssemblyType &&
				childPartAssemblyType.includes('MAIN_BOARD') &&
				subassemblyChecklist.hasOwnProperty('MAIN_BOARD')
			) {
				subassemblyChecklist['MAIN_BOARD'] = 'true';
			}

			// if the parent is a main board assembly, and the child is a main board assembly, we don't want to check it
			if (
				discoveredAssemblyType === 'MAIN_BOARD_ASSEMBLY' &&
				childPartAssemblyType &&
				childPartAssemblyType.includes('MAIN_BOARD')
			)
				return;

			checkAssemblyTests(child.childId).catch((err) => {
				rsToastify.error('Error checking Sub assembly for test audit', 'Error');
				console.error(err);
			});
		});
	}

	function generateAuditList(testStringList: string[]) {
		// format the tests to be human readable
		if (testStringList.length === 0) return [];
		if (testStringList.length === 1) return [{ group: testStringList[0], errors: [] }];
		let outputObjects: AuditErrorSection[] = [];
		let newoutputObject: AuditErrorSection = { group: '', errors: [] };
		testStringList.forEach((test, index) => {
			if (test.endsWith(':')) {
				// indicates a new group, if the old group name was anything other than '' then lets push it
				if (newoutputObject.group !== '') {
					outputObjects.push(newoutputObject);
					newoutputObject = { group: '', errors: [] };
				}
				newoutputObject.group = test.replace(':', '');
			} else {
				newoutputObject.errors.push(StringUtils.convertCamelCaseToHuman(test));
			}
		});
		if (newoutputObject.group !== '') {
			outputObjects.push(newoutputObject);
			newoutputObject = { group: '', errors: [] };
		} else if (isTabletChecklist) {
			newoutputObject.group = 'Tablet Checklist';
			outputObjects.push(newoutputObject);
			newoutputObject = { group: '', errors: [] };
		}
		return outputObjects;
	}

	if (props.mode === 'NOTICE' || props.mode === 'BOTH') {
		return (
			<Box className={'rsTestAuditCheck'}>
				{!testsComplete && (
					<AuditBanner
						level={'warn'}
						buttons={[]}
						disableAudio={true}
						disabled={false}
						id={'auditCheck'}
						position={0}
						text={`Deficient Tests:`}
						auditErrors={generateAuditList(incompleteTests)}
					/>
				)}
			</Box>
		);
	} else if (props.mode === 'POPUP' && !testsComplete && !clearPopup) {
		return <Box className={'rsTestAuditCheck'} />;
	} else if (props.mode === 'ICON') {
		return (
			<Box className={'rsTestAuditCheck'} flexDirection={'row'} gap={4}>
				<Icon {...StatusIconMap[convertLevelToStatus(iconLevel)]} />{' '}
			</Box>
		);
	} else if (props.mode === 'ICON_SUMMARY') {
		return (
			<Box
				className={'rsTestAuditCheck'}
				flexDirection={'row'}
				gap={4}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => {
					setIsHovered(false);
				}}
				onClick={() => {
					if (issueCount > 0) {
						setPopupOpenSelected(true);
					}
				}}
			>
				<Icon cursorPointer={issueCount > 0} {...StatusIconMap[convertLevelToStatus(iconLevel)]} />
				{issueCount ? issueCount : ''}
				{isHovered && issueCount > 0 && (
					<Box className={'toolTip'}>
						<Box className={'container'}>
							<AuditBanner
								level={'warn'}
								buttons={[]}
								disableAudio={true}
								disabled={false}
								id={'auditCheck'}
								position={0}
								text={`Deficient Tests:`}
								auditErrors={generateAuditList(incompleteTests)}
							/>
						</Box>
					</Box>
				)}
			</Box>
		);
	} else return <Box className={'rsTestAuditCheck'} />;
};

export default TestAuditCheck;
