import * as React from 'react';
import { Box, Checkbox, RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';

interface PayloadHighSpeedEngagementProps {}

enum FormKeys {
	ENGAGEMENT_30_1 = 'performHighSpeedEngage30Deg1',
	ENGAGEMENT_30_2 = 'performHighSpeedEngage30Deg2',
	ENGAGEMENT_30_3 = 'performHighSpeedEngage30Deg3'
}

const PayloadHighSpeedEngagement: React.FC<PayloadHighSpeedEngagementProps> = () => {
	const TEST_NAME: TestKey = 'highSpeedEngagements';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.ENGAGEMENT_30_1, res ? res.data[FormKeys.ENGAGEMENT_30_1] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			]),
			new RsFormControl<boolean>(FormKeys.ENGAGEMENT_30_2, res ? res.data[FormKeys.ENGAGEMENT_30_2] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			]),
			new RsFormControl<boolean>(FormKeys.ENGAGEMENT_30_3, res ? res.data[FormKeys.ENGAGEMENT_30_3] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			])
		])
	);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME);
	}

	return (
		<Box className={'rsHighSpeedEngagement'}>
			<Checkbox
				labelText={'Performed 30\u00B0 High Speed Engagement #1'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.ENGAGEMENT_30_1)}
				updateControl={handleUpdateControl}
			/>
			<Checkbox
				labelText={'Performed 30\u00B0 High Speed Engagement #2'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.ENGAGEMENT_30_2)}
				updateControl={handleUpdateControl}
			/>
			<Checkbox
				labelText={'Performed 30\u00B0 High Speed Engagement #3'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.ENGAGEMENT_30_3)}
				updateControl={handleUpdateControl}
			/>
		</Box>
	);
};
export default PayloadHighSpeedEngagement;
