import * as React from 'react';
import './LoadingPopup.scss';
import { Box, Popup, PopupProps } from '@redskytech/framework/ui';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';

export interface LoadingPopupProps extends PopupProps {}

const LoadingPopup: React.FC<LoadingPopupProps> = (props) => {
	return (
		<Popup {...props} preventCloseByBackgroundClick className={'rsLoadingPopupContainer'}>
			<Box className={'rsLoadingPopup'}>
				<LoadingSpinner />
			</Box>
		</Popup>
	);
};

export default LoadingPopup;
