import * as React from 'react';
import './NoResultsMessage.scss';
import { Box, Icon, Label } from '@redskytech/framework/ui';
import colors from '../../../themes/colors.scss?export';

interface NoResultsMessageProps {
	onClear: () => void;
}

const NoResultsMessage: React.FC<NoResultsMessageProps> = (props) => {
	return (
		<Box className={'rsNoResultsMessage'}>
			<Box padding={24}>
				<Icon iconImg={'icon-search'} fontSize={72} color={colors.neutralGrey600} marginBottom={10} />
				<Label variant={'subheader1'} weight={'regular'} mb={8}>
					No results found!
				</Label>
				<Label variant={'body1'} weight={'regular'} mb={8}>
					Try changing the filters or the search term.
				</Label>
				<Label
					className={'clearAll'}
					variant={'body1'}
					weight={'regular'}
					color={colors.primaryBlue500}
					onClick={props.onClear}
				>
					Clear All
				</Label>
			</Box>
		</Box>
	);
};
export default NoResultsMessage;
