import {
	DateUtils as BaseDateUtils,
	ObjectUtils as BaseObjectUtils,
	RegionUtils as BaseRegionUtils,
	StringUtils as BaseStringUtils,
	WebUtils as BaseWebUtils
} from '@redskytech/framework/utils';
import {
	CalibrationLog,
	CalibrationResultOutput,
	CarrierType,
	DutData
} from '../services/calibration/ICalibrationService';
import { TestCommand, TestPrompt, TestResult } from '../services/testFixture/ITestFixtureService';

class StringUtils extends BaseStringUtils {
	static convertCamelCaseToHuman(inputString: string): string {
		let result = '';
		let isPrevCharUpperCase = false;

		for (let i = 0; i < inputString.length; i++) {
			const char = inputString[i];
			if (char === char.toUpperCase() && !isPrevCharUpperCase) {
				result += ' ' + char;
			} else {
				result += char;
			}
			isPrevCharUpperCase = char === char.toUpperCase();
		}

		if (result.length > 0) {
			result = result[0].toUpperCase() + result.slice(1);
		}

		if (result.includes('Esad')) {
			result = result.replace('Esad', 'ESAD');
		}

		if (result.includes('Wam')) {
			result = result.replace('Wam', 'WAM');
		}

		return result;
	}

	static convertEnumToHuman(enumValue: string): string {
		let humanValueArray = enumValue.split('_');
		humanValueArray.forEach((value, index) => {
			humanValueArray[index] = this.capitalizeFirst(value.toLowerCase());
		});
		return humanValueArray.join(' ');
	}
	static convertObjectValuesToStrings(obj: any) {
		if (Array.isArray(obj)) {
			const newArr: any[] = [];
			obj.forEach((item) => {
				if (typeof item === 'object' && item !== null) {
					newArr.push(this.convertObjectValuesToStrings(item));
				} else {
					newArr.push(item?.toString());
				}
			});
			return newArr;
		} else {
			const newObj: any = {};
			for (let key in obj) {
				if (typeof obj[key] === 'object' && obj[key] !== null) {
					newObj[key] = this.convertObjectValuesToStrings(obj[key]);
				} else {
					newObj[key] = obj[key].toString();
				}
			}
			return newObj;
		}
	}

	static convertCarrierType(configType: 'IMU' | 'SIB' | 'BARO'): CarrierType {
		let type: CarrierType;
		if (configType === 'BARO') {
			type = 'MAG_BARO';
		} else type = configType;
		return type;
	}
}

class ObjectUtils extends BaseObjectUtils {
	static uploadAwsFile(file: Buffer, preSignedUrl: string, mimeType: string): Promise<boolean> {
		return new Promise((resolve) => {
			const xhr = new XMLHttpRequest();
			xhr.open('PUT', preSignedUrl, true);
			xhr.setRequestHeader('Content-Type', mimeType);

			xhr.onload = function () {
				resolve(xhr.status === 200);
			};

			xhr.onerror = function () {
				resolve(false);
			};

			xhr.send(file);
		});
	}

	static formatResultObject(obj: Record<string, any>): Record<string, any> {
		const transformedObj: Record<string, any> = {};

		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				// Convert the key to lowercase first character
				const transformedKey = key.charAt(0).toLowerCase() + key.slice(1);

				let transformedValue = obj[key];
				if (key === 'testName') {
					transformedValue = transformedValue.charAt(0).toLowerCase() + transformedValue.slice(1);
				} else if (transformedValue === 'True' || transformedValue === 'true') {
					transformedValue = true;
				} else if (transformedValue === 'False' || transformedValue === 'false') {
					transformedValue = false;
				}

				transformedObj[transformedKey] = transformedValue;
			}
		}

		return transformedObj;
	}

	static isTestResult(item: any): item is TestResult {
		return (
			ObjectUtils.isObject(item) &&
			'testName' in item &&
			'passed' in item &&
			'continuable' in item &&
			'timeStamp' in item
		);
	}
	static isCalibrationLog(item: any): item is CalibrationLog {
		return ObjectUtils.isObject(item) && 'name' in item && 'severity' in item && 'message' in item;
	}
	static isCalibrationResultOutput(item: any): item is CalibrationResultOutput {
		return (
			ObjectUtils.isObject(item) &&
			'name' in item &&
			'testName' in item &&
			'passed' in item &&
			'continuable' in item &&
			'error' in item &&
			'data' in item
		);
	}

	static isDutData(item: any): item is DutData {
		return ObjectUtils.isObject(item) && 'session' in item && 'fixture' in item && 'tray' in item && 'DUT' in item;
	}

	static isTestCommand(item: any): item is TestCommand {
		return ObjectUtils.isObject(item) && 'name' in item && 'command' in item && 'data' in item;
	}

	static isTestPrompt(item: any): item is TestPrompt {
		return (
			ObjectUtils.isObject(item) &&
			'id' in item &&
			'position' in item &&
			'level' in item &&
			'text' in item &&
			'buttons' in item
		);
	}
}

class RegionUtils extends BaseRegionUtils {}

class WebUtils extends BaseWebUtils {
	static async sha256Encode(value: string): Promise<string> {
		const encoder = new TextEncoder();
		const data = encoder.encode(value);
		let hash = await crypto.subtle.digest('SHA-256', data);
		// convert array buffer to string
		let hashArray = Array.from(new Uint8Array(hash));
		return hashArray.map((b) => ('00' + b.toString(16)).slice(-2)).join('');
	}
}

class DateUtils extends BaseDateUtils {
	static convertInputDateToServerDate(dateString: string) {
		return new Date(dateString).toISOString().split('T')[0];
	}
}

export { StringUtils, ObjectUtils, RegionUtils, WebUtils, DateUtils };
