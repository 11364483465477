import * as React from 'react';
import './TableSearchHeader.scss';
import { Box, InputText, Label } from '@redskytech/framework/ui';

interface TableSearchHeaderProps {
	searchValue?: string;
	onChange?: (value: string) => void;
	placeholder: string;
	title: string;
}

const TableSearchHeader: React.FC<TableSearchHeaderProps> = (props) => {
	return (
		<Box className={'rsTableSearchHeader'}>
			<Label variant={'subheader2'} weight={'regular'}>
				{props.title}
			</Label>
			{!!props.onChange && (
				<InputText
					inputMode={'text'}
					placeholder={props.placeholder}
					value={props.searchValue}
					onChange={props.onChange}
					icon={[{ position: 'LEFT', iconImg: 'icon-search' }]}
				/>
			)}
		</Box>
	);
};

export default TableSearchHeader;
