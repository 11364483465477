import * as React from 'react';
import './CarrierAssemblySection.scss';
import { Box, Button, Label, popupController, RsFormControl, RsFormGroup, rsToastify } from '@redskytech/framework/ui';
import { HardwareIdDecoded } from '../../../services/assembly/AssemblyService';
import PageHeader from '../../../components/pageHeader/PageHeader';
import ConfirmPopup, { ConfirmPopupProps } from '../../../popups/confirmPopup/ConfirmPopup';
import { Link } from '@redskytech/framework/996';
import SelectableInputText from '../../../components/selectableInputText/SelectableInputText';
import { calibrationAssemblies } from '../../../services/calibration/calibration.data';
import { useEffect, useState } from 'react';
import serviceFactory from '../../../services/serviceFactory';
import ChecklistSection, { CheckListResult } from './ChecklistSection';
import NotesSection from './NotesSection';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { WebUtils } from '../../../utils/utils';
import AssemblyData = CustomTypes.AssemblyData;

interface CarrierAssemblySectionProps {
	hwid: HardwareIdDecoded;
	onDone: () => void;
}

const CarrierAssemblySection: React.FC<CarrierAssemblySectionProps> = (props) => {
	const assemblyService = serviceFactory.get('AssemblyService');
	const calibrationService = serviceFactory.get('CalibrationService');
	const assemblyInfo = calibrationAssemblies.CARRIER_ASSEMBLY;
	const carrierType = calibrationService.getCarrierTypeFromPartNumber(props.hwid.partNumber);

	const [mainControl] = useState<RsFormControl<string>>(
		new RsFormControl(
			'main',
			`PN1:${props.hwid.partNumber},REV1:${props.hwid.hardwareRevision},SN1:${props.hwid.serialNumber}`
		)
	);
	const [carrierFormGroup, setCarrierFormGroup] = useState<RsFormGroup>(new RsFormGroup([]));
	const [checklistFormGroup, setChecklistFormGroup] = useState<RsFormGroup>(new RsFormGroup([]));
	const [selectedInputIndex, setSelectedInputIndex] = useState<number>(-1);
	const [notesControl, setNotesControl] = useState<RsFormControl<string>>(new RsFormControl<string>('notes', ''));

	const areFormsModified =
		carrierFormGroup.isModified() || checklistFormGroup.isModified() || !notesControl.isAtInitialValue();

	useEffect(() => {
		if (!props.hwid) return;

		if (!carrierType) {
			rsToastify.error('Could not find the carrier type from part number', 'Invalid carrier type');
			return;
		}

		(async function lookupPart() {
			// Here we need to go and look up into the database to see if this assembly has already been built
			let res = await calibrationService.lookupCarrierByNumbers(props.hwid.partNumber, props.hwid.serialNumber);

			if (!res) {
				createCarrierControls([], { checklist: [] }, '');
				return;
			}

			let hwIds: string[] = [];

			for (let i = 0; i < 16; i++) {
				let responseAsAny = res as unknown as any;
				if (responseAsAny[`part${i + 1}Id`])
					hwIds.push(
						`PN1:${responseAsAny[`part${i + 1}PartNumber`]},REV1:${
							responseAsAny[`part${i + 1}HardwareRev`]
						},SN1:${responseAsAny[`part${i + 1}SerialNumber`]}`
					);
				else hwIds.push('');
			}

			let assemblyTasksChecked: CheckListResult = { checklist: [] };
			if (res.assemblyTasksChecked && res.assemblyTasksChecked !== '')
				assemblyTasksChecked = JSON.parse(res.assemblyTasksChecked.replace(/^"(.*)"$/, '$1'));

			createCarrierControls(hwIds, assemblyTasksChecked, res.notes || '');
		})();
	}, [props.hwid]);

	function createCarrierControls(hwIds: string[], assemblyTasksChecked: CheckListResult, notes: string) {
		let inputs: RsFormControl<string>[] = [];

		for (let i = 0; i < 16; i++) {
			if (hwIds.at(i)) inputs.push(new RsFormControl(i.toString(), hwIds.at(i)!));
			else inputs.push(new RsFormControl(i.toString(), ''));
		}
		setCarrierFormGroup(new RsFormGroup(inputs));

		const checkListControls: RsFormControl<boolean>[] = [];
		assemblyInfo.checklist.forEach((_, index) => {
			let isChecked = false;
			if (index < assemblyTasksChecked.checklist.length) {
				isChecked = assemblyTasksChecked.checklist[index].checked;
			}
			checkListControls.push(new RsFormControl<boolean>(index.toString(), isChecked, []));
		});
		setChecklistFormGroup(new RsFormGroup(checkListControls));

		setNotesControl(new RsFormControl<string>('notes', notes, []));
	}

	function handleDiscard() {
		if (!areFormsModified) {
			props.onDone();
			return;
		}

		popupController.open<ConfirmPopupProps>(ConfirmPopup, {
			title: 'Discard Changes?',
			message: 'Are you sure you want to discard your unsaved changes?',
			confirmButtonText: 'Discard',
			onConfirm: () => {
				props.onDone();
			}
		});
	}

	function getSlotAssemblyData(slotNumber: number): AssemblyData | undefined {
		let control = carrierFormGroup.get<string>((slotNumber - 1).toString());
		if (!control.value) return undefined;

		let decoded = assemblyService.decodeHardwareId(control.value);
		if (!decoded) return undefined;

		let basePartType = assemblyService.getBasePartTypeFromPartNumber(decoded.partNumber);
		if (!basePartType) return undefined;

		let convertedSlotName = '';
		switch (carrierType!) {
			case 'IMU':
				convertedSlotName = 'IMU Board';
				break;
			case 'SIB':
				convertedSlotName = 'SIB Board';
				break;
			case 'MAG_BARO':
				convertedSlotName = 'MAG BARO Board';
				break;
		}

		return {
			partNumber: decoded.partNumber,
			hardwareRevision: decoded.hardwareRevision,
			serialNumber: decoded.serialNumber,
			name: convertedSlotName
		};
	}

	async function handleSave(): Promise<boolean> {
		for (let index = 0; index < carrierFormGroup.getKeys().length; index++) {
			let control = carrierFormGroup.get<string>(index.toString());
			if (!control.value) continue;

			let decoded = assemblyService.decodeHardwareId(control.value);
			if (!decoded) {
				rsToastify.error(`Please fix the errors in the form before saving.`, 'Invalid Inputs');
				return false;
			}
		}

		let assemblyTasksChecked: CheckListResult = { checklist: [] };
		for (let index = 0; index < assemblyInfo.checklist.length; index++) {
			let control = checklistFormGroup.get<boolean>(index.toString());
			let checklistItemName = assemblyInfo.checklist[index];
			assemblyTasksChecked.checklist.push({ description: checklistItemName, checked: control.value });
		}

		let notes = notesControl.value;

		let assemblyRequest: CustomTypes.CarrierAssemblyRequest = {
			partNumber: props.hwid.partNumber,
			hardwareRevision: props.hwid.hardwareRevision,
			serialNumber: props.hwid.serialNumber,
			type: carrierType!,
			assemblyTasksChecked: `"${JSON.stringify(assemblyTasksChecked)}"`,
			notes,
			...(getSlotAssemblyData(1) && { slot1: getSlotAssemblyData(1) }),
			...(getSlotAssemblyData(2) && { slot2: getSlotAssemblyData(2) }),
			...(getSlotAssemblyData(3) && { slot3: getSlotAssemblyData(3) }),
			...(getSlotAssemblyData(4) && { slot4: getSlotAssemblyData(4) }),
			...(getSlotAssemblyData(5) && { slot5: getSlotAssemblyData(5) }),
			...(getSlotAssemblyData(6) && { slot6: getSlotAssemblyData(6) }),
			...(getSlotAssemblyData(7) && { slot7: getSlotAssemblyData(7) }),
			...(getSlotAssemblyData(8) && { slot8: getSlotAssemblyData(8) }),
			...(getSlotAssemblyData(9) && { slot9: getSlotAssemblyData(9) }),
			...(getSlotAssemblyData(10) && { slot10: getSlotAssemblyData(10) }),
			...(getSlotAssemblyData(11) && { slot11: getSlotAssemblyData(11) }),
			...(getSlotAssemblyData(12) && { slot12: getSlotAssemblyData(12) }),
			...(getSlotAssemblyData(13) && { slot13: getSlotAssemblyData(13) }),
			...(getSlotAssemblyData(14) && { slot14: getSlotAssemblyData(14) }),
			...(getSlotAssemblyData(15) && { slot15: getSlotAssemblyData(15) }),
			...(getSlotAssemblyData(16) && { slot16: getSlotAssemblyData(16) })
		};

		try {
			await ApiRequestV1.postCarrierAssemble(assemblyRequest);
			rsToastify.success('Successfully saved assembly!', 'Saved Assembly');
			notesControl.updateInitialValue();
			setCarrierFormGroup(carrierFormGroup.cloneDeep().updateInitialValues());
			setChecklistFormGroup(checklistFormGroup.cloneDeep().updateInitialValues());
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error saving assembly.'), 'Assembly Error');
			return false;
		}
		return true;
	}

	async function handleSaveAndClose() {
		if (!(await handleSave())) return;
		props.onDone();
	}

	function renderRightHeaderNode() {
		return (
			<Box display={'flex'} gap={8}>
				<Button look={'outlinedPrimary'} onClick={handleDiscard}>
					{areFormsModified ? 'Discard' : 'Done'}
				</Button>
				{areFormsModified && (
					<>
						<Button look={'outlinedPrimary'} onClick={handleSave}>
							Save
						</Button>
						<Button look={'containedPrimary'} onClick={handleSaveAndClose}>
							Save & Close
						</Button>
					</>
				)}
			</Box>
		);
	}

	function handleClearAll() {
		let allControls = carrierFormGroup.getControls();
		allControls.forEach((control) => {
			control.value = '';
			carrierFormGroup.update(control);
		});
		setCarrierFormGroup(carrierFormGroup.cloneDeep());
	}

	function handleInputUpdate(updatedControl: RsFormControl<string>) {
		setCarrierFormGroup(carrierFormGroup.cloneDeep().update(updatedControl));
	}

	function renderTopAssemblyInfo() {
		return (
			<>
				<Link path={assemblyInfo.workInstructionsLink} external target={'blank'}>
					<Label
						variant={'body1'}
						weight={'regular'}
						className={'workInstructionsLink'}
						mb={32}
						display={'inline-block'}
					>
						Work Instructions
					</Label>
				</Link>
				<SelectableInputText
					labelTitle={assemblyInfo.label || ''}
					control={mainControl}
					isSelected={false}
					isFixed
					onClick={() => {}}
					updateControl={() => {}}
					onBlurOrEnter={async () => {
						return 'VALID';
					}}
				/>
				<Box className={'dividerLine'} margin={'32px 0'} />
				<Button look={'outlinedPrimary'} className={'clearBtn'} onClick={handleClearAll} ml={'auto'} mb={32}>
					CLEAR ALL
				</Button>
			</>
		);
	}

	function renderInputs(): React.ReactNode {
		return carrierFormGroup.getControls().map((control, i) => {
			return (
				<SelectableInputText
					key={i}
					labelTitle={(i + 1).toString()}
					control={control as RsFormControl<string>}
					isSelected={selectedInputIndex === i}
					onClick={() => setSelectedInputIndex(i)}
					updateControl={handleInputUpdate}
					onBlurOrEnter={async (value, enterPressed) => {
						if (!value) return 'VALID';

						// Make sure this is a valid part for this carrier
						// First get base part type
						let hardwareIdDecoded = assemblyService.decodeHardwareId(value);
						if (!hardwareIdDecoded) {
							rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
							return 'Invalid Hardware ID';
						}

						let basePartType = assemblyService.getBasePartTypeFromPartNumber(hardwareIdDecoded.partNumber);
						if (!basePartType) {
							rsToastify.error(`Invalid Part number entered.`, 'Invalid Part Number');
							return `Invalid Part number entered.`;
						}

						// Check if this part is allowed for this carrier type
						if (carrierType !== basePartType) {
							rsToastify.error(`Part type does not match carrier type.`, 'Invalid Part Type');
							return `Part type does not match carrier type.`;
						}

						// Advance to next input if enter was pressed and we are valid
						if (enterPressed) {
							setSelectedInputIndex((selectedInputIndex + 1) % carrierFormGroup.getControls().length);
						}

						return 'VALID';
					}}
				/>
			);
		});
	}

	return (
		<>
			<PageHeader
				title={`Assemble Calibration Carriers`}
				isModified={areFormsModified}
				rightNode={renderRightHeaderNode()}
			/>
			<Box className={'rsCarrierAssemblySection'}>
				{renderTopAssemblyInfo()}
				<Box className={'carrierInputsGrid'}>{renderInputs()}</Box>
				<ChecklistSection
					checklist={checklistFormGroup.getKeys().length === 0 ? [] : assemblyInfo.checklist}
					checklistFormGroup={checklistFormGroup}
					updateChecklistControl={(update) => {
						setChecklistFormGroup(checklistFormGroup.cloneDeep().update(update));
					}}
				/>
				<NotesSection
					notesControl={notesControl}
					updateNotesControl={(updatedControl) => setNotesControl(updatedControl)}
				/>
			</Box>
		</>
	);
};

export default CarrierAssemblySection;
