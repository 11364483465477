import * as React from 'react';
import './UserDetailsForm.scss';
import { Box, LabelInputText, LabelSelect, RsFormControl, RsFormGroup } from '@redskytech/framework/ui';
import colors from '../../themes/colors.scss?export';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { useState } from 'react';

interface UserDetailsFormProps {
	formGroup: RsFormGroup;
	onUpdateControl: (control: RsFormControl<IRsFormControl>) => void;
	isCreate?: boolean;
}

export const UserDetailsFormKeys = {
	FIRST_NAME: 'firstName',
	LAST_NAME: 'lastName',
	EMAIL: 'email',
	ROLE: 'role',
	PASSWORD: 'password'
};

const UserDetailsForm: React.FC<UserDetailsFormProps> = (props) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

	return (
		<Box className={'rsUserDetailsForm'} display={'grid'} gap={24}>
			<Box display={'flex'} gap={16}>
				<LabelInputText
					labelTitle={'First Name'}
					inputMode={'text'}
					control={props.formGroup.get(UserDetailsFormKeys.FIRST_NAME)}
					updateControl={props.onUpdateControl}
				/>
				<LabelInputText
					labelTitle={'Last Name'}
					inputMode={'text'}
					control={props.formGroup.get(UserDetailsFormKeys.LAST_NAME)}
					updateControl={props.onUpdateControl}
				/>
			</Box>
			<LabelInputText
				labelTitle={'Email'}
				inputMode={'text'}
				control={props.formGroup.get(UserDetailsFormKeys.EMAIL)}
				updateControl={props.onUpdateControl}
			/>
			<LabelSelect<{ label: string; value: Model.User['role'] }>
				labelTitle={'Role'}
				options={[
					{ label: 'Admin', value: 'admin' },
					{ label: 'Technician', value: 'technician' },
					{ label: 'NG Technician', value: 'ngTechnician' }
				]}
				control={props.formGroup.get(UserDetailsFormKeys.ROLE)}
				updateControl={props.onUpdateControl}
			/>
			<LabelInputText
				labelTitle={`${props.isCreate ? '' : 'New'} Password`}
				icon={[
					{
						iconImg: isPasswordVisible ? 'icon-eye-slash' : 'icon-eye',
						position: 'RIGHT',
						onClick: () => setIsPasswordVisible((prevState) => !prevState),
						color: colors.neutralGrey600
					}
				]}
				inputMode={'text'}
				type={isPasswordVisible ? 'text' : 'password'}
				control={props.formGroup.get(UserDetailsFormKeys.PASSWORD)}
				updateControl={props.onUpdateControl}
			/>
		</Box>
	);
};
export default UserDetailsForm;
