import * as React from 'react';
import './CalibrationTypeSelectorSection.scss';
import { Box, Button, Label, popupController, RsFormControl, rsToastify } from '@redskytech/framework/ui';
import PageHeader from '../../../components/pageHeader/PageHeader';
import SelectableInputText from '../../../components/selectableInputText/SelectableInputText';
import { useState } from 'react';
import serviceFactory from '../../../services/serviceFactory';
import {
	CalibrationFixtureType,
	FullCalibrationFixtureDetails,
	PartData
} from '../../../services/calibration/ICalibrationService';
import LoadingPopup, { LoadingPopupProps } from '../../../popups/loadingPopup/LoadingPopup';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import { TesterShimStatusData } from '../../../services/socketio/SocketioService';
import loadingPopup from '../../../popups/loadingPopup/LoadingPopup';
import { HardwareIdDecoded } from '../../../services/assembly/AssemblyService';

export interface ConfigDetails {
	fullDetails: FullCalibrationFixtureDetails;
	partData: PartData[];
}

interface CalibrationTypeSelectorSectionProps {
	onCalibrationTypeSelected: (fixtureType: CalibrationFixtureType, configDetails: ConfigDetails) => void;
}

const CalibrationTypeSelectorSection: React.FC<CalibrationTypeSelectorSectionProps> = (props) => {
	const assemblyService = serviceFactory.get('AssemblyService');
	const calibrationService = serviceFactory.get('CalibrationService');
	const socketioService = serviceFactory.get('SocketioService');
	const testerShimStatus = useRecoilValue<TesterShimStatusData>(globalState.testerShimStatus);
	const [calibrationHwidControl, setCalibrationHwidControl] = useState<RsFormControl<string>>(
		new RsFormControl<string>('main', '')
	);
	const [isSelected, setIsSelected] = useState<boolean>(true);
	const [fixtureType, setFixtureType] = useState<CalibrationFixtureType>();
	const [hardwareId, setHardwareId] = useState<HardwareIdDecoded>();

	async function handleConnectCalibration() {
		if (testerShimStatus.status === 'DISCONNECTED') {
			rsToastify.error('Tester shim is not connected.', 'Tester Shim Not Connected');
			return;
		}
		if (!hardwareId || !fixtureType) {
			rsToastify.error('Unable to decode hardware ID or find fixture type', 'Invalid Hardware ID');
			return;
		}
		popupController.open<LoadingPopupProps>(LoadingPopup, {});
		try {
			const details = await calibrationService.getAllDetailsOfCalFixture(
				hardwareId.partNumber,
				hardwareId.serialNumber
			);
			details.fullDetails.fixture = fixtureType === 'SIX_AXIS' ? 'boardSixAxisCalFixture' : 'boardTempCalFixture';
			props.onCalibrationTypeSelected(fixtureType, details);
			socketioService.startCalibrationScript();
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error('Unable to get calibration configuration.', 'Invalid Calibration Details');
			return;
		}
		popupController.close(loadingPopup);
	}

	return (
		<Box className={'rsCalibrationTypeSelectorSection'}>
			<PageHeader title={'Board Calibration'} />
			<Box className={'contentWrapper'}>
				<Label variant={'subheader1'} weight={'regular'}>
					Scan the hardware identifier of the test fixture you want to use.
				</Label>
				<SelectableInputText
					labelTitle={'Test Fixture'}
					control={calibrationHwidControl}
					updateControl={(updatedControl) => {
						setCalibrationHwidControl(updatedControl);
					}}
					isSelected={isSelected}
					onClick={() => {
						setIsSelected(true);
					}}
					onBlurOrEnter={async (value) => {
						if (!value) return 'VALID';
						const hardwareIdDecoded = assemblyService.decodeHardwareId(value);
						if (!hardwareIdDecoded) {
							rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
							return 'Invalid Hardware ID';
						}
						setHardwareId(hardwareIdDecoded);

						const discoveredCalibrationFixtureType =
							calibrationService.getCalibrationFixtureTypeFromPartNumber(hardwareIdDecoded.partNumber);

						if (!discoveredCalibrationFixtureType) {
							rsToastify.error('Unable to recognize this fixture.', 'Unknown Fixture Type');
							return 'Unknown Fixture Type';
						}

						setFixtureType(discoveredCalibrationFixtureType);
						return 'VALID';
					}}
				/>
			</Box>
			<Button
				look={'containedPrimary'}
				onClick={handleConnectCalibration}
				margin={'56px auto'}
				disabled={!hardwareId || !fixtureType}
			>
				Connect
			</Button>
		</Box>
	);
};
export default CalibrationTypeSelectorSection;
