import { AssemblyInfo, BasePartInfo, BasePartType, PartAssemblyType } from './IAssemblyService';

/// NOTICE, "label" is also used in the database,
// so updating label here will cause new parts to use the new name,
// but old parts will still use the old name.

// It is desirable to not use multiple part numbers per assembly, but it is possible.
export const partAssemblies: { [key in PartAssemblyType]: AssemblyInfo } = {
	MILITARY_AIR_VEHICLE_ASSEMBLY: {
		label: 'Military Vehicle',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4247476-200'],
		requiresFirmware: true
	},
	AIR_VEHICLE_ASSEMBLY: {
		label: 'Commercial Vehicle',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4248661-200']
	},
	AIRFRAME_ASSEMBLY: {
		label: 'Airframe',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4248221-100']
	},
	CHASSIS_MAIN_ASSEMBLY: {
		label: 'Chassis Main',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4248220-100'],
		requiresFirmware: true
	},
	MAIN_BOARD_ASSEMBLY: {
		label: 'Main Board Subassembly',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		// 4248225-100 is the main board subassembly after parts added. This sticker does not exist.
		// 4246146-450 is the main board subassembly before parts added. This sticker does exist and will be the one scanned.
		partNumbers: ['4246146-450', '4248225-100']
	},

	// Payloads
	NG_PENETRATOR_PAYLOAD_ASSEMBLY: {
		label: 'Penetrator Payload (NG)',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4246555-200']
	},
	NG_FRAG_PAYLOAD_ASSEMBLY: {
		label: 'Frag Payload (NG)',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4246556-200']
	},
	PENETRATOR_PAYLOAD_ASSEMBLY: {
		label: 'Penetrator Payload (BZN)',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4246559-100'],
		requiresFirmware: true
	},
	INERT_PENETRATOR_PAYLOAD_ASSEMBLY: {
		label: 'Penetrator Payload (INERT)',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4252442-200'],
		requiresFirmware: true
	},
	FRAG_PAYLOAD_ASSEMBLY: {
		label: 'Frag Payload (BZN)',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4246560-100'],
		requiresFirmware: true
	},
	INERT_FRAG_PAYLOAD_ASSEMBLY: {
		label: 'FRAG Payload (INERT)',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4252443-200'],
		requiresFirmware: true
	},
	TRAINER_PAYLOAD_ASSEMBLY: {
		label: 'Trainer Payload (BZN)',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4246558-200'],
		requiresFirmware: true
	},

	// Centerstages
	INERT_PENETRATOR_CENTERSTAGE_ASSEMBLY: {
		label: 'Inert Penetrator Centerstage',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4252684-4']
	},
	INERT_FRAG_CENTERSTAGE_ASSEMBLY: {
		label: 'Inert Forward Frag Centerstage',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4252684-2']
	},
	PENETRATOR_CENTERSTAGE_ASSEMBLY: {
		label: 'Penetrator Centerstage',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4252684-3']
	},
	FRAG_CENTERSTAGE_ASSEMBLY: {
		label: 'Forward Frag Centerstage',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4252684-1']
	},
	TRAINER_CENTERSTAGE_ASSEMBLY: {
		label: 'Trainer Centerstage',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4250035']
	},

	FCU_ASSEMBLY: {
		label: 'FCU',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4247478-200']
	},
	FCU_ANTENNA_ASSEMBLY: {
		label: 'FCU Antenna',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4250337']
	},
	BATTERY_ASSEMBLY: {
		label: 'Battery',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4247477-200']
	},
	CHARGER_ASSEMBLY: {
		label: 'Charger',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4247482-200']
	},
	CONFORMAL_PACKAGING: {
		label: 'Conformal Packaging',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4250034-200']
	},
	GROUND_CONTROL_STATION_KIT: {
		label: 'Ground Control Station Kit',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['4253066-200']
	}
};

export function getLabelStickerAssemblies(): any[] {
	let stickerAssemblyKeys: string[] = [...Object.keys(partAssemblies), 'TRIPOD'];
	let labelStickerAssemblies: any[] = [];
	for (let key in partAssemblies) {
		if (!stickerAssemblyKeys.includes(key)) continue;

		labelStickerAssemblies.push(partAssemblies[key as keyof typeof partAssemblies]);
	}
	for (let key in baseParts) {
		if (!stickerAssemblyKeys.includes(key)) continue;
		labelStickerAssemblies.push(baseParts[key as keyof typeof baseParts]);
	}

	return labelStickerAssemblies;
}

export const inertPayloadPartTypes: PartAssemblyType[] = [
	'INERT_PENETRATOR_PAYLOAD_ASSEMBLY',
	'INERT_FRAG_PAYLOAD_ASSEMBLY'
];

export const ngAllowedPartNumbers: string[] = [
	...partAssemblies.NG_PENETRATOR_PAYLOAD_ASSEMBLY.partNumbers,
	...partAssemblies.NG_FRAG_PAYLOAD_ASSEMBLY.partNumbers
];

export const centerStagePartNumbers: string[] = [
	...partAssemblies.PENETRATOR_CENTERSTAGE_ASSEMBLY.partNumbers,
	...partAssemblies.FRAG_CENTERSTAGE_ASSEMBLY.partNumbers,
	...partAssemblies.TRAINER_CENTERSTAGE_ASSEMBLY.partNumbers,
	...partAssemblies.INERT_FRAG_CENTERSTAGE_ASSEMBLY.partNumbers,
	...partAssemblies.INERT_PENETRATOR_CENTERSTAGE_ASSEMBLY.partNumbers
];

export const trainerPayloadPartNumbers: string[] = [...partAssemblies.TRAINER_PAYLOAD_ASSEMBLY.partNumbers];

export const livePayloadPartNumbers: string[] = [
	...partAssemblies.NG_PENETRATOR_PAYLOAD_ASSEMBLY.partNumbers,
	...partAssemblies.NG_FRAG_PAYLOAD_ASSEMBLY.partNumbers,
	...partAssemblies.PENETRATOR_PAYLOAD_ASSEMBLY.partNumbers,
	...partAssemblies.FRAG_PAYLOAD_ASSEMBLY.partNumbers,
	...partAssemblies.INERT_FRAG_PAYLOAD_ASSEMBLY.partNumbers,
	...partAssemblies.INERT_PENETRATOR_PAYLOAD_ASSEMBLY.partNumbers
];

export const payloadPartNumbers: string[] = [...trainerPayloadPartNumbers, ...livePayloadPartNumbers];

export const fcuPartNumbers: string[] = [
	...partAssemblies.FCU_ANTENNA_ASSEMBLY.partNumbers,
	...partAssemblies.FCU_ASSEMBLY.partNumbers
];

export const vehiclePartNumbers: string[] = [
	...partAssemblies.AIR_VEHICLE_ASSEMBLY.partNumbers,
	...partAssemblies.MILITARY_AIR_VEHICLE_ASSEMBLY.partNumbers
];

export const autoCreateableAssemblyPartNumbers: string[] = [
	...partAssemblies.BATTERY_ASSEMBLY.partNumbers,
	...partAssemblies.CHARGER_ASSEMBLY.partNumbers
];

export const commercialFirmwareAssemblyPartTypes: string[] = [];

export const baseParts: { [key in BasePartType]: BasePartInfo } = {
	MAG_BARO: {
		label: 'Mag Baro Board',
		partNumbers: ['4246153-450']
	},
	IMU: {
		label: 'IMU Board',
		partNumbers: ['4246158-450']
	},
	SIB: {
		label: 'SIB Board',
		partNumbers: ['4246133-450']
	},
	NX_MODULE: {
		label: 'NX Module',
		partNumbers: ['4248290'],
		onInput: (input) => {
			// scans as 1424221010045,48B02D5A26B7,699-13668-0001-300
			// only take first number as SN.
			let pn = baseParts.NX_MODULE.partNumbers[0];
			let sn = input.split(',')[0];
			return `PN1:${pn},REV1:00,SN1:${sn}`;
		}
	},
	MAIN_BOARD: {
		label: 'Main Board',
		partNumbers: ['4246146-450']
	},
	WASP: {
		label: 'WASP',
		partNumbers: ['A-005723-00'],
		onInput: (input) => {
			// not scan-able, tech inputs 8 digit SN
			let pn = baseParts.WASP.partNumbers[0];
			return `PN1:${pn},REV1:00,SN1:${input}`;
		}
	},
	BOSON_95: {
		label: 'Boson 95',
		partNumbers: ['22640AS95-6IAAX'],
		onInput: (input) => {
			// scans as 21640A024-6PAAX S0118881
			// only take the last 8 digits as SN.
			let pn = baseParts.BOSON_95.partNumbers[0];
			let sn = input.trim().slice(-8);
			return `PN1:${pn},REV1:00,SN1:${sn}`;
		}
	},
	EO_CAMERA: {
		label: 'EO Camera',
		partNumbers: ['4248262'],
		onInput: (input) => {
			// scans SN only
			let pn = baseParts.EO_CAMERA.partNumbers[0];
			return `PN1:${pn},REV1:00,SN1:${input}`;
		}
	},
	BIAS: {
		label: 'BIAS',
		partNumbers: ['4246149-450']
	},
	MOBILICOM_RADIO: {
		label: 'Mobilicom Radio',
		partNumbers: ['4249143'],
		onInput: (input) => {
			// scans as S/N:02979
			// only take number
			let pn = baseParts.MOBILICOM_RADIO.partNumbers[0];
			let sn = input.replace('S/N:', '');
			return `PN1:${pn},REV1:00,SN1:${sn}`;
		}
	},
	ESC_BOARDS: {
		label: 'ESC Board',
		partNumbers: ['4249142'],
		onInput: (input) => {
			// not scan-able, tech inputs 5 digit SN
			let pn = baseParts.ESC_BOARDS.partNumbers[0];
			return `PN1:${pn},REV1:00,SN1:${input}`;
		}
	},
	MOBILICOM_ADAPTER: {
		label: 'Mobilicom Adapter',
		partNumbers: ['4246157-450'],
		onInput: (input) => {
			// scans SN only
			let pn = baseParts.MOBILICOM_ADAPTER.partNumbers[0];
			return `PN1:${pn},REV1:00,SN1:${input}`;
		}
	},
	PROP_DEPLOY_BOARD: {
		label: 'Prop Deploy Board',
		partNumbers: ['4248250-450']
	},
	BOSON_ADAPTER_BOARD: {
		label: 'Boson Adapter Board',
		partNumbers: ['4246150-450'],
		onInput: (input) => {
			// scans as 4246150-450 REV. 1.01, 00027, PNC1723
			// take Rev number and second part, 00027, as SN
			let pn = baseParts.BOSON_ADAPTER_BOARD.partNumbers[0];
			let inputParts = input.split(',');
			let rev = inputParts[0].split('REV.')[1].trim();
			let sn = inputParts[1].trim();
			return `PN1:${pn},REV1:${rev},SN1:${sn}`;
		}
	},
	FRONT_RIGHT_ARM: {
		label: 'Front Right Arm',
		partNumbers: ['4248248-100']
	},
	FRONT_LEFT_ARM: {
		label: 'Front Left Arm',
		partNumbers: ['4248249-100']
	},
	RADIO_ADAPTER_BOARD: {
		label: 'Radio Adapter Board',
		partNumbers: ['4246157-450']
	},
	UMBILICAL_GPS: {
		label: 'Umbilical GPS',
		partNumbers: ['4246152-450']
	},
	PIAS_BOARD: {
		label: 'PIAS Board',
		partNumbers: ['4246147-450']
	},
	REAR_RIGHT_ARM: {
		label: 'Rear Right Arm',
		partNumbers: ['4248222-100']
	},
	REAR_LEFT_ARM: {
		label: 'Rear Left Arm',
		partNumbers: ['4248223-100']
	},
	ENCODER_BOARD: {
		label: 'Encoder Board',
		partNumbers: ['4247315-450']
	},
	BOSON_18MM: {
		label: '18mm Boson',
		partNumbers: ['4249659'],
		onInput: (input) => {
			// scans as 21640A024-6PAAX S0118881
			// only take the last 8 digits as SN.
			let pn = baseParts.BOSON_18MM.partNumbers[0];
			let sn = input.trim().slice(-8);
			return `PN1:${pn},REV1:00,SN1:${sn}`;
		}
	},
	LEOPARD_EO_CAMERA: {
		label: 'Leopard EO Camera',
		partNumbers: ['4249368'],
		onInput: (input) => {
			// scans SN only
			let pn = baseParts.LEOPARD_EO_CAMERA.partNumbers[0];
			return `PN1:${pn},REV1:00,SN1:${input}`;
		}
	},
	PIPS_BOARD: {
		label: 'PIPS board',
		partNumbers: ['4246132-450']
	},
	VSI: {
		label: 'VSI',
		partNumbers: ['4248662-200']
	},
	TABLET: {
		label: 'Tablet',
		partNumbers: ['A-010023-01-01'],
		onInput: (input) => {
			// scans SN only
			let pn = baseParts.TABLET.partNumbers[0];
			return `PN1:${pn},REV1:00,SN1:${input}`;
		}
	},
	TRIPOD: {
		label: 'Tripod',
		partNumbers: ['4250338']
	},
	LEEFI_INITIATOR: {
		label: 'LEEFI Initiator',
		partNumbers: ['4251902'],
		onInput: (input) => {
			// scans SN only
			let pn = baseParts.LEEFI_INITIATOR.partNumbers[0];
			return `PN1:${pn},REV1:00,SN1:${input}`;
		}
	},
	FRAG_MUNITION: {
		label: 'Fragmenting Munition',
		partNumbers: ['4251900'],
		onInput: (input) => {
			// scans SN only
			let pn = baseParts.FRAG_MUNITION.partNumbers[0];
			return `PN1:${pn},REV1:00,SN1:${input}`;
		}
	},
	PENETRATOR_MUNITION: {
		label: 'Penetrator Munition',
		partNumbers: ['4251901'],
		onInput: (input) => {
			// scans SN only
			let pn = baseParts.PENETRATOR_MUNITION.partNumbers[0];
			return `PN1:${pn},REV1:00,SN1:${input}`;
		}
	}
};

export const assemblyStructure: { [key in PartAssemblyType]: (PartAssemblyType | BasePartType)[] } = {
	MILITARY_AIR_VEHICLE_ASSEMBLY: ['AIR_VEHICLE_ASSEMBLY'],
	AIR_VEHICLE_ASSEMBLY: ['CHASSIS_MAIN_ASSEMBLY', 'AIRFRAME_ASSEMBLY'],
	CHASSIS_MAIN_ASSEMBLY: [
		'MAIN_BOARD_ASSEMBLY',
		'WASP',
		'BOSON_95',
		'EO_CAMERA',
		'BIAS',
		'MOBILICOM_RADIO',
		'ESC_BOARDS',
		'ESC_BOARDS',
		'MOBILICOM_ADAPTER',
		'BOSON_ADAPTER_BOARD',
		'FRONT_LEFT_ARM',
		'FRONT_RIGHT_ARM',
		'PROP_DEPLOY_BOARD'
	],
	MAIN_BOARD_ASSEMBLY: ['NX_MODULE', 'MAIN_BOARD', 'IMU'],
	AIRFRAME_ASSEMBLY: ['UMBILICAL_GPS', 'PIAS_BOARD', 'MAG_BARO', 'REAR_RIGHT_ARM', 'REAR_LEFT_ARM'],
	TRAINER_PAYLOAD_ASSEMBLY: ['TRAINER_CENTERSTAGE_ASSEMBLY', 'ENCODER_BOARD', 'PIPS_BOARD'],
	PENETRATOR_PAYLOAD_ASSEMBLY: ['PENETRATOR_CENTERSTAGE_ASSEMBLY', 'ENCODER_BOARD', 'PIPS_BOARD'],
	INERT_PENETRATOR_PAYLOAD_ASSEMBLY: ['INERT_PENETRATOR_CENTERSTAGE_ASSEMBLY', 'ENCODER_BOARD', 'PIPS_BOARD'],
	FRAG_PAYLOAD_ASSEMBLY: ['FRAG_CENTERSTAGE_ASSEMBLY', 'ENCODER_BOARD', 'PIPS_BOARD'],
	INERT_FRAG_PAYLOAD_ASSEMBLY: ['INERT_FRAG_CENTERSTAGE_ASSEMBLY', 'ENCODER_BOARD', 'PIPS_BOARD'],
	NG_FRAG_PAYLOAD_ASSEMBLY: ['FRAG_PAYLOAD_ASSEMBLY', 'FRAG_MUNITION'],
	NG_PENETRATOR_PAYLOAD_ASSEMBLY: ['PENETRATOR_PAYLOAD_ASSEMBLY', 'PENETRATOR_MUNITION'],
	FRAG_CENTERSTAGE_ASSEMBLY: ['ENCODER_BOARD', 'BOSON_18MM', 'LEOPARD_EO_CAMERA', 'BOSON_ADAPTER_BOARD', 'SIB'],
	PENETRATOR_CENTERSTAGE_ASSEMBLY: ['ENCODER_BOARD', 'BOSON_18MM', 'LEOPARD_EO_CAMERA', 'BOSON_ADAPTER_BOARD', 'SIB'],
	INERT_FRAG_CENTERSTAGE_ASSEMBLY: ['ENCODER_BOARD', 'BOSON_18MM', 'LEOPARD_EO_CAMERA', 'BOSON_ADAPTER_BOARD', 'SIB'],
	INERT_PENETRATOR_CENTERSTAGE_ASSEMBLY: [
		'ENCODER_BOARD',
		'BOSON_18MM',
		'LEOPARD_EO_CAMERA',
		'BOSON_ADAPTER_BOARD',
		'SIB'
	],
	TRAINER_CENTERSTAGE_ASSEMBLY: ['ENCODER_BOARD', 'BOSON_18MM', 'LEOPARD_EO_CAMERA', 'BOSON_ADAPTER_BOARD', 'SIB'],
	FCU_ASSEMBLY: ['FCU_ANTENNA_ASSEMBLY', 'TABLET', 'TRIPOD'],
	FCU_ANTENNA_ASSEMBLY: ['MOBILICOM_RADIO'],
	BATTERY_ASSEMBLY: [],
	CHARGER_ASSEMBLY: [],
	CONFORMAL_PACKAGING: [],
	GROUND_CONTROL_STATION_KIT: []
};

export const assemblyValStructure: { [key in PartAssemblyType]: (PartAssemblyType | BasePartType)[] } = {
	MILITARY_AIR_VEHICLE_ASSEMBLY: [],
	AIR_VEHICLE_ASSEMBLY: [],
	CHASSIS_MAIN_ASSEMBLY: [],
	MAIN_BOARD_ASSEMBLY: [],
	AIRFRAME_ASSEMBLY: [],
	TRAINER_PAYLOAD_ASSEMBLY: [],
	PENETRATOR_PAYLOAD_ASSEMBLY: [],
	INERT_PENETRATOR_PAYLOAD_ASSEMBLY: [],
	FRAG_PAYLOAD_ASSEMBLY: [],
	INERT_FRAG_PAYLOAD_ASSEMBLY: [],
	NG_FRAG_PAYLOAD_ASSEMBLY: ['FRAG_CENTERSTAGE_ASSEMBLY'],
	NG_PENETRATOR_PAYLOAD_ASSEMBLY: ['PENETRATOR_CENTERSTAGE_ASSEMBLY'],
	FRAG_CENTERSTAGE_ASSEMBLY: [],
	PENETRATOR_CENTERSTAGE_ASSEMBLY: [],
	INERT_FRAG_CENTERSTAGE_ASSEMBLY: [],
	INERT_PENETRATOR_CENTERSTAGE_ASSEMBLY: [],
	TRAINER_CENTERSTAGE_ASSEMBLY: [],
	FCU_ASSEMBLY: [],
	FCU_ANTENNA_ASSEMBLY: [],
	BATTERY_ASSEMBLY: [],
	CHARGER_ASSEMBLY: [],
	CONFORMAL_PACKAGING: [],
	GROUND_CONTROL_STATION_KIT: []
};
