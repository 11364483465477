import * as React from 'react';
import {
	Box,
	Checkbox,
	LabelSelect,
	popupController,
	RsFormControl,
	RsFormGroup,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useMemo, useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { PartAssemblyType } from '../../../../services/assembly/IAssemblyService';
import WarningPopup, { WarningPopupProps } from '../../../../popups/warningPopup/WarningPopup';

interface LiveRunPreflightProps {
	targetPayloadType: PartAssemblyType;
}

enum FormKeys {
	PAYLOAD_TYPE = 'r1gcsPayloadTypeMatchesExpected',
	LOADED_PAYLOAD = 'payloadType',
	PROD_ENGAGEMENT = 'confirmProductionEngagementNotification'
}

const LiveRunPreflight: React.FC<LiveRunPreflightProps> = (props) => {
	const TEST_NAME: TestKey = 'runPreflight';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	const payloadOptions = useMemo<{ label: string; value: string }[]>(() => {
		return [
			{ label: 'Inert Forward Frag', value: 'INERT_FRAG_PAYLOAD_ASSEMBLY' },
			{ label: 'Inert Penetrator', value: 'INERT_PENETRATOR_PAYLOAD_ASSEMBLY' },
			{ label: 'Forward Frag', value: 'FRAG_PAYLOAD_ASSEMBLY' },
			{ label: 'Penetrator', value: 'PENETRATOR_PAYLOAD_ASSEMBLY' }
		];
	}, []);

	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.PAYLOAD_TYPE, res ? res.data[FormKeys.PAYLOAD_TYPE] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			]),
			new RsFormControl<string>(FormKeys.LOADED_PAYLOAD, res ? res.data[FormKeys.LOADED_PAYLOAD] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return control.value === props.targetPayloadType;
				})
			]),
			new RsFormControl<boolean>(FormKeys.PROD_ENGAGEMENT, res ? res.data[FormKeys.PROD_ENGAGEMENT] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			])
		])
	);

	useEffect(() => {
		if (formGroup.get(FormKeys.LOADED_PAYLOAD).value === '') return;
		if (formGroup.get(FormKeys.LOADED_PAYLOAD).value === props.targetPayloadType) return;

		//find the payload options object that has the value of the selected payload
		let selectedPayload = payloadOptions.find((payload) => payload.value === props.targetPayloadType);

		let popupId = popupController.open<WarningPopupProps>(WarningPopup, {
			title: 'Payload Mismatch',
			message: `Test being executed with incorrect payload, expected ${selectedPayload?.label}.`,
			confirmButtonText: 'Continue',
			onConfirm: () => {}
		});
		return () => {
			if (popupId) popupController.closeById(popupId);
		};
	}, [formGroup.get(FormKeys.LOADED_PAYLOAD).value, props.targetPayloadType]);

	useEffect(() => {
		const formGroupClone = formGroup.cloneDeep();
		const selectedPayload = formGroupClone.get(FormKeys.LOADED_PAYLOAD).value;
		const payloadTypeControl = formGroupClone.get(FormKeys.PAYLOAD_TYPE);
		payloadTypeControl.value = selectedPayload === props.targetPayloadType;
		handleLabelControl(payloadTypeControl);
		testFixtureService.validateFlightTest(formGroup, TEST_NAME);
	}, [formGroup.get(FormKeys.LOADED_PAYLOAD).value]);

	function handleLabelControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleCheckboxControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME);
	}

	return (
		<Box className={'rsLiveRunPreflight'} display={'flex'} flexDirection={'column'} gap={16}>
			<LabelSelect<{ label: string; value: string }>
				isCreatable={false}
				className={'locationSelect'}
				labelTitle={'Select Payload Type Observed in R1GCS'}
				placeholder={'Select'}
				options={payloadOptions}
				control={formGroup.get(FormKeys.LOADED_PAYLOAD)}
				updateControl={handleLabelControl}
			/>
			<Checkbox
				labelText={'Verify R1GCS notification stating "Production Enagament Enabled"'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.PROD_ENGAGEMENT)}
				updateControl={handleCheckboxControl}
			/>
		</Box>
	);
};
export default LiveRunPreflight;
