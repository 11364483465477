import { CalibrationAssemblyInfo, CalibrationAssemblyType } from './ICalibrationService';

export const calibrationAssemblies: { [key in CalibrationAssemblyType]: CalibrationAssemblyInfo } = {
	CARRIER_ASSEMBLY: {
		label: 'Carrier',
		workInstructionsLink: 'https://www.google.com',
		checklist: ['Check 1', 'Check 2', 'Check 3'],
		partNumbers: ['CAR-IMU-1234', 'CAR-SIB-1234', 'CAR-MAG_BARO-1234'],
		carrierTypeToPartNumberMapping: {
			IMU: ['CAR-IMU-1234'],
			SIB: ['CAR-SIB-1234'],
			MAG_BARO: ['CAR-MAG_BARO-1234']
		}
	},
	CALIBRATION_FIXTURE_ASSEMBLY: {
		label: 'Calibration Fixture',
		workInstructionsLink: 'https://www.google.com',
		checklist: [],
		partNumbers: ['CAF-TEMP-1234', 'CAF-AXIS-1234'],
		fixtureTypeToPartNumberMapping: {
			TEMPERATURE: ['CAF-TEMP-1234'],
			SIX_AXIS: ['CAF-AXIS-1234']
		}
	}
};
