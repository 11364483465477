import { Service } from '../Service';
import { calibrationAssemblies } from './calibration.data';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { rsToastify } from '@redskytech/framework/ui';
import { ObjectUtils, StringUtils, WebUtils } from '../../utils/utils';
import {
	CalibrationAssemblyType,
	CalibrationFixtureType,
	CarrierType,
	FileData,
	FullCalibrationFixtureDetails,
	PartData
} from './ICalibrationService';
import globalState, { getRecoilExternalValue, setRecoilExternalValue } from '../../state/globalState';

export default class CalibrationService extends Service {
	getCalibrationAssemblyTypeFromPartNumber(partNumber: string): CalibrationAssemblyType | undefined {
		let assemblyKey: CalibrationAssemblyType;
		for (assemblyKey in calibrationAssemblies) {
			if (calibrationAssemblies[assemblyKey].partNumbers.includes(partNumber)) return assemblyKey;
		}
		return undefined;
	}

	getCarrierTypeFromPartNumber(partNumber: string): CarrierType | undefined {
		let assemblyKey: CalibrationAssemblyType;
		for (assemblyKey in calibrationAssemblies) {
			if (calibrationAssemblies[assemblyKey].partNumbers.includes(partNumber)) {
				let carrierType: CarrierType;
				for (carrierType in calibrationAssemblies[assemblyKey].carrierTypeToPartNumberMapping) {
					if (
						calibrationAssemblies[assemblyKey].carrierTypeToPartNumberMapping![carrierType].includes(
							partNumber
						)
					)
						return carrierType;
				}
			}
		}
		return undefined;
	}

	getCalibrationFixtureTypeFromPartNumber(partNumber: string): CalibrationFixtureType | undefined {
		let assemblyKey: CalibrationAssemblyType;
		for (assemblyKey in calibrationAssemblies) {
			if (calibrationAssemblies[assemblyKey].partNumbers.includes(partNumber)) {
				let fixtureType: CalibrationFixtureType;
				for (fixtureType in calibrationAssemblies[assemblyKey].fixtureTypeToPartNumberMapping) {
					if (
						calibrationAssemblies[assemblyKey].fixtureTypeToPartNumberMapping![fixtureType].includes(
							partNumber
						)
					)
						return fixtureType;
				}
			}
		}
		return undefined;
	}

	async lookupFixtureByNumbers(
		partNumber: string,
		serialNumber: string
	): Promise<Api.V1.CalFixture.By.Numbers.Get.Res | undefined> {
		try {
			return await ApiRequestV1.getCalFixtureByNumbers({
				partNumber,
				serialNumber
			});
		} catch (e: any) {
			if (e.response?.status === 404) return undefined;
			rsToastify.error(
				WebUtils.getRsErrorMessage(e, 'Error looking up calibration fixture by numbers'),
				'Lookup Error'
			);
		}
		return undefined;
	}

	async lookupCarrierByNumbers(
		partNumber: string,
		serialNumber: string
	): Promise<Api.V1.Carrier.By.Numbers.Get.Res | undefined> {
		try {
			return await ApiRequestV1.getCarrierByNumbers({
				partNumber,
				serialNumber
			});
		} catch (e: any) {
			if (e.response?.status === 404) return undefined;
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error looking up carrier by numbers'), 'Lookup Error');
		}
		return undefined;
	}

	async getAllDetailsOfCalFixture(
		partNumber: string,
		serialNumber: string
	): Promise<{
		fullDetails: FullCalibrationFixtureDetails;
		partData: PartData[];
	}> {
		const partData: PartData[] = [];
		let calFixture = await this.lookupFixtureByNumbers(partNumber, serialNumber);
		if (!calFixture) throw new Error('Calibration Fixture Not Found');

		// Request all carrier cards for this fixture
		const carrierLookupIds: { id: number; trayPosition: number; type: 'IMU' | 'SIB' | 'BARO' }[] = [];
		const calFixtureAny = calFixture as any;
		for (let i = 1; i <= 3; i++) {
			if (calFixtureAny[`imu${i}Id`])
				carrierLookupIds.push({ id: calFixtureAny[`imu${i}Id`], trayPosition: i, type: 'IMU' });
			if (calFixtureAny[`sib${i}Id`])
				carrierLookupIds.push({ id: calFixtureAny[`sib${i}Id`], trayPosition: i, type: 'SIB' });
			if (calFixtureAny[`magBaro${i}Id`])
				carrierLookupIds.push({ id: calFixtureAny[`magBaro${i}Id`], trayPosition: i, type: 'BARO' });
		}

		const carrierPromises = carrierLookupIds.map((item) => {
			return ApiRequestV1.getCarrier({ id: item.id });
		});

		const carrierDetails = await Promise.all(carrierPromises);

		const boardLookupIds: { id: number; type: 'IMU' | 'SIB' | 'BARO'; slotPosition: number }[] = [];
		for (let carrier of carrierDetails) {
			let carrierAsAny = carrier as any;
			let calFixtureCarrierType: 'IMU' | 'SIB' | 'BARO' = 'BARO';
			if (carrier.type === 'IMU' || carrier.type === 'SIB') calFixtureCarrierType = carrier.type;
			for (let i = 1; i <= 16; i++) {
				if (carrierAsAny[`slot${i}Id`])
					boardLookupIds.push({
						id: carrierAsAny[`slot${i}Id`],
						type: calFixtureCarrierType,
						slotPosition: i
					});
			}
		}

		const boardPromises = boardLookupIds.map((item) => {
			return ApiRequestV1.getPart({ partId: item.id });
		});

		const boardDetails = await Promise.all(boardPromises);

		let fullDetails: FullCalibrationFixtureDetails = {
			name: 'configuration',
			fixture: 'boardTempCalFixture',
			assembled: '',
			hardwareRev: calFixture.hardwareRev,
			partNumber: calFixture.partNumber,
			serialNumber: calFixture.serialNumber,
			session: '',
			trays: [1, 2, 3].map((trayPosition) => {
				// First see if there are any carriers in this tray position
				if (
					!carrierLookupIds.some((carrier) => {
						return trayPosition === carrier.trayPosition;
					})
				) {
					return {
						position: trayPosition,
						empty: true
					};
				}

				return {
					position: trayPosition,
					slots: (['IMU', 'SIB', 'BARO'] as ('IMU' | 'SIB' | 'BARO')[]).map((type) => {
						let carrierLookup = carrierLookupIds.find((item) => {
							return item.type === type && item.trayPosition === trayPosition;
						});

						if (!carrierLookup) {
							return {
								type,
								empty: true
							};
						}

						let carrier = carrierDetails.find((item) => {
							return item.id === carrierLookup!.id;
						})!;

						let boards: {
							empty?: true;
							position: number;
							partNumber?: string;
							hardwareRev?: string;
							serialNumber?: string;
						}[] = [];

						const carrierAsAny = carrier as any;
						for (let i = 1; i <= 16; i++) {
							if (!carrierAsAny[`slot${i}Id`]) {
								boards.push({
									empty: true,
									position: i
								});
								continue;
							}

							const boardData = boardDetails.find((item) => {
								return item.id === carrierAsAny[`slot${i}Id`];
							});
							if (!boardData) {
								throw new Error('Could not get all board data');
							}

							boards.push({
								position: i,
								partNumber: boardData.partNumber,
								serialNumber: boardData.serialNumber,
								hardwareRev: boardData.hardwareRev
							});
							partData.push({
								tray: trayPosition,
								type: StringUtils.convertCarrierType(type),
								position: i,
								partId: boardData.id
							});
						}

						return {
							type,
							partNumber: carrier.partNumber,
							hardwareRev: carrier.hardwareRev,
							serialNumber: carrier.serialNumber,
							assembled: '',
							registration: '',
							boards
						};
					})
				};
			})
		};

		return { fullDetails, partData };
	}

	getArtifactString(currentFile: {
		partId: number;
		tray: number;
		position: number;
		fileUrl: string;
		fileSize: string;
	}): string | undefined {
		let allFileDataForPart = getRecoilExternalValue(globalState.uploadedFileData)
			.filter((data) => data.partId === currentFile.partId)
			.map((data) => ({ fileUrl: data.fileUrl, fileSize: data.fileSize }));
		allFileDataForPart.push({ fileUrl: currentFile.fileUrl, fileSize: currentFile.fileSize });
		return JSON.stringify({ data: allFileDataForPart });
	}

	getPartStatus(part: PartData): 'PASS' | 'FAIL' | 'INCOMPLETE' {
		let slot = getRecoilExternalValue(globalState.slotResultStatuses).find(
			(status) => status.tray === part.tray && status.type === part.type && status.position === part.position
		);
		if (!slot) return 'FAIL';
		switch (slot.status) {
			case 'GOOD':
				return 'PASS';
			case 'FAILED':
				return 'FAIL';
			default:
				return 'INCOMPLETE';
		}
	}

	async uploadAndSaveCalibrationResultFiles(files: FileData[]) {
		try {
			for (let file of files) {
				let url = await ApiRequestV1.getTestResultPresignedUrl({
					pathFileName: `test-results/${file.partData.connectionGuid}/${file.fileName}`,
					mimeType: file.mimeType
				});
				let success = await ObjectUtils.uploadAwsFile(file.fileBuffer, url, file.mimeType);
				if (!success) {
					rsToastify.error('Failed to upload file', 'File Upload Error');
					return;
				}

				let fileData = {
					fileSize: file.fileSize,
					fileUrl: url.split(file.fileName)[0] + file.fileName,
					partId: file.partData.partId,
					tray: file.partData.tray,
					position: file.partData.position
				};

				await ApiRequestV1.patchTestResult({
					partId: file.partData.partId,
					connectionGuid: `${file.partData.connectionGuid}_${file.partData.partId}-${file.partData.tray}-${file.partData.position}`,
					status: this.getPartStatus(file.partData),
					artifacts: this.getArtifactString(fileData),
					hasPassed: this.getPartStatus(file.partData) === 'PASS',
					isComplete: true
				});

				setRecoilExternalValue(globalState.uploadedFileData, (prev) => {
					let updatedState = [...prev];
					updatedState.push(fileData);
					return updatedState;
				});
			}
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error getting file data'), 'File Data Error');
		}
	}
}
