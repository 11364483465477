import * as React from 'react';
import './FlightTestItem.scss';
import { Box, Icon, Img, Label } from '@redskytech/framework/ui';
import { StatusIconMap, TestStatus } from '../../pages/testDetailsPage/TestDetailsPage';
import spinner from '../testList/testItem/spinner.gif';

interface FlightTestItemProps {
	testName: string;
	isSelected: boolean;
	onClick: () => void;
	status?: TestStatus;
}

const FlightTestItem: React.FC<FlightTestItemProps> = (props) => {
	function renderIcon() {
		if (!props.status) return <></>;
		if (props.status === 'PENDING') return <Img src={spinner} width={50} height={50} alt={'pending'} />;
		return <Icon {...StatusIconMap[props.status]} fontSize={24} />;
	}

	return (
		<Box className={`rsFlightTestItem ${props.isSelected ? 'active' : ''}`} onClick={props.onClick}>
			<Label variant={'body1'} weight={props.isSelected ? 'bold' : 'regular'}>
				{props.testName}
			</Label>
			<Box display={'flex'} gap={22}>
				{renderIcon()}
			</Box>
		</Box>
	);
};
export default FlightTestItem;
