import * as React from 'react';
import './ResultItem.scss';
import { Box, Icon, Label } from '@redskytech/framework/ui';
import StatusChip from '../statusChip/StatusChip';
import { ObjectUtils } from '../../utils/utils';
import { useState } from 'react';
import { TestStatus } from '../../pages/testDetailsPage/TestDetailsPage.js';

interface ResultItemProps {
	name: string;
	hasNotRan?: true;
	passed?: boolean;
	timeStamp: string;
	error?: any;
	data?: any;
	rawData?: any;
	other?: any[];
}

const ResultItem: React.FC<ResultItemProps> = (props) => {
	const [isRawDataExpanded, setIsRawDataExpanded] = useState<boolean>(false);

	function insertLineBreaks(json: string): string {
		let insideArray = 0;
		let result = '';
		for (let i = 0; i < json.length; i++) {
			const char = json[i];
			if (char === '[') {
				insideArray++;
			} else if (char === ']') {
				insideArray--;
			}
			if (char === ',' && insideArray === 0) {
				result += ',\n';
			} else {
				result += char;
			}
		}
		return result;
	}

	function getDataString(data: any): string {
		if (!ObjectUtils.isObject(data)) return data;
		let dataString = JSON.stringify(data);
		let lineBreakStr = insertLineBreaks(dataString);
		let spacedStr = lineBreakStr.replaceAll('":', '": ').replaceAll(',', ', ');
		return spacedStr.slice(1, -1);
	}

	function convertStatusType(): TestStatus {
		if (props.hasNotRan) return 'INCOMPLETE';
		if (props.passed) return 'SUCCESS';
		return 'ERROR';
	}

	function renderOtherData() {
		if (!props.other?.length) return <></>;
		return props.other.map((dataSet, index) => (
			<Label key={`label${index}`} variant={'body1'} weight={'regular'} padding={8} whiteSpace={'pre-line'}>
				{getDataString(dataSet)}
			</Label>
		));
	}

	function renderRawData() {
		if (!props.rawData) return;
		return (
			<>
				<Box
					onClick={() => {
						setIsRawDataExpanded(!isRawDataExpanded);
					}}
					className={'rawDataDropdown'}
				>
					<Label variant={'body1'} weight={'semiBold'}>
						Raw Data
					</Label>
					<Icon iconImg={isRawDataExpanded ? 'icon-chevron-down' : 'icon-chevron-up'} fontSize={12} />
				</Box>
				{isRawDataExpanded && (
					<Label variant={'body1'} weight={'regular'} px={8} whiteSpace={'pre-line'}>
						{getDataString(props.rawData)}
					</Label>
				)}
			</>
		);
	}

	return (
		<Box className={'rsResultItem'}>
			<Box position={'relative'}>
				<Label variant={'body1'} weight={'semiBold'} pt={8} textAlign={'center'}>
					{props.name}
				</Label>
				<Box className={'statusTimeContainer'}>
					<StatusChip status={convertStatusType()} />
					<Label variant={'caption1'} weight={'regular'}>
						{props.timeStamp}
					</Label>
				</Box>
			</Box>
			{props.error && (
				<Label variant={'body1'} weight={'regular'} padding={8} whiteSpace={'pre-line'}>
					{getDataString(props.error)}
				</Label>
			)}
			{props.data && (
				<Label variant={'body1'} weight={'regular'} padding={8} whiteSpace={'pre-line'}>
					{getDataString(props.data)}
				</Label>
			)}
			{renderOtherData()}
			{renderRawData()}
			{!props.error && !props.data && !props.rawData && !props.other?.length && (
				<Label variant={'body1'} weight={'regular'} padding={8} whiteSpace={'pre-line'}>
					No Data
				</Label>
			)}
		</Box>
	);
};
export default ResultItem;
