import * as React from 'react';
import './TestResultsTable.scss';
import {
	Box,
	Button,
	InputText,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	Select
} from '@redskytech/framework/ui';
import TableSearchHeader from '../../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import classNames from 'classnames';
import ColumnHeader from '../../../components/listDataTable/columnHeader/ColumnHeader';
import ListDataTable from '../../../components/listDataTable/ListDataTable';
import { Column } from 'primereact/column';
import router from '../../../utils/router';
import { useEffect, useRef, useState } from 'react';
import useDataTableFilters from '../../../customHooks/useDataTableFilters';
import useIsMounted from '../../../customHooks/useIsMounted';
import { DataTableFilterMeta } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { DateUtils, WebUtils } from '../../../utils/utils';
import StatusChip from '../../../components/statusChip/StatusChip';
import TestResultsPopup, { TestResultsPopupProps } from '../../../popups/testResultsPopup/TestResultsPopup';
import { RsSortOrder } from '@redskytech/framework/ui/dataTable/DataTable';
import LinksPopup, { LinksPopupProps } from '../../../popups/linksPopup/LinksPopup';
import BurnInPlotPopup, { BurnInPlotProps } from '../../../popups/burnInPlottingPopup/BurnInPlotPopup';
import { baseParts } from '../../../services/assembly/assembly.data';

interface TestResultsTableProps {
	assemblyDetails: Api.V1.Part.Get.Res;
}

interface ChecklistItem {
	key: string;
	value: boolean | string;
}

interface AssemblyTasksChecked {
	checklist: ChecklistItem[];
}

const TabletDisplayNames: { [key: string]: string } = {
	loadTabletImage: 'Load tablet image',
	activateWindowsKey: 'Activate windows key',
	addOperatingManualToDesktop: 'Add operating manual PDF to the desktop',
	verifyR1GCSVersion: 'Verify R1GCS is the latest version',
	connectToVehicle: 'Connect to vehicle and check tethering',
	addFriendlyName: 'Add friendly name',
	wipeCleanInspectRepackage: 'Wipe clean of finger prints, visually inspect, and repackage'
};

const FieldKeys = {
	NAME: 'testResult.testName',
	CREATED_ON: 'testResult.createdOn',
	CREATED_FIRST_NAME: 'user.firstName',
	CREATED_LAST_NAME: 'user.lastName',
	STATUS: 'testResult.status'
};

const TestResultsTable: React.FC<TestResultsTableProps> = (props) => {
	const { partId } = router.getQueryParams<{ partId: number }>([
		{
			key: 'pi',
			default: 0,
			type: 'integer',
			alias: 'partId'
		}
	]);

	const [testResults, setTestResults] = useState<RedSky.RsPagedResponseData<Api.V1.TestResult.Paged.Get.Res[]>>({
		data: [],
		total: 0
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeSortColumn, setActiveSortColumn] = useState<string | undefined>();
	const currentPageQuery = useRef<RedSky.PageQuery | undefined>();
	const initialFiltersFormGroup: RsFormGroup = new RsFormGroup([
		new RsFormControl<string>(FieldKeys.NAME, ''),
		new RsFormControl<string>(FieldKeys.CREATED_ON, ''),
		new RsFormControl<string>(FieldKeys.CREATED_FIRST_NAME, ''),
		new RsFormControl<string>(FieldKeys.STATUS, '')
	]);
	const initialFilters: DataTableFilterMeta = {
		[FieldKeys.NAME]: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.CREATED_ON]: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.STATUS]: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.CREATED_FIRST_NAME]: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.CREATED_LAST_NAME]: {
			operator: FilterOperator.OR,
			constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
		}
	};
	const {
		filters,
		activeFilters,
		globalSearch,
		setGlobalSearch,
		handleClearAllFilters,
		handleFilterClear,
		handleFilterApply,
		handleUpdateControl,
		filtersFormGroup
	} = useDataTableFilters(initialFilters, initialFiltersFormGroup, getUpdatedFilters);
	const isMounted = useIsMounted();

	useEffect(() => {
		if (!currentPageQuery || !currentPageQuery.current) return;
		getData(currentPageQuery.current).catch(console.error);
	}, [partId]);

	function getUpdatedFilters(field: string, value: any) {
		const filter: any = filters[field];
		const constraint = [
			{
				value: value,
				matchMode: filter?.constraints[0]?.matchMode
			}
		];
		if (field === FieldKeys.CREATED_FIRST_NAME) {
			return {
				...filters,
				[field]: {
					...filter,
					constraints: constraint
				},
				[FieldKeys.CREATED_LAST_NAME]: {
					operator: FilterOperator.OR,
					constraints: constraint
				}
			};
		}
		return {
			...filters,
			[field]: {
				...filter,
				constraints: constraint
			}
		};
	}

	async function getData(pageQuery: RedSky.PageQuery) {
		setActiveSortColumn(pageQuery.sortBy);
		currentPageQuery.current = pageQuery;
		if (props.assemblyDetails.name === baseParts['TABLET'].label) {
			try {
				setIsLoading(true);
				const serialNumber = props.assemblyDetails.serialNumber;
				const res = await ApiRequestV1.getPartChecklist({
					partNumber: baseParts['TABLET'].partNumbers[0],
					serialNumber
				});
				let data: Api.V1.TestResult.Paged.Get.Res[] = [];
				let parsed: AssemblyTasksChecked =
					res.assemblyTasksChecked && JSON.parse(res.assemblyTasksChecked.replace(/^"(.*)"$/, '$1'));
				let count = 0;
				if (parsed) {
					parsed.checklist.forEach((listItem: ChecklistItem) => {
						if (typeof listItem.value === 'boolean') {
							let testStatus: boolean = listItem.value;
							count++;
							let itemToAdd: Api.V1.TestResult.Paged.Get.Res = {
								id: partId,
								createdOn: res.createdOn,
								modifiedOn: res.createdOn,
								status: testStatus ? 'PASS' : 'INCOMPLETE',
								testName: TabletDisplayNames[listItem.key],
								userFirstName: res.createdUserFirstName,
								userLastName: res.createdUserLastName,
								hasPassed: testStatus,
								isComplete: testStatus,
								partId: partId,
								userId: props.assemblyDetails.createdById,
								connectionGuid: ''
							};
							data.push(itemToAdd);
						}
					});
				}
				setTestResults({
					data,
					total: count
				});
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			}
			setIsLoading(false);
		} else {
			try {
				if (!isMounted || !partId) return;
				setIsLoading(true);

				if (pageQuery.sortBy === undefined) {
					delete pageQuery.sortBy;
					delete pageQuery.sortOrder;
				}
				if (!pageQuery.filter) delete pageQuery.filter;
				const res = await ApiRequestV1.getTestResultPaged({ ...pageQuery, partId });
				setTestResults(res);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			}
			setIsLoading(false);
		}
	}

	function handleShowDetails(testResult: Api.V1.TestResult.Paged.Get.Res) {
		popupController.open<TestResultsPopupProps>(TestResultsPopup, {
			testName: testResult.testName,
			testResultId: testResult.id
		});
	}

	return (
		<Box className={'rsTestResultsTable'}>
			<ListDataTable
				loading={isLoading}
				onClearAllFilters={handleClearAllFilters}
				onFilterClear={handleFilterClear}
				onFilterApply={handleFilterApply}
				filters={filters}
				data={testResults}
				sortField={FieldKeys.CREATED_ON}
				sortOrder={RsSortOrder.DESC}
				getData={getData}
				globalFilter={globalSearch}
				globalFilterFields={[FieldKeys.NAME, FieldKeys.CREATED_LAST_NAME, FieldKeys.CREATED_FIRST_NAME]}
				initialRowsPerPage={100}
				scrollHeight={'400px'}
				header={
					<TableSearchHeader
						searchValue={globalSearch}
						onChange={(value: string) => setGlobalSearch(value)}
						placeholder={'Search test results'}
						title={'Test Results'}
					/>
				}
			>
				<Column
					className={classNames({ activeFilter: activeFilters.includes(FieldKeys.NAME) })}
					field={FieldKeys.NAME}
					sortField={FieldKeys.NAME}
					header={<ColumnHeader label={'Test'} isActiveSort={activeSortColumn === FieldKeys.NAME} />}
					sortable
					filter
					filterElement={
						<InputText
							inputMode={'text'}
							placeholder={'Test'}
							control={filtersFormGroup.get(FieldKeys.NAME)}
							updateControl={handleUpdateControl}
						/>
					}
					body={(testResult: Api.V1.TestResult.Paged.Get.Res) => testResult.testName}
				/>
				<Column
					className={classNames({
						activeFilter: activeFilters.includes(FieldKeys.CREATED_ON)
					})}
					field={FieldKeys.CREATED_ON}
					sortField={FieldKeys.CREATED_ON}
					header={
						<ColumnHeader label={'Created On'} isActiveSort={activeSortColumn === FieldKeys.CREATED_ON} />
					}
					sortable
					filter
					filterElement={
						<InputText
							inputMode={'text'}
							type={'date'}
							control={filtersFormGroup.get(FieldKeys.CREATED_ON)}
							updateControl={(control) => {
								control.value = DateUtils.convertInputDateToServerDate(control.value.toString());
								handleUpdateControl(control);
							}}
						/>
					}
					body={(testResult: Api.V1.TestResult.Paged.Get.Res) =>
						`${DateUtils.displayDate(testResult.createdOn)} ${DateUtils.displayTime(testResult.createdOn)}`
					}
				/>
				<Column
					className={classNames({
						activeFilter: activeFilters.includes(FieldKeys.CREATED_FIRST_NAME)
					})}
					field={FieldKeys.CREATED_FIRST_NAME}
					sortField={FieldKeys.CREATED_FIRST_NAME}
					header={
						<ColumnHeader
							label={'Created By'}
							isActiveSort={activeSortColumn === FieldKeys.CREATED_FIRST_NAME}
						/>
					}
					sortable
					filter
					filterElement={
						<InputText
							inputMode={'text'}
							placeholder={'Created By'}
							control={filtersFormGroup.get(FieldKeys.CREATED_FIRST_NAME)}
							updateControl={handleUpdateControl}
						/>
					}
					body={(testResult: Api.V1.TestResult.Paged.Get.Res) =>
						`${testResult.userFirstName || ''} ${testResult.userLastName || ''}`
					}
				/>
				<Column
					className={classNames({
						activeFilter: activeFilters.includes(FieldKeys.STATUS)
					})}
					field={FieldKeys.STATUS}
					sortField={FieldKeys.STATUS}
					header={<ColumnHeader label={'Status'} isActiveSort={activeSortColumn === FieldKeys.STATUS} />}
					sortable
					filter
					filterElement={
						<Select<{ label: string; value: Api.V1.TestResult.Paged.Get.Res['status'] }>
							options={[
								{ label: 'Pass', value: 'PASS' },
								{ label: 'Fail', value: 'FAIL' },
								{ label: 'Incomplete', value: 'INCOMPLETE' }
							]}
							control={filtersFormGroup.get(FieldKeys.STATUS)}
							updateControl={handleUpdateControl}
							placeholder={'Status'}
						/>
					}
					body={(testResult: Api.V1.TestResult.Paged.Get.Res) => {
						if (testResult.isComplete) {
							if (testResult.hasPassed) return <StatusChip status={'SUCCESS'} />;
							return <StatusChip status={'ERROR'} />;
						} else {
							return (
								<Box display={'flex'} gap={8}>
									<StatusChip status={'PENDING'} />
									{testResult.hasPassed ? (
										<StatusChip status={'SUCCESS'} />
									) : (
										<StatusChip status={'ERROR'} />
									)}
								</Box>
							);
						}
					}}
				/>
				<Column
					align={'right'}
					body={(testResult: Api.V1.TestResult.Paged.Get.Res) => (
						<Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={16}>
							{!Object.values(TabletDisplayNames).includes(testResult.testName) && (
								<Button look={'outlinedPrimary'} onClick={() => handleShowDetails(testResult)}>
									Details
								</Button>
							)}
							<Box display={'flex'} overflow={'hidden'}>
								{testResult.artifacts && (
									<>
										<Button
											look={'outlinedPrimary'}
											onClick={() => {
												let artifacts = testResult.artifacts as {
													data: { fileUrl: string; fileSize: string }[];
												};
												popupController.open<LinksPopupProps>(LinksPopup, {
													artifacts
												});
											}}
										>
											Links
										</Button>
									</>
								)}
								{testResult.testName === 'Air Vehicle Burn In Test' && (
									<Button
										look={'outlinedPrimary'}
										onClick={() =>
											popupController.open<BurnInPlotProps>(BurnInPlotPopup, {
												testName: testResult.testName,
												testResultId: testResult.id
											})
										}
									>
										Burn In
									</Button>
								)}
							</Box>
							<Box></Box>
						</Box>
					)}
				/>
			</ListDataTable>
		</Box>
	);
};

export default TestResultsTable;
