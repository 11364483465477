import * as React from 'react';
import './DeviceIdlePopup.scss';
import { Box, Button, Label, Popup, PopupProps, popupController } from '@redskytech/framework/ui';
import colors from '../../themes/colors.scss?export';
import { useEffect, useState } from 'react';

export interface DeviceIdlePopupProps extends PopupProps {
	onPowerOff: () => void;
	onKeepOn: () => void;
	onRestorePower: () => void;
}

const MAX_IDLE_TIME_SINCE_POPUP_SECS = 5 * 60;

const DeviceIdlePopup: React.FC<DeviceIdlePopupProps> = (props) => {
	const [timeRemainingSecs, setTimeRemainingSecs] = useState<number>(MAX_IDLE_TIME_SINCE_POPUP_SECS);
	const [isPoweredDown, setIsPoweredDown] = useState<boolean>(false);

	useEffect(() => {
		if (isPoweredDown) return;

		const audio = new Audio('/alert_sound.mp3');
		audio.play().catch(console.error);

		let startTime = new Date();
		const intervalId = setInterval(() => {
			const newTimeRemaining = Math.max(
				MAX_IDLE_TIME_SINCE_POPUP_SECS - Math.floor((new Date().getTime() - startTime.getTime()) / 1000),
				0
			);
			setTimeRemainingSecs(newTimeRemaining);
			if (newTimeRemaining === 0) {
				props.onPowerOff();
				setIsPoweredDown(true);
				clearInterval(intervalId);
			}
		}, 5000);
		return () => clearInterval(intervalId);
	}, [isPoweredDown]);

	function convertTimeRemainingToMinutes(): React.ReactNode {
		const timeRemainingMins = Math.ceil(timeRemainingSecs / 60);
		return (
			<>
				<span style={{ fontWeight: 900 }}>{timeRemainingMins}</span> minute{timeRemainingMins > 1 ? 's' : ''}
			</>
		);
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick className={'rsDeviceIdlePopupContainer'}>
			<Box className={'rsDeviceIdlePopup'}>
				<Box paddingBottom={7} borderBottom={`1px solid ${colors.neutralGrey400}`} width={'100%'}>
					<Label variant="subheader1" weight="semiBold" textAlign="center">
						{isPoweredDown ? 'Device Turned Off' : 'Device Idle'}
					</Label>
				</Box>
				<Label variant="body1" weight="regular">
					{isPoweredDown ? (
						'The device was powered off due to inactivity. Restore power to continue the test session.'
					) : (
						<>
							Device has been powered on with no test activity in the last 20 minutes. If the device
							continues to be idle, it will be automatically powered off in{' '}
							<span style={{ fontWeight: 900 }}>{convertTimeRemainingToMinutes()}</span>.
						</>
					)}
				</Label>
				<Box display="flex" gap={16} width={'100%'}>
					{isPoweredDown ? (
						<Button
							fullWidth
							look="containedPrimary"
							onClick={() => {
								popupController.close(DeviceIdlePopup);
								props.onRestorePower();
							}}
						>
							Restore Power
						</Button>
					) : (
						<>
							<Button
								fullWidth
								look="outlinedPrimary"
								onClick={() => {
									props.onPowerOff();
									setIsPoweredDown(true);
								}}
							>
								Power Off
							</Button>
							<Button
								fullWidth
								look="containedPrimary"
								onClick={() => {
									props.onKeepOn();
									popupController.close(DeviceIdlePopup);
								}}
							>
								Keep On
							</Button>
						</>
					)}
				</Box>
			</Box>
		</Popup>
	);
};

export default DeviceIdlePopup;
