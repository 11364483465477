import * as React from 'react';
import './SerialOnlyPartSelector.scss';
import { Box, Button, Label, RsFormControl, rsToastify } from '@redskytech/framework/ui';
import SelectableInputText from '../selectableInputText/SelectableInputText';
import serviceFactory from '../../services/serviceFactory';
import { useState } from 'react';
import PageHeader from '../pageHeader/PageHeader';
import { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import useIsMounted from '../../customHooks/useIsMounted';
import { BasePartType } from '../../services/assembly/IAssemblyService';
import { baseParts } from '../../services/assembly/assembly.data';
import { useEffect } from 'react';

interface SerialOnlyPartSelectorProps {
	onPartTypeSelected: (assemblyType: BasePartType, hwid: HardwareIdDecoded) => void;
	partType: BasePartType;
	labelText?: string;
	clearAfterEnter?: boolean;
	rightNode?: React.ReactNode;
	fullSize: boolean;
}

const SerialOnlyPartSelector: React.FC<SerialOnlyPartSelectorProps> = (props: SerialOnlyPartSelectorProps) => {
	const [expanded, setIsExpanded] = useState<boolean>(false);
	const [partHwidControl, setPartHwidControl] = useState<RsFormControl<string>>(
		new RsFormControl<string>('main', '')
	);
	const [isSelected, setIsSelected] = useState<boolean>(true);
	const isMounted = useIsMounted();

	useEffect(() => {
		setIsExpanded(props.fullSize);
	}, [props.fullSize]);

	function handleUpdateControl(control: RsFormControl<string>) {
		if (isMounted) setPartHwidControl(control);
	}

	function handleIsSelected() {
		if (isMounted) setIsSelected(true);
	}

	return (
		<>
			<Box className={'rsSerialOnlyPartSelector'}>
				{expanded === false && (
					<Button
						look={'outlinedPrimary'}
						onClick={() => {
							setIsExpanded(true);
						}}
					>
						{'Add A Tablet'}
					</Button>
				)}
				{expanded === true && (
					<>
						<Label variant={'subheader1'} weight={'regular'}>
							{props.labelText
								? props.labelText
								: 'Scan the hardware identifier of the assembly you want to build.'}
						</Label>
						<SelectableInputText
							labelTitle={baseParts[props.partType]?.label || 'Assembly'}
							control={partHwidControl}
							updateControl={handleUpdateControl}
							isSelected={isSelected}
							onClick={handleIsSelected}
							onBlurOrEnter={async (value: string) => {
								// Clearing out is ok
								if (!value) return 'VALID';

								const assemblyService = serviceFactory.get('AssemblyService');
								const calibrationService = serviceFactory.get('CalibrationService');
								let hardwareIdDecoded = assemblyService.decodeHardwareId(value);

								if (
									!hardwareIdDecoded &&
									props.partType in baseParts &&
									baseParts[props.partType].onInput !== undefined
								) {
									let tmpvalue = baseParts[props.partType]?.onInput?.(value);
									if (tmpvalue) hardwareIdDecoded = assemblyService.decodeHardwareId(tmpvalue);
								}
								if (!hardwareIdDecoded) {
									rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
									return 'Invalid Hardware ID';
								}

								const discoveredBasePartType = assemblyService.getBasePartTypeFromPartNumber(
									hardwareIdDecoded.partNumber
								);
								if (!discoveredBasePartType) {
									rsToastify.error('Unable to recognize the entered Part.', 'Unknown Part Type');
									return 'Unknown Part Type';
								}

								if (discoveredBasePartType === props.partType) {
									props.onPartTypeSelected(props.partType, hardwareIdDecoded);
								}

								if (props.clearAfterEnter) {
									setPartHwidControl(new RsFormControl<string>('main', ''));
									setIsExpanded(false);
								}

								return 'VALID';
							}}
						/>
						<Button
							look={'outlinedPrimary'}
							onClick={() => {
								setIsExpanded(false);
							}}
						>
							{'Cancel'}
						</Button>
					</>
				)}
			</Box>
		</>
	);
};

export default SerialOnlyPartSelector;
