import * as React from 'react';
import './WarningPopup.scss';
import { Box, Button, Label, Popup, popupController, PopupProps } from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';

export interface WarningPopupProps extends PopupProps {
	title: string;
	onConfirm: () => void;
	message: string;
	confirmButtonText?: string;
	disableAutoClose?: boolean;
}

const WarningPopup: React.FC<WarningPopupProps> = (props) => {
	const fullProps = popupController.convertProps(props);

	function handleConfirm() {
		if (props.onConfirm) props.onConfirm();
		if (!props.disableAutoClose) popupController.closeById(fullProps.popupId);
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsWarningPopup'}>
				<PopupHeader title={props.title} onClose={handleConfirm} />
				<Label variant={'body1'} weight={'regular'} mt={24}>
					{props.message}
				</Label>
				<Box display={'flex'} gap={16} mt={24}>
					<Button look={'containedPrimary'} onClick={handleConfirm} fullWidth>
						{props.confirmButtonText || 'Confirm'}
					</Button>
				</Box>
			</Box>
		</Popup>
	);
};
export default WarningPopup;
