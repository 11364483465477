import * as React from 'react';
import './LoadingSpinner.scss';
import { Box } from '@redskytech/framework/ui';

interface LoadingSpinnerProps {}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
	return (
		<Box className={'rsLoadingSpinner'}>
			<div className="container">
				<div className="holder">
					<div className="box" />
				</div>
				<div className="holder">
					<div className="box" />
				</div>
				<div className="holder">
					<div className="box" />
				</div>
			</div>
		</Box>
	);
};

export default LoadingSpinner;
