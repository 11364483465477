import * as React from 'react';
import './EvaluationItem.scss';
import { Box, Button, Label } from '@redskytech/framework/ui';

export type EvaluationOptions = 'PASS' | 'FAILURE';

export type EvaluationGroup = {
	[key: string]: EvaluationOptions | undefined;
};

interface EvaluationItemProps {
	label: string;
	onEvaluationClick: (evaluation: EvaluationOptions) => void;
	evaluation?: EvaluationOptions;
}

const EvaluationItem: React.FC<EvaluationItemProps> = (props) => {
	return (
		<Box className={'rsEvaluationItem'}>
			<Box flexGrow={1}>
				<Label variant={'subheader2'} weight={'regular'}>
					{props.label}
				</Label>
			</Box>
			<Box display={'flex'} gap={16}>
				<Button
					look={props.evaluation === 'FAILURE' ? 'containedPrimary' : 'outlinedPrimary'}
					onClick={() => props.onEvaluationClick('FAILURE')}
					small
				>
					Failure
				</Button>
				<Button
					look={props.evaluation === 'PASS' ? 'containedPrimary' : 'outlinedPrimary'}
					onClick={() => props.onEvaluationClick('PASS')}
					small
				>
					Pass
				</Button>
			</Box>
		</Box>
	);
};
export default EvaluationItem;
