import * as React from 'react';
import './AssemblePage.scss';
import { Page } from '@redskytech/framework/996';
import { useState } from 'react';
import AssemblyTypeSelectorSection, { AssemblyRenderType } from './sections/AssemblyTypeSelectorSection';
import PartAssemblySection from './sections/PartAssemblySection';
import { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import CarrierAssemblySection from './sections/CarrierAssemblySection';
import CalFixtureAssemblySection from './sections/CalFixtureAssemblySection';

interface AssemblePageProps {}

const AssemblePage: React.FC<AssemblePageProps> = (props) => {
	const [hwid, setHwid] = useState<HardwareIdDecoded | undefined>();
	const [assemblyRenderType, setAssemblyRenderType] = useState<AssemblyRenderType | undefined>();

	function handleAssemblyTypeSelected(assemblyRenderType: AssemblyRenderType, hwid: HardwareIdDecoded) {
		setAssemblyRenderType(assemblyRenderType);
		setHwid(hwid);
	}

	function handleOnDone() {
		setAssemblyRenderType(undefined);
		setHwid(undefined);
	}

	function renderBasedOnAssemblyType(): React.ReactNode {
		if (!hwid || !assemblyRenderType) {
			return <AssemblyTypeSelectorSection onAssemblyTypeSelected={handleAssemblyTypeSelected} />;
		}

		switch (assemblyRenderType) {
			case 'PART_ASSEMBLY':
				return <PartAssemblySection hwid={hwid} onDone={handleOnDone} />;
			case 'CARRIER_ASSEMBLY':
				return <CarrierAssemblySection hwid={hwid} onDone={handleOnDone} />;
			case 'CALIBRATION_FIXTURE_ASSEMBLY':
				return <CalFixtureAssemblySection hwid={hwid} onDone={handleOnDone} />;
		}
	}

	return <Page className={'rsAssemblePage'}>{renderBasedOnAssemblyType()}</Page>;
};
export default AssemblePage;
