import * as React from 'react';
import { useEffect, useState } from 'react';
import './SubAssemblyNotesPaper.scss';
import { Box, Label } from '@redskytech/framework/ui';
import PaperHeaderBar from '../../../components/paperHeaderBar/PaperHeaderBar';
import colors from '../../../themes/colors.scss?export';
import { ApiRequestV1 } from '../../../generated/apiRequests';

interface SubAssemblyNotesPaperProps {
	assemblyDetails: Api.V1.Part.Get.Res;
}

const SubAssemblyNotesPaper: React.FC<SubAssemblyNotesPaperProps> = (props) => {
	const [childParts, setChildParts] = useState<Api.V1.Part.Get.Res[]>([]);
	useEffect(() => {
		async function fetchAllChildParts() {
			if (props.assemblyDetails.children.length > 0) {
				const fetchedParts = await getAllChildParts(props.assemblyDetails.children, new Set<string>());
				setChildParts(fetchedParts);
			}
		}
		fetchAllChildParts();
	}, [props.assemblyDetails]);

	async function getAllChildParts(children: Api.V1.Part.Get.Res['children'], visitedParts: Set<string>) {
		let parts: Api.V1.Part.Get.Res[] = [];
		for (let child of children) {
			if (
				child.childPartNumber !== props.assemblyDetails.partNumber &&
				!visitedParts.has(child.childPartNumber)
			) {
				visitedParts.add(child.childPartNumber);
				let res = await ApiRequestV1.getPart({ partId: child.childId });
				parts.push(res);
				if (res.children.length > 0) {
					const childParts = await getAllChildParts(res.children, visitedParts);
					parts.push(...childParts);
				}
			}
		}
		return parts;
	}

	function displaySubAssemblyNotes() {
		if (!childParts.length) {
			return (
				<Label
					variant={'body1Italic'}
					weight={'regular'}
					color={colors.neutralGrey700}
					textAlign={'center'}
					className={'noNotesLabel'}
				>
					No notes to display.
				</Label>
			);
		}

		return childParts.map((part) => (
			<Box key={part.id} className={'partContainer'}>
				<Box display={'flex'} gap={4} mt={8}>
					<Label
						variant={'overline'}
						weight={'regular'}
						textTransform={'uppercase'}
						color={colors.neutralGrey700}
					>
						{part.partNumber},
					</Label>
					<Label
						variant={'overline'}
						weight={'regular'}
						textTransform={'uppercase'}
						color={colors.neutralGrey700}
					>
						{part.name}
					</Label>
				</Box>
				<Box>
					{part.notes ? (
						<Label variant={'body1'} weight={'regular'} color={colors.neutralGrey700} mb={8}>
							{part.notes}
						</Label>
					) : (
						<Label variant={'body1Italic'} weight={'regular'} color={colors.neutralGrey700} mb={8}>
							No notes to display.
						</Label>
					)}
				</Box>
			</Box>
		));
	}

	return (
		<Box className={'rsSubAssemblyNotesPaper'}>
			<PaperHeaderBar title={'Subassembly Notes'}>
				<Box className={'subAssemblyNotesContainer'}>{displaySubAssemblyNotes()}</Box>
			</PaperHeaderBar>
		</Box>
	);
};

export default SubAssemblyNotesPaper;
