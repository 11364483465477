import * as React from 'react';
import './PopupHeader.scss';
import { Box, Icon, Label } from '@redskytech/framework/ui';
import colors from '../../themes/colors.scss?export';

interface PopupHeaderProps {
	title: string | React.ReactNode;
	onClose?: () => void;
}

const PopupHeader: React.FC<PopupHeaderProps> = (props) => {
	return (
		<Box className={'rsPopupHeader'}>
			{typeof props.title === 'string' ? (
				<Label variant={'subheader1'} weight={'medium'}>
					{props.title}
				</Label>
			) : (
				props.title
			)}
			{props.onClose && (
				<Icon
					iconImg={'icon-close'}
					fontSize={24}
					color={colors.neutralGrey600}
					onClick={props.onClose}
					cursorPointer
				/>
			)}
		</Box>
	);
};
export default PopupHeader;
