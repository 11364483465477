import { Service } from '../Service';
import globalState, {
	clearPersistentState,
	getRecoilExternalValue,
	setRecoilExternalValue
} from '../../state/globalState';
import { ApiRequestV1 } from '../../generated/apiRequests';
import router from '../../utils/router';
import { RoutePaths } from '../../routes';
import { WebUtils } from '../../utils/utils';
import serviceFactory from '../serviceFactory';
import { boundMethod } from 'autobind-decorator';

export interface AuthTokens {
	refreshToken: string;
	token: string;
	tokenExpiresOn: string;
}

const IDLE_TIME_CHECK_MS = 5 * 60 * 1000; // 5 mins

export default class UserService extends Service {
	private idleStartTime: number = 0;

	start() {
		super.start();

		// Event listener for user activity
		document.addEventListener('mousemove', this.resetIdleTimer);
		document.addEventListener('keydown', this.resetIdleTimer);

		setInterval(this.keepUserLoggedIn, IDLE_TIME_CHECK_MS);
	}

	// Function to reset the timer
	@boundMethod
	resetIdleTimer() {
		this.idleStartTime = Date.now();
	}

	@boundMethod
	keepUserLoggedIn() {
		const authTokens = getRecoilExternalValue(globalState.authTokens);
		if (!authTokens) return;
		if (Date.now() - this.idleStartTime < IDLE_TIME_CHECK_MS) {
			ApiRequestV1.postUserKeepAlive({ token: authTokens.token, refreshToken: authTokens.refreshToken }).catch(
				console.error
			);
		}
	}

	async loginUserByPassword(username: string, password: string) {
		let hashedPassword = await WebUtils.sha256Encode(password);
		let loginResponse = await ApiRequestV1.postUserLogin({
			username,
			password: hashedPassword
		});

		await this.onAfterLogin({
			token: loginResponse.token,
			refreshToken: loginResponse.refreshToken,
			tokenExpiresOn: loginResponse.expiresOn
		});
	}

	async refreshToken(authToken: AuthTokens): Promise<AuthTokens> {
		const reAuthResponse = await ApiRequestV1.postUserReAuth({
			token: authToken.token,
			refreshToken: authToken.refreshToken
		});
		let newAuthTokens: AuthTokens = {
			token: reAuthResponse.token,
			refreshToken: reAuthResponse.refreshToken,
			tokenExpiresOn: reAuthResponse.expiresOn
		};
		setRecoilExternalValue(globalState.authTokens, newAuthTokens);
		return newAuthTokens;
	}

	logout() {
		clearPersistentState();
		router.navigate<RoutePaths>('/').catch(console.error);
	}

	async onAfterLogin(authTokens: AuthTokens) {
		setRecoilExternalValue(globalState.authTokens, authTokens);
		const socketioService = serviceFactory.get('SocketioService');
		socketioService.syncLinkState();
		// Since recoil can take a bit to update we need to delay so our authorization Token is updated before
		// doing any requests that might require it.
		await WebUtils.sleep(50);
		await this.getAndSaveUserDetails();
	}

	private async getAndSaveUserDetails(): Promise<Api.V1.User.Me.Get.Res> {
		const userDetails = await ApiRequestV1.getUserMe();
		setRecoilExternalValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user, userDetails);
		return userDetails;
	}
}
