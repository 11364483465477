import * as React from 'react';
import './PaperHeaderBar.scss';
import { Box, Label } from '@redskytech/framework/ui';
import classNames from 'classnames';

interface PaperHeaderBarProps {
	title: string;
	className?: string;
	rightNode?: React.ReactNode;
	contentPadding?: number | string;
}

const PaperHeaderBar: React.FC<PaperHeaderBarProps> = (props) => {
	return (
		<Box className={classNames('rsPaperHeaderBar', props.className)}>
			<Box className={'header'}>
				<Label variant={'h5'} weight={'medium'}>
					{props.title}
				</Label>
				{props.rightNode}
			</Box>
			<Box className="container" padding={props.contentPadding || 24}>
				{props.children}
			</Box>
		</Box>
	);
};

export default PaperHeaderBar;
