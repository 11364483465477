import React, { FormEvent, useState } from 'react';
import './LoginPage.scss';
import UserService from '../../services/user/UserService';
import serviceFactory from '../../services/serviceFactory';
import { WebUtils } from '../../utils/utils';
import colors from '../../themes/colors.scss?export';
import {
	Label,
	Box,
	Button,
	InputText,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { Page } from '@redskytech/framework/996';
import SplitImageLogoLayout from '../../components/splitImageLogoLayout/SplitImageLogoLayout';
import login from '../../images/login.png?width=500&webp&imagetools';

enum FormKeys {
	PASSWORD = 'password',
	EMAIL = 'email'
}

const LoginPage: React.FC = () => {
	// @ts-ignore
	let userService = serviceFactory.get<UserService>('UserService');
	const [isAttemptingLogin, setIsAttemptingLogin] = useState<boolean>(false);
	const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
	const [loginErrorMessage, setLoginErrorMessage] = useState<string>('');
	const [loginForm, setLoginForm] = useState(
		new RsFormGroup([
			new RsFormControl(FormKeys.PASSWORD, '', [new RsValidator(RsValidatorEnum.REQ, 'Password is required')]),
			new RsFormControl(FormKeys.EMAIL, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Email is required'),
				new RsValidator(RsValidatorEnum.EMAIL, 'Email is invalid')
			])
		])
	);

	async function signInUser(e: FormEvent) {
		e.preventDefault();

		if (!(await loginForm.isValid())) {
			setLoginErrorMessage('Please fix login inputs.');
			setLoginForm(loginForm.clone());
			return;
		}

		try {
			setLoginErrorMessage('');
			setIsAttemptingLogin(true);
			let loginValues = loginForm.toModel<{ email: string; password: string }>();
			// @ts-ignore
			await userService.loginUserByPassword(loginValues.email, loginValues.password);
		} catch (e) {
			setIsAttemptingLogin(false);
			setLoginErrorMessage('Failed logging in.');
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Failed to login.'), 'Login Error');
		}
	}

	function updateLoginForm(control: RsFormControl<any>) {
		setLoginForm(loginForm.clone().update(control));
	}

	return (
		<Page className="rsLoginPage">
			<SplitImageLogoLayout sideImageUrl={login} disableImagekit={true}>
				<Box className={'login'}>
					<Box className={'loginForm'}>
						<Label variant={'subheader1'} weight={'semiBold'} mb={24}>
							Log In
						</Label>
						<form className="signInForm" action={'#'} onSubmit={signInUser}>
							<Box>
								<InputText
									inputMode={'text'}
									className="signInInput"
									placeholder="Email address"
									autocompleteType={'email'}
									type={'text'}
									look={'filled'}
									control={loginForm.get(FormKeys.EMAIL)}
									updateControl={updateLoginForm}
								/>
							</Box>
							<Box>
								<InputText
									icon={[
										{
											iconImg: isPasswordVisible ? 'icon-eye-slash' : 'icon-eye',
											position: 'RIGHT',
											onClick: () => setIsPasswordVisible((prevState) => !prevState),
											color: colors.neutralGrey600
										}
									]}
									inputMode={'text'}
									className="signInInput"
									placeholder="Password"
									autocompleteType={'current-password'}
									type={isPasswordVisible ? 'text' : 'password'}
									look={'filled'}
									control={loginForm.get(FormKeys.PASSWORD)}
									updateControl={updateLoginForm}
								/>
							</Box>
							<Button
								className="signInButton"
								look={'containedPrimary'}
								type="submit"
								disabled={isAttemptingLogin}
							>
								{isAttemptingLogin ? 'Logging in...' : 'Log In'}
							</Button>
							{!!loginErrorMessage.length && (
								<Label className="errorText" variant={'body2'} weight={'regular'}>
									{loginErrorMessage}
								</Label>
							)}
							<Button look={'textPrimary'} className={'forgotPasswordButton'} onClick={() => {}}>
								<Label className={'forgotPassword'} variant={'caption1'} weight={'regular'}>
									Forgot your password?
								</Label>
							</Button>
						</form>
					</Box>
				</Box>
			</SplitImageLogoLayout>
		</Page>
	);
};

export default LoginPage;
