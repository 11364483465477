import * as React from 'react';
import './PromptItem.scss';
import { Box, Button, Icon, Label, rsToastify } from '@redskytech/framework/ui';
import classNames from 'classnames';
import { StatusIconMap, TestStatus } from '../../pages/testDetailsPage/TestDetailsPage';
import serviceFactory from '../../services/serviceFactory';
import { TestPrompt } from '../../services/testFixture/ITestFixtureService';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { useEffect } from 'react';

interface PromptItemProps extends TestPrompt {
	disabled: boolean;
	disableAudio?: boolean;
}

const PromptItem: React.FC<PromptItemProps> = (props) => {
	const testerShimStatus = useRecoilValue(globalState.testerShimStatus);

	useEffect(() => {
		if (props.disableAudio) return;
		// Play mp3 audio when mounted
		const audio = new Audio('/alert_sound.mp3');
		audio.play().catch(console.error);
	}, [props.disableAudio]);

	function handleOnClick(value: string, label: string, id: string) {
		if (testerShimStatus.status === 'DISCONNECTED') {
			rsToastify.error('Tester shim is not connected.', 'Tester Shim Not Connected');
			return;
		}
		const socketioService = serviceFactory.get('SocketioService');
		socketioService.sendPromptResponse({
			name: 'command',
			command: 'promptButton',
			data: {
				id: id,
				button: {
					label,
					value
				}
			}
		});
	}

	function renderButtons() {
		return [...props.buttons]
			.sort((a, b) => {
				return a.position - b.position;
			})
			.map((button) => {
				return (
					<Button
						key={button.label}
						look={'outlinedPrimary'}
						onClick={() => handleOnClick(button.value, button.label, props.id)}
						small
						disabled={props.disabled}
					>
						{button.label}
					</Button>
				);
			});
	}

	function convertLevelToStatus(level: Omit<TestPrompt['level'], 'default'>): TestStatus {
		switch (level) {
			case 'alert':
			case 'warn':
				return 'WARNING';
			case 'error':
				return 'ERROR';
			case 'success':
				return 'SUCCESS';
			case 'info':
				return 'INFO';
			default:
				return 'PENDING';
		}
	}

	return (
		<Box className={classNames('rsPromptItem', props.level.toLowerCase())}>
			<Box display={'flex'} gap={8}>
				{props.level !== 'default' && (
					<Icon {...StatusIconMap[convertLevelToStatus(props.level)]} fontSize={24} />
				)}
				<Label variant={'body1'} weight={'regular'}>
					{props.text}
				</Label>
			</Box>
			<Box display={'flex'} gap={16}>
				{renderButtons()}
			</Box>
		</Box>
	);
};
export default PromptItem;
