import * as React from 'react';
import './InfoItem.scss';
import { Box, Label } from '@redskytech/framework/ui';

interface InfoItemProps {
	title: string;
	info: string;
	onClick?: () => void;
}

const InfoItem: React.FC<InfoItemProps> = (props) => {
	return (
		<Box className={'rsInfoItem'}>
			<Label variant={'overline'} weight={'regular'} mb={8}>
				{props.title}
			</Label>
			<Label
				variant={'body1'}
				weight={'regular'}
				className={props.onClick ? 'parentLink' : ''}
				onClick={props.onClick}
			>
				{props.info}
			</Label>
		</Box>
	);
};
export default InfoItem;
