import * as React from 'react';
import './ChecklistSection.scss';
import { Box, Checkbox, Label, RsFormControl, RsFormGroup } from '@redskytech/framework/ui';

interface ChecklistSectionProps {
	checklist: string[];
	checklistFormGroup: RsFormGroup;
	updateChecklistControl: (update: RsFormControl<boolean>) => void;
}

export interface CheckListItem {
	description: string;
	details?: string;
	checked: boolean;
}

export interface CheckListResult {
	checklist: CheckListItem[];
}

const ChecklistSection: React.FC<ChecklistSectionProps> = (props) => {
	return (
		<Box className={'rsChecklistSection'}>
			<Box className={'dividerLine'} />
			<Label variant={'subheader2'} weight={'regular'}>
				Inspection Checklist
			</Label>
			<Box display={'flex'} flexDirection={'column'} gap={16}>
				{props.checklist.map((checklistItem, index) => {
					return (
						<Checkbox
							key={checklistItem}
							labelText={checklistItem}
							look={'containedPrimary'}
							control={props.checklistFormGroup.get(index.toString())}
							updateControl={props.updateChecklistControl}
							checked={props.checklistFormGroup.get(index.toString()).value === true}
						/>
					);
				})}
			</Box>
		</Box>
	);
};

export default ChecklistSection;
