import * as React from 'react';
import { useState } from 'react';
import './AssemblyRecordsPage.scss';
import { Page } from '@redskytech/framework/996';
import { Box, InputText, Label, RsFormControl, RsFormGroup, rsToastify } from '@redskytech/framework/ui';
import {
	DataTableFilterMeta,
	DataTableFilterMetaData,
	DataTableOperatorFilterMetaData,
	DataTableRowClickEventParams
} from 'primereact/datatable';
import useIsMounted from '../../customHooks/useIsMounted';
import { DateUtils, WebUtils } from '../../utils/utils';
import router from '../../utils/router';
import PageHeader from '../../components/pageHeader/PageHeader';
import TableSearchHeader from '../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import ListDataTable from '../../components/listDataTable/ListDataTable';
import classNames from 'classnames';
import ColumnHeader from '../../components/listDataTable/columnHeader/ColumnHeader';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import useDataTableFilters from '../../customHooks/useDataTableFilters';
import { ApiRequestV1 } from '../../generated/apiRequests';
import SelectableInputText from '../../components/selectableInputText/SelectableInputText';
import serviceFactory from '../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { ngAllowedPartNumbers } from '../../services/assembly/assembly.data';

interface AssemblyRecordsPageProps {}

const FieldKeys = {
	NAME: 'part.name',
	SERIAL_NUMBER: 'part.serialNumber',
	PART_NUMBER: 'part.partNumber',
	HARDWARE_REV: 'part.hardwareRev',
	CREATED_ON: 'part.createdOn',
	CREATED_FIRST_NAME: 'createdUser.firstName',
	CREATED_LAST_NAME: 'createdUser.lastName',
	MODIFIED_ON: 'part.modifiedOn',
	MODIFIED_FIRST_NAME: 'modifiedUser.firstName',
	MODIFIED_LAST_NAME: 'modifiedUser.lastName'
};

const AssemblyRecordsPage: React.FC<AssemblyRecordsPageProps> = () => {
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);
	const queryParams = router.getQueryParams<{
		sortField: string;
		sortOrder: string;
		globalSearch: string;
		page: number;
		perPage: number;
		filter: string;
	}>([
		{
			key: 'sortField',
			default: FieldKeys.MODIFIED_ON,
			type: 'string'
		},
		{
			key: 'sortOrder',
			default: 'DESC',
			type: 'string'
		},
		{
			key: 'globalSearch',
			default: '',
			type: 'string'
		},
		{
			key: 'page',
			default: 1,
			type: 'integer'
		},
		{
			key: 'perPage',
			default: 50,
			type: 'integer'
		},
		{
			key: 'filter',
			default: '',
			type: 'string'
		}
	]);
	const [assemblyRecords, setAssemblyRecords] = useState<RedSky.RsPagedResponseData<Api.V1.Part.Paged.Get.Res[]>>({
		data: [],
		total: 0
	});
	const [quickLookupControl, setQuickLookupControl] = useState<RsFormControl<string>>(new RsFormControl('quick', ''));
	const [isQuickLookupSelected, setIsQuickLookupSelected] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeSortColumn, setActiveSortColumn] = useState<string>();
	const initialFiltersFormGroup: RsFormGroup = new RsFormGroup([
		new RsFormControl<string>(FieldKeys.NAME, getQueryParamValueForFilter(FieldKeys.NAME) || ''),
		new RsFormControl<string>(FieldKeys.SERIAL_NUMBER, getQueryParamValueForFilter(FieldKeys.SERIAL_NUMBER) || ''),
		new RsFormControl<string>(FieldKeys.PART_NUMBER, getQueryParamValueForFilter(FieldKeys.PART_NUMBER) || ''),
		new RsFormControl<string>(FieldKeys.HARDWARE_REV, getQueryParamValueForFilter(FieldKeys.HARDWARE_REV) || ''),
		new RsFormControl<string>(FieldKeys.CREATED_ON, getQueryParamValueForFilter(FieldKeys.CREATED_ON) || ''),
		new RsFormControl<string>(
			FieldKeys.CREATED_FIRST_NAME,
			getQueryParamValueForFilter(FieldKeys.CREATED_FIRST_NAME) || ''
		),
		new RsFormControl<string>(FieldKeys.MODIFIED_ON, getQueryParamValueForFilter(FieldKeys.MODIFIED_ON) || ''),
		new RsFormControl<string>(
			FieldKeys.MODIFIED_FIRST_NAME,
			getQueryParamValueForFilter(FieldKeys.MODIFIED_FIRST_NAME) || ''
		)
	]);
	const initialFilters: DataTableFilterMeta = {
		[FieldKeys.NAME]: {
			operator: FilterOperator.AND,
			constraints: [{ value: getQueryParamValueForFilter(FieldKeys.NAME), matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.SERIAL_NUMBER]: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: getQueryParamValueForFilter(FieldKeys.SERIAL_NUMBER), matchMode: FilterMatchMode.CONTAINS }
			]
		},
		[FieldKeys.PART_NUMBER]: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: getQueryParamValueForFilter(FieldKeys.PART_NUMBER), matchMode: FilterMatchMode.CONTAINS }
			]
		},
		[FieldKeys.HARDWARE_REV]: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: getQueryParamValueForFilter(FieldKeys.HARDWARE_REV), matchMode: FilterMatchMode.CONTAINS }
			]
		},
		[FieldKeys.CREATED_ON]: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: getQueryParamValueForFilter(FieldKeys.CREATED_ON), matchMode: FilterMatchMode.CONTAINS }
			]
		},
		[FieldKeys.CREATED_FIRST_NAME]: {
			operator: FilterOperator.AND,
			constraints: [
				{
					value: getQueryParamValueForFilter(FieldKeys.CREATED_FIRST_NAME),
					matchMode: FilterMatchMode.CONTAINS
				}
			]
		},
		[FieldKeys.CREATED_LAST_NAME]: {
			operator: FilterOperator.OR,
			constraints: [
				{ value: getQueryParamValueForFilter(FieldKeys.CREATED_LAST_NAME), matchMode: FilterMatchMode.CONTAINS }
			]
		},
		[FieldKeys.MODIFIED_ON]: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: getQueryParamValueForFilter(FieldKeys.MODIFIED_ON), matchMode: FilterMatchMode.EQUALS }
			]
		},
		[FieldKeys.MODIFIED_FIRST_NAME]: {
			operator: FilterOperator.AND,
			constraints: [
				{
					value: getQueryParamValueForFilter(FieldKeys.MODIFIED_FIRST_NAME),
					matchMode: FilterMatchMode.CONTAINS
				}
			]
		},
		[FieldKeys.MODIFIED_LAST_NAME]: {
			operator: FilterOperator.OR,
			constraints: [
				{
					value: getQueryParamValueForFilter(FieldKeys.MODIFIED_LAST_NAME),
					matchMode: FilterMatchMode.CONTAINS
				}
			]
		}
	};
	const {
		filters,
		activeFilters,
		globalSearch,
		setGlobalSearch,
		handleClearAllFilters,
		handleFilterClear,
		handleFilterApply,
		handleUpdateControl,
		filtersFormGroup
	} = useDataTableFilters(initialFilters, initialFiltersFormGroup, getUpdatedFilters, queryParams.globalSearch);
	const isMounted = useIsMounted();

	async function getData(pageQuery: RedSky.PageQuery) {
		setIsLoading(true);
		setActiveSortColumn(pageQuery.sortBy);

		let filterValues: string[] = [];
		for (let i in filters) {
			if (filters[i].hasOwnProperty('constraints')) {
				(filters[i] as DataTableOperatorFilterMetaData).constraints.forEach(
					(constraint: DataTableFilterMetaData) => {
						if (constraint.value !== null) filterValues.push(`${i}_${constraint.value}`);
					}
				);
			}
		}
		router.updateQueryParams({
			sortField: pageQuery.sortBy || '',
			sortOrder: pageQuery.sortOrder || -1,
			globalSearch,
			page: pageQuery.page || 1,
			perPage: pageQuery.perPage || 50,
			...(filterValues.length && { filter: filterValues.join(',') })
		});
		if (user?.role === 'ngTechnician') {
			// In this case, we quietly extend the filtering to only show the part numbers allowed for the NG technician.
			if (ngAllowedPartNumbers.length > 0) {
				const ngFilterString = ngAllowedPartNumbers.map(
					(value) => `(column:part.partNumber,value:${value},type:contains)`
				);
				pageQuery.filter = pageQuery.filter
					? `${pageQuery.filter}and(${ngFilterString.join('or')})`
					: `(${ngFilterString.join('or')})`;
			} else {
				// This case should never happen. but if the NG technician has no allowed part numbers, we should not show any data.
				setIsLoading(false);
				return;
			}
		}
		try {
			if (!isMounted) return;
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			if (!pageQuery.filter) delete pageQuery.filter;
			const res = await ApiRequestV1.getPartPaged(pageQuery);
			setAssemblyRecords(res);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
		setIsLoading(false);
	}

	function getQueryParamValueForFilter(filterName: string): string | null {
		// Quick check to see if it's in here
		if (!queryParams.filter.includes(filterName)) return null;
		const filters = queryParams.filter.split(',');
		const filter = filters.find((filter) => filter.includes(filterName));
		if (!filter) return null;
		const filterSplit = filter.split('_');
		if (filterSplit.length !== 2) return null;
		return filterSplit[1];
	}

	function getUpdatedFilters(field: string, value: any) {
		const filter: any = filters[field];

		const constraint = [
			{
				value: value,
				matchMode: filter?.constraints[0]?.matchMode
			}
		];
		if (field === FieldKeys.CREATED_FIRST_NAME || field === FieldKeys.MODIFIED_FIRST_NAME) {
			return {
				...filters,
				[field]: {
					...filter,
					constraints: constraint
				},
				[field === FieldKeys.CREATED_FIRST_NAME ? FieldKeys.CREATED_LAST_NAME : FieldKeys.MODIFIED_LAST_NAME]: {
					operator: FilterOperator.OR,
					constraints: constraint
				}
			};
		}
		return {
			...filters,
			[field]: {
				...filter,
				constraints: constraint
			}
		};
	}

	function handleRowClick({ data }: DataTableRowClickEventParams) {
		router.navigate('/assembly/details?pi=' + data.id).catch(console.error);
	}

	async function handleQuickLoopUp(value: string, enterPressed: boolean): Promise<'VALID' | string> {
		if (quickLookupControl.value === '') return 'VALID';
		if (!enterPressed) {
			setIsQuickLookupSelected(false);
			return 'VALID';
		}
		setIsQuickLookupSelected(true);

		const assemblyService = serviceFactory.get('AssemblyService');
		const hardwareIdDecoded = assemblyService.decodeHardwareId(value);
		if (!hardwareIdDecoded) {
			rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
			return 'Invalid Hardware ID';
		}

		ApiRequestV1.getPartByNumbers({
			partNumber: hardwareIdDecoded.partNumber,
			serialNumber: hardwareIdDecoded.serialNumber
		})
			.then((res) => {
				router.navigate('/assembly/details?pi=' + res.id).catch(console.error);
			})
			.catch((e) => {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Part not found');
			});

		return 'VALID';
	}

	return (
		<Page className={'rsAssemblyRecordsPage'}>
			<PageHeader title={'Assembly Records'} />
			<Box className={'pageContent'}>
				<Box display={'grid'} gridTemplateColumns={'1fr 1fr'} alignItems={'center'} gap={32}>
					<SelectableInputText
						control={quickLookupControl}
						updateControl={(updatedControl) => setQuickLookupControl(updatedControl)}
						labelTitle={'Assembly'}
						isSelected={isQuickLookupSelected}
						onBlurOrEnter={handleQuickLoopUp}
						onClick={() => setIsQuickLookupSelected(true)}
					/>
					<Label variant={'subheader1'} weight={'regular'}>
						Scan the hardware identifier of the assembly to view its details, or search for it using the
						table below.
					</Label>
				</Box>
				<ListDataTable
					loading={isLoading}
					onClearAllFilters={handleClearAllFilters}
					onFilterClear={handleFilterClear}
					onFilterApply={handleFilterApply}
					filters={filters}
					rowsPerPageOptions={[50, 250, 500]}
					data={assemblyRecords}
					getData={getData}
					initialRowsPerPage={queryParams.perPage}
					first={(queryParams.page - 1) * queryParams.perPage}
					globalFilter={globalSearch}
					globalFilterFields={[
						FieldKeys.NAME,
						FieldKeys.MODIFIED_FIRST_NAME,
						FieldKeys.MODIFIED_LAST_NAME,
						FieldKeys.CREATED_FIRST_NAME,
						FieldKeys.CREATED_LAST_NAME,
						FieldKeys.HARDWARE_REV,
						FieldKeys.PART_NUMBER,
						FieldKeys.SERIAL_NUMBER
					]}
					sortField={queryParams.sortField}
					sortOrder={queryParams.sortOrder === 'ASC' ? 1 : -1}
					onRowClick={handleRowClick}
					header={
						<TableSearchHeader
							searchValue={globalSearch}
							onChange={(value: string) => setGlobalSearch(value)}
							placeholder={'Search assemblies'}
							title={'Assemblies'}
						/>
					}
				>
					<Column
						className={classNames({ activeFilter: activeFilters.includes(FieldKeys.NAME) })}
						field={FieldKeys.NAME}
						sortField={FieldKeys.NAME}
						header={<ColumnHeader label={'Name'} isActiveSort={activeSortColumn === FieldKeys.NAME} />}
						sortable
						filter
						filterElement={
							<InputText
								inputMode={'text'}
								placeholder={'Name'}
								control={filtersFormGroup.get(FieldKeys.NAME)}
								updateControl={handleUpdateControl}
							/>
						}
						body={(part: Api.V1.Part.Paged.Get.Res) => part.name}
					/>
					<Column
						className={classNames({ activeFilter: activeFilters.includes(FieldKeys.SERIAL_NUMBER) })}
						field={FieldKeys.SERIAL_NUMBER}
						sortField={FieldKeys.SERIAL_NUMBER}
						header={
							<ColumnHeader
								label={'Serial #'}
								isActiveSort={activeSortColumn === FieldKeys.SERIAL_NUMBER}
							/>
						}
						sortable
						filter
						filterElement={
							<InputText
								inputMode={'text'}
								placeholder={'Serial #'}
								control={filtersFormGroup.get(FieldKeys.SERIAL_NUMBER)}
								updateControl={handleUpdateControl}
							/>
						}
						body={(part: Api.V1.Part.Paged.Get.Res) => part.serialNumber}
					/>
					<Column
						className={classNames({ activeFilter: activeFilters.includes(FieldKeys.PART_NUMBER) })}
						field={FieldKeys.PART_NUMBER}
						sortField={FieldKeys.PART_NUMBER}
						header={
							<ColumnHeader label={'Part #'} isActiveSort={activeSortColumn === FieldKeys.PART_NUMBER} />
						}
						sortable
						filter
						filterElement={
							<InputText
								inputMode={'text'}
								placeholder={'Part #'}
								control={filtersFormGroup.get(FieldKeys.PART_NUMBER)}
								updateControl={handleUpdateControl}
							/>
						}
						body={(part: Api.V1.Part.Paged.Get.Res) => part.partNumber}
					/>
					<Column
						className={classNames({ activeFilter: activeFilters.includes(FieldKeys.HARDWARE_REV) })}
						field={FieldKeys.HARDWARE_REV}
						sortField={FieldKeys.HARDWARE_REV}
						header={
							<ColumnHeader
								label={'Hardware Rev'}
								isActiveSort={activeSortColumn === FieldKeys.HARDWARE_REV}
							/>
						}
						sortable
						filter
						filterElement={
							<InputText
								inputMode={'text'}
								placeholder={'Hardware Rev'}
								control={filtersFormGroup.get(FieldKeys.HARDWARE_REV)}
								updateControl={handleUpdateControl}
							/>
						}
						body={(part: Api.V1.Part.Paged.Get.Res) => part.hardwareRev}
					/>
					<Column
						className={classNames({ activeFilter: activeFilters.includes(FieldKeys.MODIFIED_ON) })}
						field={FieldKeys.MODIFIED_ON}
						sortField={FieldKeys.MODIFIED_ON}
						header={
							<ColumnHeader
								label={'Modified On'}
								isActiveSort={activeSortColumn === FieldKeys.MODIFIED_ON}
							/>
						}
						sortable
						filter
						filterElement={
							<InputText
								inputMode={'text'}
								type={'date'}
								control={filtersFormGroup.get(FieldKeys.CREATED_ON)}
								updateControl={(control) => {
									control.value = DateUtils.convertInputDateToServerDate(control.value.toString());
									handleUpdateControl(control);
								}}
							/>
						}
						body={(part: Api.V1.Part.Paged.Get.Res) => DateUtils.displayDate(part.modifiedOn)}
					/>
					<Column
						className={classNames({ activeFilter: activeFilters.includes(FieldKeys.MODIFIED_FIRST_NAME) })}
						field={FieldKeys.MODIFIED_FIRST_NAME}
						sortField={FieldKeys.MODIFIED_FIRST_NAME}
						header={
							<ColumnHeader
								label={'Modified By'}
								isActiveSort={activeSortColumn === FieldKeys.MODIFIED_FIRST_NAME}
							/>
						}
						sortable
						filter
						filterElement={
							<InputText
								inputMode={'text'}
								placeholder={'Modified By'}
								control={filtersFormGroup.get(FieldKeys.MODIFIED_FIRST_NAME)}
								updateControl={handleUpdateControl}
							/>
						}
						body={(part: Api.V1.Part.Paged.Get.Res) =>
							`${part.modifiedUserFirstName} ${part.modifiedUserLastName}`
						}
					/>
					<Column
						className={classNames({ activeFilter: activeFilters.includes(FieldKeys.CREATED_ON) })}
						field={FieldKeys.CREATED_ON}
						sortField={FieldKeys.CREATED_ON}
						header={
							<ColumnHeader
								label={'Created On'}
								isActiveSort={activeSortColumn === FieldKeys.CREATED_ON}
							/>
						}
						sortable
						filter
						filterElement={
							<InputText
								inputMode={'text'}
								type={'date'}
								control={filtersFormGroup.get(FieldKeys.CREATED_ON)}
								updateControl={(control) => {
									control.value = DateUtils.convertInputDateToServerDate(control.value.toString());
									handleUpdateControl(control);
								}}
							/>
						}
						body={(part: Api.V1.Part.Paged.Get.Res) => DateUtils.displayDate(part.createdOn)}
					/>
					<Column
						className={classNames({ activeFilter: activeFilters.includes(FieldKeys.CREATED_FIRST_NAME) })}
						field={FieldKeys.CREATED_FIRST_NAME}
						sortField={FieldKeys.CREATED_FIRST_NAME}
						header={
							<ColumnHeader
								label={'Created By'}
								isActiveSort={activeSortColumn === FieldKeys.CREATED_FIRST_NAME}
							/>
						}
						sortable
						filter
						filterElement={
							<InputText
								inputMode={'text'}
								placeholder={'Created By'}
								control={filtersFormGroup.get(FieldKeys.CREATED_FIRST_NAME)}
								updateControl={handleUpdateControl}
							/>
						}
						body={(part: Api.V1.Part.Paged.Get.Res) =>
							`${part.createdUserFirstName} ${part.createdUserLastName}`
						}
					/>
				</ListDataTable>
			</Box>
		</Page>
	);
};
export default AssemblyRecordsPage;
