import * as React from 'react';
import './KitCheckoutRow.scss';
import {
	Box,
	Checkbox,
	Icon,
	InputText,
	Label,
	LabelInputText,
	RsFormControl,
	RsFormGroup,
	RsValidator,
	RsValidatorEnum,
	rsToastify
} from '@redskytech/framework/ui';
import colors from '../../themes/colors.scss?export';
import AssemblyAuditCheck from '../assemblyAuditCheck/AssemblyAuditCheck';
import TestAuditCheck from '../testAuditCheck/TestAuditCheck';
import { useState, useEffect } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { useRecoilValue } from 'recoil';
import { KitResult } from '../../services/kit/IKitService';
import globalState from '../../state/globalState';
import { KitChecklistEntry } from '../../services/kit/IKitService';
import AssemblyService, { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import KitService from '../../services/kit/KitService';
import { ApiRequestV1 } from '../../generated/apiRequests';
import useIsMounted from '../../customHooks/useIsMounted';

export interface KitCheckoutRowProps {
	HWID: HardwareIdDecoded;
	checklistItems: KitChecklistEntry[];
	removeItem?: (HWID: HardwareIdDecoded) => void;
	globalExpanded?: boolean;
	expansionTrigger?: boolean;
}

const KitCheckoutRow: React.FC<KitCheckoutRowProps> = (props) => {
	const assemblyService = new AssemblyService();
	const kitService = new KitService();
	const [partId, setPartId] = useState<number>(-1);
	const [isExpanded, setIsExpanded] = React.useState(false);
	const kitResults = useRecoilValue<KitResult[]>(globalState.kitResults);
	const [rowComplete, setRowComplete] = useState<boolean>(false);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(new RsFormGroup([]));
	const isMounted = useIsMounted();
	const [testResultCnt, setTestResultCnt] = React.useState<number>(0);
	const [auditResultCnt, setAuditResultCnt] = React.useState<number>(0);
	const [testResultList, setTestResultList] = React.useState<string[]>([]);
	const [auditResultList, setAuditResultList] = React.useState<string[]>([]);

	let res: KitResult | undefined = kitResults.find(
		(prevRes) =>
			prevRes.HWID.hardwareRevision === props.HWID.hardwareRevision &&
			prevRes.HWID.partNumber === props.HWID.partNumber &&
			prevRes.HWID.serialNumber === props.HWID.serialNumber
	);

	useEffect(() => {
		if (props.expansionTrigger == undefined) return;
		if (props.globalExpanded == undefined) return;

		// This will set to the expanded value,
		setIsExpanded(props.globalExpanded);
	}, [props.globalExpanded, props.expansionTrigger]);

	useEffect(() => {
		let kitResult = kitResults.find(
			(kit) =>
				kit.HWID.partNumber === props.HWID.partNumber &&
				kit.HWID.serialNumber === props.HWID.serialNumber &&
				kit.HWID.hardwareRevision === props.HWID.hardwareRevision
		);
		let complete = false;
		if (kitResult) {
			complete = kitResult.completed;
		}
		setRowComplete(complete);
	}, [kitResults]);

	useEffect(() => {
		(async function lookupPart() {
			try {
				let part = await ApiRequestV1.getPartByNumbers({
					partNumber: props.HWID.partNumber,
					serialNumber: props.HWID.serialNumber
				});
				if (isMounted)
					if (part) {
						setPartId(part.id);
					}
			} catch (e) {
				rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
				console.error(e);
			}
		})();
	}, [props.HWID]);

	useEffect(() => {
		setFormGroup(
			new RsFormGroup(
				props.checklistItems.flatMap((item) => {
					if (item.textArea) {
						const booleanKey = item.checklistKey;
						const textKey = item.checklistKey + 'Text';
						return [
							new RsFormControl<boolean>(booleanKey, res ? res.data[booleanKey] : false, [
								new RsValidator(
									RsValidatorEnum.CUSTOM,
									res?.data.checklistKey ? res.data.checklistKey : false,
									(control) => {
										return !!control.value;
									}
								)
							]),
							new RsFormControl<string>(textKey, res ? res.data[textKey] : '', [
								new RsValidator(RsValidatorEnum.CUSTOM, res ? res.data[textKey] : '', (control) => {
									return control.value !== ''; // criteria for the text box validation is that it isn't empty;
								})
							])
						];
					} else {
						const booleanKey = item.checklistKey;
						return [
							new RsFormControl<boolean>(booleanKey, res ? res.data[booleanKey] : false, [
								new RsValidator(
									RsValidatorEnum.CUSTOM,
									res ? res.data.checklistKey : false,
									(control) => {
										return !!control.value;
									}
								)
							])
						];
					}
				})
			)
		);
	}, [props.checklistItems]);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));

		const auditSummary = {
			auditErrorCount: auditResultCnt,
			auditErrors: auditResultList,
			testErrorCount: testResultCnt,
			testErrors: testResultList
		};
		kitService.validateKitCheckout(formGroup, props.HWID, auditSummary);
	}

	function renderExpandedRowItems(items: KitChecklistEntry[]) {
		return items.map((item, index) => {
			return (
				<Box key={index} display={'flex'} flexDirection={'row'} gap={16} alignItems={'center'}>
					<Box paddingY={8}>
						<Checkbox
							id={`${item.checklistKey}${index}${props.HWID.serialNumber.replace(' ', '_')}${
								props.HWID.partNumber
							}`}
							labelText={item.label}
							look={'containedPrimary'}
							control={formGroup.get(item.checklistKey)}
							updateControl={handleUpdateControl}
						/>
					</Box>
					{item.textArea && (
						<InputText
							id={`text${item.checklistKey}${index}${props.HWID.serialNumber.replace(' ', '_')}${
								props.HWID.partNumber
							}`}
							placeholder={'Version'}
							control={formGroup.get(item.checklistKey + 'Text')}
							updateControl={handleUpdateControl}
							inputMode={'text'}
						></InputText>
					)}
				</Box>
			);
		});
	}

	async function handleExpandClick() {
		setIsExpanded(!isExpanded);
	}

	return (
		<Box className={'rsKitCheckoutRow'}>
			<Box className={'kitCheckoutRowHeader'} bgColor={colors.neutralWhite}>
				<Box className={'collapseColumn'} onClick={handleExpandClick}>
					{isExpanded && (
						<Box width={32} height={32} padding={8}>
							<Icon iconImg={'icon-chevron-down'} color={colors.neutralGrey700} />
						</Box>
					)}
					{!isExpanded && (
						<Box width={32} height={32} padding={8}>
							<Icon iconImg={'icon-chevron-up'} color={colors.neutralGrey700} />
						</Box>
					)}
				</Box>
				<Box className={'serialColumn'}>
					<Label color={colors.neutralGrey700} variant={'caption1'} weight={'regular'}>
						{props.HWID.serialNumber} - {assemblyService.getLabelFromPartNumber(props.HWID.partNumber)}
					</Label>
				</Box>
				<Box className={'assembliesColumn'}>
					<Box width={32} height={32} padding={8}>
						{partId < 0 ? (
							<Icon iconImg={'icon-pending'} color={colors.neutralGrey700} />
						) : (
							<AssemblyAuditCheck
								partId={partId}
								mode={'ICON_SUMMARY'}
								auditStats={(auditcnt, auditErrors) => {
									setAuditResultCnt(auditcnt);
									setAuditResultList(auditErrors);
								}}
							/>
						)}
					</Box>
				</Box>
				<Box className={'testsColumn'}>
					<Box width={32} height={32} padding={8}>
						{partId < 0 ? (
							<Icon iconImg={'icon-pending'} color={colors.neutralGrey700} />
						) : (
							<TestAuditCheck
								partId={partId}
								serialNumber={props.HWID.serialNumber}
								mode={'ICON_SUMMARY'}
								testStats={(testcnt, testErrors) => {
									setTestResultCnt(testcnt);
									setTestResultList(testErrors);
								}}
							/>
						)}
					</Box>
				</Box>
				<Box className={'kitCheckColumn'}>
					<Box width={32} height={32} padding={8}>
						{rowComplete ? (
							<Icon iconImg={'icon-check-circle'} color={colors.accentSuccess} />
						) : (
							<Icon iconImg={'icon-pending'} color={colors.neutralGrey700} />
						)}
					</Box>
				</Box>
				<Box className={'deleteColumn'}>
					<Box width={32} height={32} padding={8}>
						<Icon
							cursorPointer
							iconImg={'icon-delete'}
							onClick={(event) => {
								props.removeItem && props.removeItem(props.HWID);
							}}
							color={colors.neutralGrey700}
						/>
					</Box>
				</Box>
			</Box>
			{isExpanded && (
				<Box className={isExpanded ? 'kitCheckoutBody grow-animation' : 'kitCheckoutBody'}>
					{isExpanded && renderExpandedRowItems(props.checklistItems)}
				</Box>
			)}
		</Box>
	);
};

export default KitCheckoutRow;
