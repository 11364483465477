import * as React from 'react';
import './DateRangePicker.scss';
import { DateRangePicker as ReactDateRangePicker, DateRangePickerProps } from 'react-date-range';
import { elements } from 'chart.js';
import { Box, Button } from '@redskytech/framework/ui';

export interface RsDateRangePickerProps extends DateRangePickerProps {
	onClear?: () => void;
	onSave?: () => void;
	elementRef?: React.RefObject<HTMLDivElement>;
}
const DateRangePicker: React.FC<RsDateRangePickerProps> = (props) => {
	const { onSave, onClear, elementRef, ...pickerProps } = props;
	return (
		<Box elementRef={elementRef} className="rsDateRangePicker">
			<ReactDateRangePicker {...pickerProps} />
			<Box className="buttonWrapper">
				<Button look="outlinedPrimary" onClick={onClear}>
					All Time
				</Button>
				<Button look="containedPrimary" onClick={onSave}>
					Save
				</Button>
			</Box>
		</Box>
	);
};

export default DateRangePicker;
