import { Box, Button, Icon, Label, Paper, Popup, popupController, PopupProps } from '@redskytech/framework/ui';
import * as React from 'react';

export interface PdfPopupProps extends PopupProps {
	onDownload: () => void;
}

const PdfPopup: React.FC<PdfPopupProps> = (props) => {
	return (
		<Popup {...props}>
			<Paper className="rsPdfPopup">
				<Box display="flex" justifyContent="space-between" borderBottom={'1px solid black'}>
					<Label variant="h1" weight="bold">
						Kit Checkout
					</Label>
					<Button look="containedPrimary" onClick={props.onDownload}>
						Download PDF
					</Button>
					{/* <Icon iconImg="icon-close" onClick={() => popupController.close(PdfPopup)} /> */}
				</Box>
				{props.children}
			</Paper>
		</Popup>
	);
};

export default PdfPopup;
