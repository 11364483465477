import * as React from 'react';
import {
	Box,
	LabelInputText,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';

interface WindSpeedProps {
	minMax: [number, number];
}

enum FormKeys {
	WIND_SPEED = 'windSpeedObserved'
}

const WindSpeed: React.FC<WindSpeedProps> = (props) => {
	const TEST_NAME: TestKey = 'windSpeed';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.WIND_SPEED, res ? res.data[FormKeys.WIND_SPEED] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return testFixtureService.validateResultInRange(control, props.minMax[0], props.minMax[1]);
				})
			])
		])
	);

	useEffect(() => {
		setFormGroup(
			new RsFormGroup([
				new RsFormControl<string>(FormKeys.WIND_SPEED, res ? res.data[FormKeys.WIND_SPEED] : '', [
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return testFixtureService.validateResultInRange(control, props.minMax[0], props.minMax[1]);
					})
				])
			])
		);
	}, [props.minMax]);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME);
	}

	return (
		<Box className={'rsWindSpeed'}>
			<LabelInputText
				labelTitle={'Enter Observed Windspeed'}
				inputMode={'text'}
				type={'text'}
				control={formGroup.get(FormKeys.WIND_SPEED)}
				updateControl={handleUpdateControl}
				onBlur={() => {
					testFixtureService.validateFlightTest(formGroup, TEST_NAME);
				}}
			/>
		</Box>
	);
};
export default WindSpeed;
