import { PartAssemblyType } from '../assembly/IAssemblyService';
import { Fixture, TestFixture, TestFixtureType } from './ITestFixtureService';

export const testFixtures: { [key in TestFixtureType]: TestFixture } = {
	MILITARY_AIR_VEHICLE_TEST_FIXTURE: {
		label: 'Military Air Vehicle Test Fixture',
		partNumbers: ['TEST-4247476']
	},
	AIR_VEHICLE_BENCH_FIXTURE: {
		label: 'Air Vehicle Bench Fixture',
		partNumbers: ['TEST-4248661']
	},
	AIR_VEHICLE_BURN_FIXTURE: {
		label: 'Air Vehicle Burn Fixture',
		partNumbers: ['BURN-4248661']
	},
	CHASSIS_FIXTURE: {
		label: 'Chassis Fixture',
		partNumbers: ['TEST-4248220']
	},
	PAYLOAD_FIXTURE: {
		label: 'Payload Fixture',
		partNumbers: ['TEST-4246560']
	},
	PAYLOAD_FIXTURE_NG: {
		label: 'NG Payload Fixture',
		partNumbers: ['TEST-4246556']
	},
	CENTERSTAGE_FIXTURE: {
		label: 'Centerstage Fixture',
		partNumbers: ['TEST-4250046']
	},
	PRELIMINARY_CHECKOUT_FIXTURE: {
		label: 'Preliminary Checkout Fixture',
		partNumbers: ['TEST-4247476-PRELIM']
	}
};

const ngPayloadFixtureTestDefinition: Fixture = {
	name: 'NG Payload Bench Test',
	scriptName: 'payloadBench',
	tests: [
		{
			label: 'Check Firmware Version',
			testName: 'checkFirmware',
			pythonArgs: ['-t', 'checkFirmware', '-n']
		},
		{
			label: 'Verify Hardware Versions',
			testName: 'verifyHwVersions',
			pythonArgs: ['-t', 'verifyHwVersions', '-n']
		},
		{
			label: 'Update Part Number',
			testName: 'partNumber',
			pythonArgs: ['-t', 'partNumber', '-n']
		},
		{
			label: 'Friction',
			testName: 'friction',
			pythonArgs: ['-t', 'friction', '-n']
		},
		{
			label: 'Balance',
			testName: 'balance',
			pythonArgs: ['-t', 'balance', '-n']
		},
		{
			label: 'Check PPS',
			testName: 'checkPps',
			pythonArgs: ['-t', 'checkPps', '-n']
		},
		{
			label: 'Check SD Card',
			testName: 'checkSdCard',
			pythonArgs: ['-t', 'checkSdCard', '-n']
		},
		{
			label: 'Video Quality',
			testName: 'videoQuality',
			pythonArgs: ['-t', 'videoQuality', '-n']
		},
		{
			label: 'Inertial Point',
			testName: 'inertialPoint',
			pythonArgs: ['-t', 'inertialPoint', '-n']
		},
		{
			label: 'Check CTD',
			testName: 'checkCtd',
			pythonArgs: ['-t', 'checkCtd', '-n']
		}
	]
};

const payloadTestFixtureTestDefinition: Fixture = {
	name: 'Payload Bench Test',
	scriptName: 'payloadBench',
	tests: [
		{
			label: 'Load Firmware',
			testName: 'loadFirmware',
			pythonArgs: ['-t', 'loadFirmware']
		},
		{
			label: 'Comms: Mission Manager to Gimbal Controller',
			testName: 'commsMMtoGC',
			pythonArgs: ['-t', 'commsMMtoGC']
		},
		{
			label: 'Verify Hardware Versions',
			testName: 'verifyHwVersions',
			pythonArgs: ['-t', 'verifyHwVersions']
		},
		{
			label: 'Mechanical Zero Encoders',
			testName: 'mechZeroEncoders',
			pythonArgs: ['-t', 'mechZeroEncoders']
		},
		{
			label: 'Electrical Zero Encoders',
			testName: 'elecZeroEncoders',
			pythonArgs: ['-t', 'elecZeroEncoders']
		},
		{
			label: 'PIPS Serial',
			testName: 'pipsSerial',
			pythonArgs: ['-t', 'pipsSerial']
		},
		{
			label: 'Param Serial',
			testName: 'paramSerial',
			pythonArgs: ['-t', 'paramSerial']
		},
		{
			label: 'Friction',
			testName: 'friction',
			pythonArgs: ['-t', 'friction']
		},
		{
			label: 'Balance',
			testName: 'balance',
			pythonArgs: ['-t', 'balance']
		},
		{
			label: 'Electrical Zero Refine',
			testName: 'elecZeroRefine',
			pythonArgs: ['-t', 'elecZeroRefine']
		},
		{
			label: 'Check Motors',
			testName: 'checkMotors',
			pythonArgs: ['-t', 'checkMotors']
		},
		{
			label: 'Check SD Card',
			testName: 'checkSdCard',
			pythonArgs: ['-t', 'checkSdCard']
		},
		{
			label: 'Check PPS',
			testName: 'checkPps',
			pythonArgs: ['-t', 'checkPps']
		},
		{
			label: 'Check VSI',
			testName: 'checkVsi',
			pythonArgs: ['-t', 'checkVsi']
		},
		{
			label: 'Video Quality',
			testName: 'videoQuality',
			pythonArgs: ['-t', 'videoQuality']
		},
		{
			label: 'Inertial Point',
			testName: 'inertialPoint',
			pythonArgs: ['-t', 'inertialPoint']
		},
		// {
		// 	label: 'Feature Point (EO)',
		// 	testName: 'featurePointEo',
		// 	pythonArgs: ['-t', 'featurePoint', '-p1', '1', '-p2', 'eo', '-p3', '0', '-p4', '0']
		// },
		// {
		// 	label: 'Feature Point (IR)',
		// 	testName: 'featurePointIr',
		// 	pythonArgs: ['-t', 'featurePoint', '-p1', '1', '-p2', 'ir', '-p3', '0', '-p4', '0']
		// },
		{
			label: 'Check CTD',
			testName: 'checkCtd',
			pythonArgs: ['-t', 'checkCtd']
		}
	]
};

const centerstageTestFixtureTestDefinition: Fixture = {
	name: 'Centerstage Test',
	scriptName: 'payloadCenter',
	tests: [
		{
			label: 'SIB Eeprom',
			testName: 'sibEeprom',
			pythonArgs: ['-t', 'sibEeprom']
		},
		{
			label: 'Check IMU',
			testName: 'checkImu',
			pythonArgs: ['-t', 'checkImu']
		},
		{
			label: 'Check Cameras',
			testName: 'checkCameras',
			pythonArgs: ['-t', 'checkCameras']
		},
		{
			label: 'LRF Range',
			testName: 'lrfRange',
			pythonArgs: ['-t', 'lrfRange']
		},
		{
			label: 'HOB Cal',
			testName: 'hobCal',
			pythonArgs: ['-t', 'hobCal']
		}
	]
};

export const testFixtureTestMap: {
	[key in PartAssemblyType]: {
		[key in TestFixtureType]?: Fixture;
	};
} = {
	NG_FRAG_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE_NG: ngPayloadFixtureTestDefinition
	},
	NG_PENETRATOR_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE_NG: ngPayloadFixtureTestDefinition
	},
	PENETRATOR_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE: payloadTestFixtureTestDefinition
	},
	INERT_PENETRATOR_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE: payloadTestFixtureTestDefinition
	},
	FRAG_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE: payloadTestFixtureTestDefinition
	},
	INERT_FRAG_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE: payloadTestFixtureTestDefinition
	},
	TRAINER_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE: payloadTestFixtureTestDefinition
	},
	INERT_FRAG_CENTERSTAGE_ASSEMBLY: {
		CENTERSTAGE_FIXTURE: centerstageTestFixtureTestDefinition
	},
	INERT_PENETRATOR_CENTERSTAGE_ASSEMBLY: {
		CENTERSTAGE_FIXTURE: centerstageTestFixtureTestDefinition
	},
	PENETRATOR_CENTERSTAGE_ASSEMBLY: {
		CENTERSTAGE_FIXTURE: centerstageTestFixtureTestDefinition
	},
	FRAG_CENTERSTAGE_ASSEMBLY: {
		CENTERSTAGE_FIXTURE: centerstageTestFixtureTestDefinition
	},
	TRAINER_CENTERSTAGE_ASSEMBLY: {
		CENTERSTAGE_FIXTURE: centerstageTestFixtureTestDefinition
	},
	MILITARY_AIR_VEHICLE_ASSEMBLY: {
		MILITARY_AIR_VEHICLE_TEST_FIXTURE: {
			name: 'Military Vehicle Bench Test',
			scriptName: 'vehicleBench',
			tests: [
				{
					label: 'Write SN',
					testName: 'writeSn',
					pythonArgs: ['-t', 'writeSn']
				},
				{
					label: 'Load Firmware',
					testName: 'loadFirmware',
					pythonArgs: ['-t', 'loadFirmware']
				}
			]
		}
	},
	AIR_VEHICLE_ASSEMBLY: {
		AIR_VEHICLE_BENCH_FIXTURE: {
			name: 'Air Vehicle Bench Test',
			scriptName: 'vehicleBench',
			tests: [
				{
					label: 'Check NX Comms',
					testName: 'checkNxComms',
					pythonArgs: ['-t', 'checkNxComms']
				},
				{
					label: 'Check UART Comms',
					testName: 'checkUartComms',
					pythonArgs: ['-t', 'checkUartComms']
				},
				{
					label: 'Write SN',
					testName: 'writeSn',
					pythonArgs: ['-t', 'writeSn']
				},
				{
					label: 'Verify Radio',
					testName: 'verifyRadio',
					pythonArgs: ['-t', 'verifyRadio']
				},
				{
					label: 'Radio Comm',
					testName: 'radioComm',
					pythonArgs: ['-t', 'radioComm']
				},
				{
					label: 'Check PPS to GC',
					testName: 'checkPpsGc',
					pythonArgs: ['-t', 'checkPpsGc']
				},
				{
					label: 'Umbilical Modes',
					testName: 'umbilicalModes',
					pythonArgs: ['-t', 'umbilicalModes']
				},
				{
					label: 'Payload Eeprom',
					testName: 'payloadEeprom',
					pythonArgs: ['-t', 'payloadEeprom']
				},
				{
					label: 'Check Motors',
					testName: 'checkMotors',
					pythonArgs: ['-t', 'checkMotors']
				},
				{
					label: 'Check GPS',
					testName: 'checkGps',
					pythonArgs: ['-t', 'checkGps']
				},
				{
					label: 'Check Mag Baro',
					testName: 'checkMagBaro',
					pythonArgs: ['-t', 'checkMagBaro']
				},
				{
					label: 'Check Payload Video',
					testName: 'checkPayloadVideo',
					pythonArgs: ['-t', 'checkPayloadVideo']
				},
				{
					label: 'Check Payload SD Card',
					testName: 'checkSdCard',
					pythonArgs: ['-t', 'checkSdCard']
				},
				{
					label: 'Check VTO to ESAF',
					testName: 'esafVto',
					pythonArgs: ['-t', 'esafVto']
				}
			]
		},
		AIR_VEHICLE_BURN_FIXTURE: {
			name: 'Air Vehicle Burn In Test',
			scriptName: 'vehicleBurnIn',
			tests: [
				{
					label: 'Run Throttle Profile',
					testName: 'runThrottleProfile',
					pythonArgs: ['-t', 'runThrottleProfile']
				}
			]
		},
		PRELIMINARY_CHECKOUT_FIXTURE: {
			name: 'Preliminary Checkout',
			scriptName: 'prelimCheckout',
			tests: [
				{
					label: 'Verify HWID',
					testName: 'verifyHwid',
					pythonArgs: ['-t', 'verifyHwid']
				},
				{
					label: 'Radio Multipoint',
					testName: 'radioMultipoint',
					pythonArgs: ['-t', 'radioMultipoint']
				},
				{
					label: 'Check Radio PPS',
					testName: 'checkRadioPps',
					pythonArgs: ['-t', 'checkRadioPps']
				},
				{
					label: 'Write Vehicle Name',
					testName: 'writeVehicleName',
					pythonArgs: ['-t', 'writeVehicleName']
				}
			]
		}
	},
	CHASSIS_MAIN_ASSEMBLY: {
		CHASSIS_FIXTURE: {
			name: 'Chassis Test',
			scriptName: 'vehicleChassis',
			tests: [
				{
					label: 'Configure FTDI',
					testName: 'configFtdi',
					pythonArgs: ['-t', 'configFtdi']
				},
				{
					label: 'Load Firmware',
					testName: 'loadFirmware',
					pythonArgs: ['-t', 'loadFirmware']
				},
				{
					label: 'Check Comms',
					testName: 'checkComms',
					pythonArgs: ['-t', 'checkComms']
				},
				{
					label: 'Check IMU',
					testName: 'checkImu',
					pythonArgs: ['-t', 'checkImu']
				},
				{
					label: 'HW Version Check',
					testName: 'hwVersionCheck',
					pythonArgs: ['-t', 'hwVersionCheck']
				},
				{
					label: 'Check LRF',
					testName: 'checkLrf',
					pythonArgs: ['-t', 'checkLrf']
				},
				{
					label: 'Radio Firmware',
					testName: 'radioFirmware',
					pythonArgs: ['-t', 'radioFirmware']
				},
				{
					label: 'Check Radio License',
					testName: 'checkRadioLicense',
					pythonArgs: ['-t', 'checkRadioLicense']
				},
				{
					label: 'Check Radio',
					testName: 'checkRadio',
					pythonArgs: ['-t', 'checkRadio']
				},
				{
					label: 'Nav Video Quality',
					testName: 'videoQuality',
					pythonArgs: ['-t', 'videoQuality']
				},
				{
					label: 'Check Pixhawk SD Card',
					testName: 'checkPxSd',
					pythonArgs: ['-t', 'checkPxSd']
				},
				{
					label: 'Battery Wake',
					testName: 'batteryWake',
					pythonArgs: ['-t', 'batteryWake']
				},
				{
					label: 'Check VTO',
					testName: 'checkVto',
					pythonArgs: ['-t', 'checkVto']
				},
				{
					label: 'Check GPS PPS',
					testName: 'checkGpsPps',
					pythonArgs: ['-t', 'checkGpsPps']
				},
				{
					label: 'Check ESC',
					testName: 'checkEsc',
					pythonArgs: ['-t', 'checkEsc']
				},
				{
					label: 'Check NX Fan',
					testName: 'checkFan',
					pythonArgs: ['-t', 'checkFan']
				}
				// {
				// 	label: 'Param Serial',
				// 	testName: 'paramSerial',
				// 	pythonArgs: ['-t', 'paramSerial']
				// },
				// {
				// 	label: 'Check IMU',
				// 	testName: 'checkImu',
				// 	pythonArgs: ['-t', 'checkImu']
				// },
				// {
				// 	label: 'Check Fan',
				// 	testName: 'checkFan',
				// 	pythonArgs: ['-t', 'checkFan']
				// },

				// {
				// 	label: 'Calibrate Cameras',
				// 	testName: 'calCameras',
				// 	pythonArgs: ['-t', 'calCameras']
				// }
			]
		}
	},
	AIRFRAME_ASSEMBLY: {},
	MAIN_BOARD_ASSEMBLY: {},
	FCU_ASSEMBLY: {},
	FCU_ANTENNA_ASSEMBLY: {},
	BATTERY_ASSEMBLY: {},
	CHARGER_ASSEMBLY: {},
	CONFORMAL_PACKAGING: {},
	GROUND_CONTROL_STATION_KIT: {}
};
