import * as React from 'react';
import { Box } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useState } from 'react';
import EvaluationItem, {
	EvaluationGroup,
	EvaluationOptions
} from '../../../../components/evaluationItem/EvaluationItem';
import ServiceFactory from '../../../../services/serviceFactory';

interface PayloadVerifyEsadReadyProps {}

type PayloadVerifyEsadEvaluationGroup = EvaluationGroup & {
	disarmEsad1ArmToReady: EvaluationOptions | undefined;
	disarmVsi1ArmToReady: EvaluationOptions | undefined;
	disarmEsad2ArmToReady: EvaluationOptions | undefined;
	disarmVsi2ArmToReady: EvaluationOptions | undefined;
	disarmEsad3ArmToReady: EvaluationOptions | undefined;
	disarmVsi3ArmToReady: EvaluationOptions | undefined;
};

type EvaluationKey = keyof PayloadVerifyEsadEvaluationGroup;

const PayloadVerifyEsadReady: React.FC<PayloadVerifyEsadReadyProps> = () => {
	const TEST_NAME: TestKey = 'verifyEsadReady';
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const [evaluations, setEvaluations] = useState<PayloadVerifyEsadEvaluationGroup>({
		disarmEsad1ArmToReady: res ? res.data['disarmEsad1ArmToReady'] : undefined,
		disarmVsi1ArmToReady: res ? res.data['disarmVsi1ArmToReady'] : undefined,
		disarmEsad2ArmToReady: res ? res.data['disarmEsad2ArmToReady'] : undefined,
		disarmVsi2ArmToReady: res ? res.data['disarmVsi2ArmToReady'] : undefined,
		disarmEsad3ArmToReady: res ? res.data['disarmEsad3ArmToReady'] : undefined,
		disarmVsi3ArmToReady: res ? res.data['disarmVsi3ArmToReady'] : undefined
	});

	useEffect(() => {
		testFixtureService.validateEvaluationTest(TEST_NAME, evaluations);
	}, [evaluations]);

	function handleEvaluationClick(evaluation: EvaluationOptions, key: EvaluationKey) {
		setEvaluations((prevState) => {
			let updatedState = { ...prevState };
			updatedState[key] = evaluation;
			return updatedState;
		});
	}
	return (
		<Box className={'rsVerifyEsadReady'} display={'flex'} flexDirection={'column'} gap={16}>
			<EvaluationItem
				label={'Disarm #1: ESAD transitions from "Arm" to "Ready"'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmEsad1ArmToReady');
				}}
				evaluation={evaluations.disarmEsad1ArmToReady}
			/>
			<EvaluationItem
				label={'Disarm #1: VSI shows red in "Arm" and green in "Ready"'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmVsi1ArmToReady');
				}}
				evaluation={evaluations.disarmVsi1ArmToReady}
			/>
			<EvaluationItem
				label={'Disarm #2: ESAD transitions from "Arm" to "Ready"'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmEsad2ArmToReady');
				}}
				evaluation={evaluations.disarmEsad2ArmToReady}
			/>
			<EvaluationItem
				label={'Disarm #2: VSI shows red in "Arm" and green in "Ready"'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmVsi2ArmToReady');
				}}
				evaluation={evaluations.disarmVsi2ArmToReady}
			/>{' '}
			<EvaluationItem
				label={'Disarm #3: ESAD transitions from "Arm" to "Ready"'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmEsad3ArmToReady');
				}}
				evaluation={evaluations.disarmEsad3ArmToReady}
			/>
			<EvaluationItem
				label={'Disarm #3: VSI shows red in "Arm" and green in "Ready"'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmVsi3ArmToReady');
				}}
				evaluation={evaluations.disarmVsi3ArmToReady}
			/>
		</Box>
	);
};
export default PayloadVerifyEsadReady;
