import * as React from 'react';
import { useEffect, useState } from 'react';
import './KitCheckoutGroup.scss';
import { Box, Label } from '@redskytech/framework/ui';
import colors from '../../../themes/colors.scss?export';
import AssemblyService, { HardwareIdDecoded } from '../../../services/assembly/AssemblyService';
import KitCheckoutRow from '../../../components/kitCheckoutRow/KitCheckoutRow';
import { kitGroups } from '../../../services/kit/kit.data';
import { KitGroupType } from '../../../services/kit/IKitService';

export interface KitCheckoutGroupProps {
	group: KitGroupType;
	idList: HardwareIdDecoded[];
	removeItem?: (group: KitGroupType, HWID: HardwareIdDecoded) => void;
	allExpanded?: boolean;
	triggerExpanded?: boolean;
}

const KitCheckoutGroup: React.FC<KitCheckoutGroupProps> = (props) => {
	const assemblyService = new AssemblyService();
	const [allExpanded, setAllExpanded] = useState<boolean>(false);
	const [triggerExpansion, setTriggerExpansion] = useState<boolean>(false);

	useEffect(() => {
		if (props.allExpanded == undefined) return;
		if (props.triggerExpanded == undefined) return;

		// This will set to the expanded value,
		setAllExpanded(props.allExpanded);
		setTriggerExpansion((prev) => {
			return !prev;
		});
	}, [props.allExpanded, props.triggerExpanded]);

	function removeRow(HWID: HardwareIdDecoded) {
		if (props.removeItem) {
			props.removeItem(props.group, HWID);
		}
	}

	function renderGroupRows(idList: HardwareIdDecoded[]) {
		return idList.map((item, index) => {
			let partType = assemblyService.getAssemblyTypeFromPartNumber(item.partNumber);
			return (
				<KitCheckoutRow
					key={`${item.serialNumber.replace(' ', '_')}_${item.partNumber}`}
					removeItem={removeRow}
					HWID={item}
					checklistItems={kitGroups[props.group].checklist}
					globalExpanded={allExpanded}
					expansionTrigger={triggerExpansion}
				></KitCheckoutRow>
			);
		});
	}

	return (
		<Box className={'rsKitCheckoutGroup'}>
			<Label variant={'body1'} weight={'bold'}>
				{kitGroups[props.group].label}
			</Label>
			<Box display={'flex'} gap={16} className={'kitGroupListHeader'} minHeight={16}>
				<Box className={'collapseColumn'}></Box>
				<Box className={'serialColumn'}>
					<Label color={colors.neutralGrey700} variant={'caption1'} weight={'regular'}>
						Serial Number
					</Label>
				</Box>
				<Box className={'assembliesColumn'}>
					<Label color={colors.neutralGrey700} variant={'caption1'} weight={'regular'}>
						Assemblies
					</Label>
				</Box>
				<Box className={'testsColumn'}>
					<Label color={colors.neutralGrey700} variant={'caption1'} weight={'regular'}>
						Tests
					</Label>
				</Box>
				<Box className={'kitCheckColumn'}>
					<Label color={colors.neutralGrey700} variant={'caption1'} weight={'regular'}>
						Kit Checks
					</Label>
				</Box>
				<Box className={'deleteColumn'}></Box>
			</Box>
			{renderGroupRows(props.idList)}
			<Box className={'bottom'} height={32} />
		</Box>
	);
};
export default KitCheckoutGroup;
