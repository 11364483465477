import * as React from 'react';
import './NotesSection.scss';
import { Box, InputTextarea, Label, RsFormControl } from '@redskytech/framework/ui';

interface NotesSectionProps {
	notesControl: RsFormControl<string>;
	updateNotesControl: (update: RsFormControl<string>) => void;
}

const NotesSection: React.FC<NotesSectionProps> = (props) => {
	return (
		<Box className={'rsNotesSection'}>
			<Box className={'dividerLine'} />
			<Label variant={'subheader2'} weight={'regular'} mt={24} mb={16}>
				Notes
			</Label>
			<InputTextarea
				control={props.notesControl}
				updateControl={props.updateNotesControl}
				maxLength={500}
				rows={4}
			/>
		</Box>
	);
};

export default NotesSection;
