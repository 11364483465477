import * as React from 'react';
import './SplitImageLogoLayout.scss';
import { Box, Img } from '@redskytech/framework/ui';

interface SplitImageLogoLayoutProps {
	sideImageUrl: string;
	disableImagekit?: boolean;
	logoUrl?: string;
}

const SplitImageLogoLayout: React.FC<SplitImageLogoLayoutProps> = (props) => {
	return (
		<Box className={'rsSplitImageLogoLayout'}>
			<Box className={'sideImage'}>
				<Img
					disableImageKit={props.disableImagekit}
					width={'1920'}
					height={'100%'}
					alt={'Login Image'}
					className={'mainImage'}
					src={props.sideImageUrl}
					objectFit={'cover'}
				/>
				<Box className={'imageOverlay'} />
				{props.logoUrl && (
					<Box className={'imageTextContainer'}>
						<Img
							disableImageKit={props.disableImagekit}
							src={props.logoUrl}
							className={'wideLogo'}
							width={'500'}
							height={'auto'}
							alt={'company logo'}
						/>
					</Box>
				)}
			</Box>
			{props.children}
		</Box>
	);
};
export default SplitImageLogoLayout;
