import UserService from './user/UserService';
import SocketioService from './socketio/SocketioService';
import TestFixtureService from './testFixture/TestFixtureService';
import AssemblyService from './assembly/AssemblyService';
import CalibrationService from './calibration/CalibrationService';
import LabelService from './label/LabelService';
import KitService from './kit/KitService';
import ReportService from './report/ReportService';

export type ServiceTypes = {
	UserService: UserService;
	SocketioService: SocketioService;
	TestFixtureService: TestFixtureService;
	AssemblyService: AssemblyService;
	CalibrationService: CalibrationService;
	LabelService: LabelService;
	KitService: KitService;
	ReportService: ReportService;
};

class ServiceFactory {
	private serviceMap!: ServiceTypes;

	init(): void {
		this.serviceMap = {
			UserService: new UserService(),
			SocketioService: new SocketioService(),
			TestFixtureService: new TestFixtureService(),
			AssemblyService: new AssemblyService(),
			CalibrationService: new CalibrationService(),
			LabelService: new LabelService(),
			KitService: new KitService(),
			ReportService: new ReportService()
		};

		let key: keyof ServiceTypes;
		for (key in this.serviceMap) {
			this.serviceMap[key].start();
		}
	}

	get<T extends keyof ServiceTypes>(serviceKey: T): ServiceTypes[T] {
		return this.serviceMap[serviceKey];
	}
}
let serviceFactory = new ServiceFactory();
export default serviceFactory;
