export interface TestFixtureStatus {
	currentTestFixtureName: string | null;
	currentTestIndex: number;
	testList: {
		name: string;
		status: 'PENDING' | 'IN_PROGRESS' | 'COMPLETE';
	}[];
}

export interface TestPrompt {
	name?: 'messagePrompt';
	id: string;
	position: number;
	level: 'default' | 'warn' | 'error' | 'success' | 'info';
	text: string;
	buttons: {
		position: number;
		label: string;
		value: string;
	}[];
}

export interface TestCommand {
	name: 'command';
	command: string;
	data: any;
}

export interface TestResult {
	testName: string;
	passed: boolean;
	continuable: boolean;
	hasNotRan?: true;
	data?: any;
	rawData?: any;
	timeStamp: string;
	error?: string;
}

export const optionalTestFixtures: TestFixtureType[] = ['MILITARY_AIR_VEHICLE_TEST_FIXTURE'];

export type TestFixtureType =
	| 'MILITARY_AIR_VEHICLE_TEST_FIXTURE'
	| 'AIR_VEHICLE_BENCH_FIXTURE'
	| 'AIR_VEHICLE_BURN_FIXTURE'
	| 'CHASSIS_FIXTURE'
	| 'PAYLOAD_FIXTURE_NG'
	| 'PAYLOAD_FIXTURE'
	| 'CENTERSTAGE_FIXTURE'
	| 'PRELIMINARY_CHECKOUT_FIXTURE';

export interface TestFixture {
	label: string;
	partNumbers: string[];
}

// Individual test that invokes the python script
export interface PythonTest {
	label: string;
	testName: string;
	pythonArgs: string[];
}

// Fixture is a collection of tests that can be run based upon the assembly type
export interface Fixture {
	partId?: number;
	name: string;
	scriptName: string;
	tests: PythonTest[];
}
