import * as React from 'react';
import {
	Box,
	LabelInputText,
	RsFormControl,
	RsFormGroup,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useState } from 'react';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';

interface MagnetometerValidationProps {
	absHeadingErrorMax: number;
	direction: 'North' | 'East' | 'South' | 'West';
}

enum FormKeys {
	LEVEL_VALUE = 'level',
	T_FORWARD_VALUE = 'tiltForward',
	T_BACKWARD_VALUE = 'tiltBackward',
	T_LEFT_VALUE = 'tiltLeft',
	T_RIGHT_VALUE = 'tiltRight'
}

const directionToCardinalDegMap: { [key in MagnetometerValidationProps['direction']]: number } = {
	North: 0,
	East: 90,
	South: 180,
	West: 270
};

const MagnetometerValidation: React.FC<MagnetometerValidationProps> = (props) => {
	const TEST_NAME: TestKey | string = `magnetometerValidation${props.direction}`;
	const testFixtureService = ServiceFactory.get('TestFixtureService');

	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);

	function validationForDirection(control: RsFormControl<IRsFormControl>) {
		if (props.direction === 'North') {
			// Special case for north to account for wrapping around 360
			// also handle a negative value just in case
			return (
				testFixtureService.validateResultInRange(control, 360 - props.absHeadingErrorMax, 360) ||
				testFixtureService.validateResultInRange(control, 0, props.absHeadingErrorMax, true)
			);
		}

		return testFixtureService.validateResultInRange(
			control,
			directionToCardinalDegMap[props.direction] - props.absHeadingErrorMax,
			directionToCardinalDegMap[props.direction] + props.absHeadingErrorMax
		);
	}

	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.LEVEL_VALUE, res ? res.data[FormKeys.LEVEL_VALUE] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', validationForDirection)
			]),
			new RsFormControl<string>(FormKeys.T_FORWARD_VALUE, res ? res.data[FormKeys.T_FORWARD_VALUE] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', () => {
					return true;
				})
			]),
			new RsFormControl<string>(FormKeys.T_BACKWARD_VALUE, res ? res.data[FormKeys.T_BACKWARD_VALUE] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', () => {
					return true;
				})
			]),
			new RsFormControl<string>(FormKeys.T_RIGHT_VALUE, res ? res.data[FormKeys.T_RIGHT_VALUE] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', () => {
					return true;
				})
			]),
			new RsFormControl<string>(FormKeys.T_LEFT_VALUE, res ? res.data[FormKeys.T_LEFT_VALUE] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', () => {
					return true;
				})
			])
		])
	);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	return (
		<Box className={'rsMagnetometerValidation'} display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={16}>
			<LabelInputText
				labelTitle={'Level'}
				inputMode={'text'}
				type={'text'}
				control={formGroup.get(FormKeys.LEVEL_VALUE)}
				updateControl={handleUpdateControl}
				onBlur={() => {
					testFixtureService.validateFlightTest(formGroup, TEST_NAME as TestKey, true);
				}}
			/>
			<LabelInputText
				labelTitle={'Tilt Forward'}
				inputMode={'text'}
				type={'text'}
				control={formGroup.get(FormKeys.T_FORWARD_VALUE)}
				updateControl={handleUpdateControl}
				onBlur={() => {
					testFixtureService.validateFlightTest(formGroup, TEST_NAME as TestKey, true);
				}}
			/>
			<LabelInputText
				labelTitle={'Tilt Backward'}
				inputMode={'text'}
				type={'text'}
				control={formGroup.get(FormKeys.T_BACKWARD_VALUE)}
				updateControl={handleUpdateControl}
				onBlur={() => {
					testFixtureService.validateFlightTest(formGroup, TEST_NAME as TestKey, true);
				}}
			/>
			<LabelInputText
				labelTitle={'Tilt Left'}
				inputMode={'text'}
				type={'text'}
				control={formGroup.get(FormKeys.T_LEFT_VALUE)}
				updateControl={handleUpdateControl}
				onBlur={() => {
					testFixtureService.validateFlightTest(formGroup, TEST_NAME as TestKey, true);
				}}
			/>
			<LabelInputText
				labelTitle={'Tilt Right'}
				inputMode={'text'}
				type={'text'}
				control={formGroup.get(FormKeys.T_RIGHT_VALUE)}
				updateControl={handleUpdateControl}
				onBlur={() => {
					testFixtureService.validateFlightTest(formGroup, TEST_NAME as TestKey, true);
				}}
			/>
		</Box>
	);
};
export default MagnetometerValidation;
