import * as React from 'react';
import './FirmwareEntry.scss';
import { Box, Label, Icon } from '@redskytech/framework/ui';
import colors from '../../../themes/colors.scss?export';

export interface FirmwareFileEntryFields {
	isSelected: boolean;
	isDownloaded: boolean;
	name: string;
	url: string;
	version: string;
	size: string;
	isDownloading: boolean;
}

export interface FirmwareEntryProps {
	isSelected: boolean;
	isDownloaded: boolean;
	version: string;
	size: string;
	isEven: boolean;
	isDownloading: boolean;
	onSetActive: () => void;
	onDownload: () => void;
	onDelete: () => void;
}

const FirmwareEntry: React.FC<FirmwareEntryProps> = (props) => {
	function renderDownloadIcon() {
		if (props.isDownloading) {
			return (
				<Box className={'downloadIcon'}>
					<Icon className={'downloadIcon'} color={colors.neutralGrey600} iconImg={'icon-spinner'} />
				</Box>
			);
		}

		if (props.isSelected && props.isDownloaded) {
			return <Box className={'downloadIcon'} width={16} height={16} />;
		}

		if (props.isDownloaded) {
			return (
				<Icon
					className={'downloadIcon'}
					color={colors.neutralGrey600}
					onClick={props.onDelete}
					iconImg={'icon-delete'}
				/>
			);
		} else {
			return (
				<Icon
					className={'downloadIcon'}
					color={colors.neutralGrey600}
					onClick={props.onDownload}
					iconImg={'icon-download'}
				/>
			);
		}
	}

	function renderActiveIcon() {
		if (props.isDownloaded) {
			if (props.isSelected) {
				return <Icon className={'activeIcon'} color={colors.primaryBlue700} iconImg={'icon-check-circle'} />;
			} else {
				return (
					<Icon
						className={'activeIcon'}
						onClick={props.onSetActive}
						color={colors.neutralGrey600}
						iconImg={'icon-check-circle-outlined'}
					/>
				);
			}
		} else {
			return <Box className={'activeIcon'} width={16} height={16} />;
		}
	}

	return (
		<Box
			display={'flex'}
			gap={16}
			className={'rsFirmwareEntry'}
			bgColor={props.isEven ? colors.neutralWhite : colors.primaryBlue50}
		>
			<Box className={'activeBox'}>{renderActiveIcon()}</Box>
			<Box className={'versionLabel'}>
				<Label variant={'body2'} weight={'semiBold'}>
					{props.version}
				</Label>
			</Box>
			<Box className={'sizeLabel'}>
				<Label variant={'body2'} weight={'semiBold'}>
					{props.size}
				</Label>
			</Box>

			<Box className={'downloadBox'}>{renderDownloadIcon()}</Box>
		</Box>
	);
};

export default FirmwareEntry;
