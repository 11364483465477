import * as React from 'react';
import { Box, Checkbox, RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';

interface HighSpeedBoxFlightProps {}

enum FormKeys {
	MS_30 = 'thirtyMeterPerSecondBoxFlightPerformed'
}

const HighSpeedBoxFlight: React.FC<HighSpeedBoxFlightProps> = () => {
	const TEST_NAME: TestKey = 'highSpeedBoxFlight';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.MS_30, res ? res.data[FormKeys.MS_30] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			])
		])
	);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME);
	}

	return (
		<Box className={'rsHighSpeedBoxFlight'} display={'flex'} flexDirection={'column'} gap={16}>
			<Checkbox
				labelText={'30 m/s'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.MS_30)}
				updateControl={handleUpdateControl}
			/>
		</Box>
	);
};
export default HighSpeedBoxFlight;
