import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Popup, popupController, PopupProps, rsToastify, Button } from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import { Chart, registerables } from 'chart.js';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { Line } from 'react-chartjs-2';
import { WebUtils } from '../../utils/utils';
import zoomPlugin from 'chartjs-plugin-zoom';
import './BurnInPlotPopup.scss';

Chart.register(...registerables, zoomPlugin);

export interface BurnInPlotProps extends PopupProps {
	testName: string;
	testResultId: number;
}

interface FilteredResult {
	testName: string;
	passed: boolean;
	data: Record<string, string[]>;
}

interface Dataset {
	label: string;
	data: number[];
	borderColor: string;
	borderWidth: number;
}

interface ChartData {
	labels: string[];
	datasets: Dataset[];
	minDataValue: number;
	maxDataValue: number;
}

const BurnInPlotPopup: React.FC<BurnInPlotProps> = (props) => {
	const [testResultDetails, setTestResultDetails] = useState<Api.V1.TestResult.Get.Res | undefined>();
	const [filteredResults, setFilteredResults] = useState<FilteredResult[]>([]);
	const [chartDataList, setChartDataList] = useState<ChartData[]>([]);
	const [currentPage, setCurrentPage] = useState(0);

	useEffect(() => {
		(async function () {
			try {
				let res = await ApiRequestV1.getTestResult({ id: props.testResultId });
				setTestResultDetails(res);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Test Result Fetch Error'), 'Server Error');
			}
		})();
	}, [props.testResultId]);

	useEffect(() => {
		if (testResultDetails) {
			const resultData = testResultDetails.resultData;
			const results = Object.values(resultData).filter(
				(result: FilteredResult) => result.testName === 'runThrottleProfile'
			) as FilteredResult[];
			setFilteredResults(results);
		}
	}, [testResultDetails]);

	useEffect(() => {
		if (filteredResults.length > 0) {
			const allChartData = filteredResults.map((result) => constructChartData(result));
			setChartDataList(allChartData);
		}
	}, [filteredResults]);

	function constructChartData(filteredResult: FilteredResult): ChartData {
		const data: Record<string, string[]> = filteredResult.data;
		if (data) {
			const keys: string[] = Object.keys(data).filter(
				(key) => key !== 'rollDeg' && key !== 'pitchDeg' && key !== 'nxSsdTempC' && key !== 'nxFanRpm'
			);

			const keyDisplayNames: Record<string, { displayName: string; color: string }> = {
				nxCpuTempC: { displayName: 'NX CPU', color: '#FF0000' },
				nxGpuTempC: { displayName: 'NX GPU', color: '#008080' },
				radioTempC: { displayName: 'Radio', color: '#0000FF' },
				esc1TempC: { displayName: 'ESC 1', color: '#00FF00' },
				esc2TempC: { displayName: 'ESC 2', color: '#FFC0CB' },
				esc3TempC: { displayName: 'ESC 3', color: '#FFA500' },
				esc4TempC: { displayName: 'ESC 4', color: '#800080' }
			};

			let allDataValues: number[] = [];

			const datasets = keys.map((key) => {
				let lastValidValue: number | null = null;

				const dataPoints = data[key]
					.map((value) => {
						const numValue = parseFloat(value);
						if (!isNaN(numValue) && numValue !== 0) {
							lastValidValue = numValue;
							return numValue;
						}
						return lastValidValue;
					})
					.filter((value): value is number => value !== null);

				allDataValues = allDataValues.concat(dataPoints);

				return {
					label: (keyDisplayNames[key] && keyDisplayNames[key].displayName) || key,
					data: dataPoints,
					borderColor: keyDisplayNames[key] && keyDisplayNames[key].color,
					borderWidth: 4
				};
			});

			const maxLength = Math.max(...keys.map((key) => data[key].length));
			const labels = Array.from({ length: maxLength }, (_, index) => index * 15);

			let minValue = Math.min(...allDataValues) - 10;
			let maxValue = Math.max(...allDataValues) + 10;

			if (allDataValues.length === 0) {
				minValue = 0;
				maxValue = 100;
			}

			return {
				labels: labels.map((label) => label.toString()),
				datasets: datasets,
				minDataValue: minValue,
				maxDataValue: maxValue
			};
		} else {
			return {
				labels: [],
				datasets: [],
				minDataValue: 0,
				maxDataValue: 100
			};
		}
	}

	function handlePreviousPage() {
		if (currentPage > 0) setCurrentPage(currentPage - 1);
	}

	function handleNextPage() {
		if (currentPage < chartDataList.length - 1) setCurrentPage(currentPage + 1);
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsBurnInPlotPopup'}>
				<PopupHeader
					title={'Burn In Plot'}
					onClose={() => {
						popupController.close(BurnInPlotPopup);
					}}
				/>
				<Box className={'paginationControls'} display={'flex'} alignItems={'center'} gap={8}>
					<Button
						onClick={handlePreviousPage}
						disabled={currentPage === 0}
						className={'paginationButton'}
						look={'outlinedPrimary'}
					>
						{'<'}
					</Button>
					<span>
						{currentPage + 1} / {chartDataList.length}
					</span>
					<Button
						onClick={handleNextPage}
						disabled={currentPage === chartDataList.length - 1}
						className={'paginationButton'}
						look={'outlinedPrimary'}
					>
						{'>'}
					</Button>
				</Box>
				<Box className={'lineGraphContainer'}>
					{chartDataList.length > 0 && (
						<Line
							data={chartDataList[currentPage]}
							options={{
								scales: {
									x: {
										display: true
									},
									y: {
										beginAtZero: true,
										max: chartDataList[currentPage].maxDataValue,
										min: chartDataList[currentPage].minDataValue
									}
								},
								plugins: {
									zoom: {
										zoom: {
											wheel: {
												enabled: true
											},
											pinch: {
												enabled: true
											}
										},
										pan: {
											enabled: true,
											mode: 'xy'
										}
									},
									legend: {
										display: true,
										position: 'bottom'
									}
								},
								elements: {
									line: {
										tension: 0.5
									},
									point: {
										radius: 0,
										hoverRadius: 0
									}
								},
								responsive: true,
								maintainAspectRatio: true,
								animation: false
							}}
						/>
					)}
				</Box>
			</Box>
		</Popup>
	);
};

export default BurnInPlotPopup;
