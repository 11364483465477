import { Label, Paper } from '@redskytech/framework/ui';
import classNames from 'classnames';
import * as React from 'react';
import './FamilyTreeTile.scss';

export interface FamilyTreeTileProps {
	familyTreeData: CustomTypes.PartFamilyTreeResponse['familyTree'];
	onClick: () => void;
	isCurrentView: boolean;
}

const FamilyTreeTile: React.FC<FamilyTreeTileProps> = (props) => {
	return (
		<Paper
			className={classNames('rsFamilyTreeTile', { isCurrentView: props.isCurrentView })}
			onClick={props.onClick}
		>
			<Label variant="body1" weight={'regular'}>
				Name: {props.familyTreeData!.name}
			</Label>
			<Label variant="body1" weight={'regular'}>
				Part #: {props.familyTreeData!.partNumber}
			</Label>
		</Paper>
	);
};

export default FamilyTreeTile;
