import * as React from 'react';
import './ActivityLogPaper.scss';
import PaperHeaderBar from '../../../components/paperHeaderBar/PaperHeaderBar';
import { Box, Label } from '@redskytech/framework/ui';
import { DateUtils } from '../../../utils/utils';

interface ActivityLogPaperProps {
	actionLogs: Api.V1.ActionLog.Paged.Get.Res[];
}

const ActivityLogPaper: React.FC<ActivityLogPaperProps> = (props) => {
	function renderActionLogs() {
		return props.actionLogs.map((log) => {
			return (
				<Box className={'logItem'} key={log.id}>
					<Label variant={'body1'} weight={'regular'}>
						{DateUtils.displayDate(log.createdOn)}
					</Label>
					<Label variant={'body1'} weight={'regular'}>
						{`${log.userFirstName} ${log.userLastName}`}
					</Label>
					<Label variant={'body1'} weight={'regular'}>
						{log.details}
					</Label>
				</Box>
			);
		});
	}

	return (
		<PaperHeaderBar title={'Activity'} className={'rsActivityLogPaper'}>
			<Box className={'logItem'} mt={0}>
				<Label variant={'overline'} weight={'regular'}>
					Modified On
				</Label>
				<Label variant={'overline'} weight={'regular'}>
					Modified By
				</Label>
				<Label variant={'overline'} weight={'regular'}>
					Action
				</Label>
			</Box>
			{renderActionLogs()}
		</PaperHeaderBar>
	);
};
export default ActivityLogPaper;
