import * as React from 'react';
import { useEffect, useState } from 'react';
import './KitCheckoutSection.scss';
import { Box, Button, Label, RsFormControl, rsToastify } from '@redskytech/framework/ui';
import SelectableInputText from '../../../components/selectableInputText/SelectableInputText';
import useIsMounted from '../../../customHooks/useIsMounted';
import { ApiRequestV1 } from '../../../generated/apiRequests';

interface KitCheckoutSectionProps {
	onKitSelected: (KitNumber: string) => void;
	labelText?: string;
	pageTitle?: string;
	clearAfterEnter?: boolean;
	rightNode?: React.ReactNode;
}

const KitCheckoutSection: React.FC<KitCheckoutSectionProps> = (props) => {
	const [kitNumberControl, setKitNumberControl] = useState<RsFormControl<string>>(
		new RsFormControl<string>('kitNumber', '')
	);
	const [isSelected, setIsSelected] = useState<boolean>(true);
	const isMounted = useIsMounted();
	const [kitNumber, setKitNumber] = useState<string>('');

	function handleUpdateControl(control: RsFormControl<string>) {
		if (isMounted) setKitNumberControl(control);
	}

	function handleIsSelected() {
		if (isMounted) setIsSelected(true);
	}

	async function handleStartNewKit() {
		(async function getKit() {
			const newKitNumber = await ApiRequestV1.getKitNext();
			if (!newKitNumber) return;

			const kitInitial = {
				checklistResults: { kitResults: [] },
				children: [],
				completed: false,
				kitNumber: `${newKitNumber}`,
				poNumber: ''
			};

			await ApiRequestV1.postKitAssemble(kitInitial);

			//set the property on ourselves,
			setKitNumber(newKitNumber);
			// call the parent function
			props.onKitSelected(newKitNumber);
		})();
		return;
	}

	return (
		<Box className={'rsKitCheckoutSection'} display={'flex'} gap={32} flexDirection={'column'}>
			<Label variant={'subheader1'} weight={'regular'}>
				{props.labelText ? props.labelText : 'Start A New Kit, or enter a previous Kit Number to continue'}
			</Label>

			<Button look={'containedPrimary'} onClick={handleStartNewKit}>
				Start a New Kit
			</Button>
			<Box className={'dividerLine'}></Box>
			<Box alignContent={'center'} justifyContent={'center'} alignSelf={'center'}>
				Or
			</Box>
			<Box className={'dividerLine'}></Box>
			<SelectableInputText
				labelTitle={'Continue Existing Kit'}
				control={kitNumberControl}
				updateControl={handleUpdateControl}
				isSelected={isSelected}
				placeholder={'Enter Kitnumber YYYYMMDD-XXX'}
				onClick={handleIsSelected}
				onBlurOrEnter={async (value) => {
					// Clearing out is ok
					if (!value) return 'VALID';

					// attempt to find a kit by the number provided
					try {
						const kit = await ApiRequestV1.getKitByNumbers({ kn: value });
						console.log(kit);
						// if we don't find a kit, we should alert the user
						if (!kit) {
							rsToastify.error(
								'Unable to find Previous Kit. Number should look like YYYYMMDD-XXX',
								'Invalid Kit Number'
							);
							return 'Invalid Kit Number';
						}
						// if the kit is good, lets assign the kitNumber, call the parent, and return valid
						setKitNumber(kit.kitNumber);
						props.onKitSelected(kit.kitNumber);
						return 'VALID';
					} catch (e) {
						rsToastify.error(
							'Unable to find Previous Kit. Number should look like YYYYMMDD-XXX',
							'Invalid Kit Number'
						);
						return 'Invalid Kit Number';
					}
				}}
			/>
		</Box>
	);
};
export default KitCheckoutSection;
