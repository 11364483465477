import * as React from 'react';
import './FlightTestSection.scss';
import PayloadVerifyEsadDetonation from './flightTests/PayloadVerifyEsadDetonation';
import VehicleHeightSensorTesting from './flightTests/VehicleHeightSensorTesting';
import LoadFlightLog from './flightTests/LoadFlightLog';
import PayloadVideoQuality from './flightTests/PayloadVideoQuality';
import CameraInspection from './flightTests/CameraInspection';

import WindSpeed from './flightTests/WindSpeed';
import PayloadHighSpeedEngagements from './flightTests/PayloadHighSpeedEngagement';
import LoadVideo from './flightTests/LoadVideo';
import MinWattsProfileFlight from './flightTests/MinWattsProfileFlight';
import ReviewPlottingResults from './flightTests/ReviewPlottingResults';
import LiveVehicleHeightSensorTesting from './flightTests/LiveVehicleHeightSensorTesting';
import LiveRunPreflight from './flightTests/LiveRunPreflight';

export type LivePayloadPreFlightTestKey = 'windSpeed' | 'cameraInspection';

export type LivePayloadFlightTestKey =
	| 'runPreflight'
	| 'tenMeterLiveHeightSensorTesting'
	| 'verifyEsadDetonation'
	| 'minWattsProfileFlight'
	| 'highSpeedEngagements';

export type LivePayloadPostFlightTestKey =
	| 'loadVideo'
	| 'loadFlightLog'
	| 'reviewPlottingResults'
	| 'payloadVideoQuality';

export type LivePayloadPostFlightTestKeyNoVideo = 'loadFlightLog' | 'reviewPlottingResults' | 'payloadVideoQuality';

export type LivePayloadTestKey = LivePayloadPreFlightTestKey | LivePayloadFlightTestKey | LivePayloadPostFlightTestKey;

const livePayloadTestKeys: LivePayloadTestKey[] = [
	'windSpeed',
	'cameraInspection',
	'runPreflight',
	'tenMeterLiveHeightSensorTesting',
	'verifyEsadDetonation',
	'minWattsProfileFlight',
	'highSpeedEngagements',
	'loadVideo',
	'loadFlightLog',
	'reviewPlottingResults',
	'payloadVideoQuality'
];

type AssertAllKeys<T extends readonly string[]> = T[number] extends LivePayloadTestKey ? true : never;
const assertAllKeys: AssertAllKeys<typeof livePayloadTestKeys> = true;

export { livePayloadTestKeys };

export const livePayloadPreFlightTestData: { [key in LivePayloadPreFlightTestKey]: JSX.Element } = {
	windSpeed: <WindSpeed minMax={[0.0, 10.0]} />,
	cameraInspection: <CameraInspection />
};

export const livePayloadInFlightTestData: { [key in LivePayloadFlightTestKey]: JSX.Element } = {
	runPreflight: <LiveRunPreflight key={'livePreflight'} targetPayloadType={'INERT_FRAG_PAYLOAD_ASSEMBLY'} />,
	tenMeterLiveHeightSensorTesting: (
		<LiveVehicleHeightSensorTesting
			targetHeight={10}
			absHeightErrorMax={5}
			relativeError={[-2, 2]}
			testKey={'tenMeterLiveHeightSensorTesting'}
		/>
	),
	verifyEsadDetonation: <PayloadVerifyEsadDetonation />,

	minWattsProfileFlight: <MinWattsProfileFlight />,
	highSpeedEngagements: <PayloadHighSpeedEngagements />
};

export const livePayloadPostFlightTestData: { [key in LivePayloadPostFlightTestKey]: JSX.Element } = {
	loadVideo: <LoadVideo />,
	loadFlightLog: <LoadFlightLog />,
	reviewPlottingResults: <ReviewPlottingResults />,
	payloadVideoQuality: <PayloadVideoQuality />
};

export const livePayloadPostFlightTestDataNoVideo: { [key in LivePayloadPostFlightTestKeyNoVideo]: JSX.Element } = {
	loadFlightLog: <LoadFlightLog />,
	reviewPlottingResults: <ReviewPlottingResults />,
	payloadVideoQuality: <PayloadVideoQuality />
};

export const livePayloadFlightTestData = {
	...livePayloadPreFlightTestData,
	...livePayloadInFlightTestData,
	...livePayloadPostFlightTestData
};

export const livePayloadFlightTestDataNoVideo = {
	...livePayloadPreFlightTestData,
	...livePayloadInFlightTestData,
	...livePayloadPostFlightTestDataNoVideo
};

// Adjust the flightTestData object to include the appropriate test list based on the current HWID
