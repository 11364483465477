import * as React from 'react';
import { useState } from 'react';
import {
	Box,
	LabelInputText,
	RsFormControl,
	RsFormGroup,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import ServiceFactory from '../../../../services/serviceFactory';

interface FcuGpsValidationProps {
	satellitesMinMax: [number, number];
}

enum FormKeys {
	GPS_SNR = 'gpsSnr',
	SATELLITES = 'satellites'
}

const FcuGpsValidation: React.FC<FcuGpsValidationProps> = (props) => {
	const TEST_NAME: TestKey = 'gpsValidation';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.SATELLITES, res ? res.data[FormKeys.SATELLITES] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return testFixtureService.validateResultInRange(
						control,
						props.satellitesMinMax[0],
						props.satellitesMinMax[1]
					);
				})
			])
		])
	);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	return (
		<Box className={'rsGpsValidation'} display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={16}>
			<LabelInputText
				labelTitle={'FCU Satellites'}
				inputMode={'text'}
				type={'text'}
				control={formGroup.get(FormKeys.SATELLITES)}
				updateControl={handleUpdateControl}
				onBlur={() => {
					testFixtureService.validateFlightTest(formGroup, TEST_NAME);
				}}
			/>
		</Box>
	);
};
export default FcuGpsValidation;
