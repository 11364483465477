import * as React from 'react';
import './FirmwareList.scss';
import { Box, Label } from '@redskytech/framework/ui';
import { TesterShimStatusData } from '../../services/socketio/SocketioService';
import colors from '../../themes/colors.scss?export';
import PromptItem from '../promptItem/PromptItem';
//todo, make testerStatus type match the type of the testerStatus in the global

export interface FirmwareData {
	versions: any[];
	availableSpace: string;
	activeCommercial: string;
	activeMilitary: string;
}

export interface FirmwareSettings {
	commercialFirmware: string;
	militaryFirmware: string;
}
export interface FirmwareListProps {
	testerStatus: TesterShimStatusData['status'];
}

const FirmwareList: React.FC<FirmwareListProps> = (props) => {
	function renderHeader() {
		return (
			<Box
				display={'flex'}
				gap={16}
				className={'firmwareListHeader'}
				bgColor={colors.neutralGrey50}
				minHeight={16}
			>
				<Box className={'activeColumn'}>
					<Label color={colors.neutralGrey700} variant={'caption1'} weight={'regular'}>
						Loaded
					</Label>
				</Box>
				<Box className={'firmwareColumn'}>
					<Label color={colors.neutralGrey700} variant={'caption1'} weight={'regular'}>
						Firmware
					</Label>
				</Box>
				<Box className={'sizeColumn'}>
					<Label color={colors.neutralGrey700} variant={'caption1'} weight={'regular'}>
						Size
					</Label>
				</Box>
				<Box className={'downloadColumn'}></Box>
			</Box>
		);
	}

	if (props.testerStatus === 'DISCONNECTED') {
		return (
			<Box className={'rsFirmwareList'}>
				<PromptItem
					disabled={false}
					id={'0'}
					position={0}
					level={'error'}
					text={'Shim is not connected. Connect shim to load firmware.'}
					buttons={[]}
				/>
			</Box>
		);
	}

	return (
		<Box className={'rsFirmwareList'}>
			{renderHeader()}
			<Box maxHeight={128} overflow={'hidden auto'}>
				{props.children}
			</Box>
		</Box>
	);
};

export default FirmwareList;
