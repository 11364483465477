import * as React from 'react';
import './AssemblyAuditCheck.scss';
import { Box, Icon, IconProps, popupController, rsToastify } from '@redskytech/framework/ui';
import PromptItem from '../promptItem/PromptItem';
import { assemblyStructure } from '../../services/assembly/assembly.data';
import serviceFactory from '../../services/serviceFactory';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { useEffect, useState, useReducer } from 'react';
import { BasePartType, PartAssemblyType } from '../../services/assembly/IAssemblyService';
import ConfirmPopup, { ConfirmPopupProps } from '../../popups/confirmPopup/ConfirmPopup';
import router from '../../utils/router';
import colors from '../../themes/colors.scss?export';
import useIsMounted from '../../customHooks/useIsMounted';
import { StatusIconMap } from '../../pages/testDetailsPage/TestDetailsPage';
import AuditBanner, { AuditErrorSection } from '../auditBanner/AuditBanner';
import AuditPopup, { AuditPopupProps } from '../../popups/auditPopup/AuditPopup';
export interface AssemblyAuditCheckProps {
	partId: number;
	mode: 'NOTICE' | 'POPUP' | 'BOTH' | 'ICON' | 'ICON_SUMMARY';
	auditStats?: (value: number, errorList: string[]) => void;
}

export type AuditStatus = 'ERROR' | 'SUCCESS' | 'WARNING' | 'INFO' | 'PENDING';

export const AuditIconMap: { [key in AuditStatus]: IconProps } = {
	ERROR: { iconImg: 'icon-fail-circle', color: colors.accentErrorDark },
	SUCCESS: { iconImg: 'icon-check-circle', color: colors.accentSuccess },
	WARNING: { iconImg: 'icon-warning', color: colors.accentWarning },
	INFO: { iconImg: 'icon-info', color: colors.accentInfo },
	PENDING: { iconImg: 'icon-pending', color: colors.neutralGrey400 }
};

interface AssemblyAuditCheckState {
	type: 'CLEAR' | 'ADD';
	payload?: string[];
}

const assemblyReducer = (state: string[], action: AssemblyAuditCheckState) => {
	switch (action.type) {
		case 'CLEAR':
			return [];
		case 'ADD':
			if (action.payload) return [...state, ...action.payload];
			else return state;
		default:
			throw new Error();
	}
};

const AssemblyAuditCheck: React.FC<AssemblyAuditCheckProps> = (props) => {
	const [isHovered, setIsHovered] = useState<boolean>(false);
	const [missingSubassemblies, dispatchMissingSubassemblies] = useReducer(assemblyReducer, []);
	const [clearPopup, setClearPopup] = useState<boolean>(false);
	const [popupOpenSelected, setPopupOpenSelected] = useState<boolean>(false);
	const [assemblyComplete, setAssemblyComplete] = useState<boolean>(true);
	const assemblyService = serviceFactory.get('AssemblyService');
	const isMounted = useIsMounted();
	const [iconLevel, setIconLevel] = useState<AuditStatus>('PENDING');
	const [issueCount, setIssueCount] = useState<number>(0);
	const [auditComplete, setAuditComplete] = useState<boolean>(false);

	useEffect(() => {
		setAssemblyComplete(true);
		dispatchMissingSubassemblies({ type: 'CLEAR' });
	}, [props.partId]);

	useEffect(() => {
		if (!props.auditStats) return;
		props.auditStats(missingSubassemblies.length, missingSubassemblies);
	}, [missingSubassemblies]);

	useEffect(() => {
		// recursive function to check the assembly of a part
		if (props.partId === 0) return;
		setIssueCount(0);
		async function checkAssembly(partIdToCheck: number) {
			let res = await ApiRequestV1.getPart({ partId: partIdToCheck });
			if (!isMounted) return;

			let discoveredAssemblyType: PartAssemblyType | BasePartType | undefined;

			discoveredAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(res.partNumber);
			if (!discoveredAssemblyType) {
				discoveredAssemblyType = assemblyService.getBasePartTypeFromPartNumber(res.partNumber);
				if (discoveredAssemblyType) {
					// This is a base component, and has no children, so just return
					return;
				} else {
					rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
					return;
				}
			}

			const assemblyComponents = assemblyStructure[discoveredAssemblyType];

			if (!assemblyComponents) {
				rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
				return;
			}

			// This shouldn't happen unless we have an assembly with no children it is not a base part.
			if (assemblyComponents.length === 0) {
				return;
			}
			// make a dictionary with a key for every item in required subassemblies, all set to false
			let subassemblyChecklist = assemblyComponents.reduce((obj: any, item: PartAssemblyType | BasePartType) => {
				if (item in obj) {
					obj[item]['count']++;
				} else obj[item] = { auditComplete: 'false', count: 1 };
				return obj;
			}, {});

			const partChildren = res.children;
			// At this point there should have been children since we had required subassemblies (so we were missing a child.)
			if (!partChildren) {
				setIssueCount((prev) => prev + subassemblyChecklist.length);
				setAssemblyComplete(false);
				return;
			}
			// for each child, we query it's part number, and if it's in the required subassemblies, we set it to true
			for (const child of partChildren) {
				let childPartAssemblyType: PartAssemblyType | BasePartType | undefined;
				childPartAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(child.childPartNumber);
				if (!childPartAssemblyType) {
					childPartAssemblyType = assemblyService.getBasePartTypeFromPartNumber(child.childPartNumber);
					if (!childPartAssemblyType) {
						rsToastify.error(
							`Unable to recognize child subassembly type. ${child.childPartNumber}`,
							'Unknown Assembly Type'
						);
					}
				}

				if (childPartAssemblyType && subassemblyChecklist.hasOwnProperty(childPartAssemblyType)) {
					subassemblyChecklist[childPartAssemblyType]['count']--;
					if (subassemblyChecklist[childPartAssemblyType]['count'] === 0) {
						subassemblyChecklist[childPartAssemblyType]['auditComplete'] = 'true';
					}
					if (subassemblyChecklist[childPartAssemblyType]['count'] < 0) {
						subassemblyChecklist[childPartAssemblyType]['auditComplete'] = 'false';
						rsToastify.error(
							`Too Many children of type found. ${childPartAssemblyType}`,
							'Part Count Mismatch'
						);
					}
				}

				// special check to deal with main board assemblies
				if (
					childPartAssemblyType &&
					childPartAssemblyType.includes('MAIN_BOARD') &&
					subassemblyChecklist.hasOwnProperty('MAIN_BOARD')
				) {
					subassemblyChecklist['MAIN_BOARD']['count']--;
					if (subassemblyChecklist['MAIN_BOARD']['count'] === 0) {
						subassemblyChecklist['MAIN_BOARD']['auditComplete'] = 'true';
					}
					if (subassemblyChecklist['MAIN_BOARD']['count'] < 0) {
						subassemblyChecklist['MAIN_BOARD']['auditComplete'] = 'false';
						rsToastify.error(`Too Many children of type found. MAIN_BOARD`, 'Part Count Mismatch');
					}
				}

				// if the parent is a main board assembly, and the child is a main board assembly, we don't want to check it
				if (
					discoveredAssemblyType === 'MAIN_BOARD_ASSEMBLY' &&
					childPartAssemblyType &&
					childPartAssemblyType.includes('MAIN_BOARD')
				)
					return;

				await checkAssembly(child.childId).catch((err) => {
					rsToastify.error('Error checking Sub assembly for part audit', 'Error');
					console.error(err);
				});
			}

			if (!isMounted) return;

			let anyMissingAssemblies = false;
			let missingLabels: string[] = [];

			for (const key in subassemblyChecklist) {
				if (subassemblyChecklist[key]['auditComplete'] === 'false') {
					// append all missing subassemblies to the missingSubassemblies array
					if (subassemblyChecklist[key]['count'] > 0) {
						for (let i = 0; i < subassemblyChecklist[key]['count']; i++) {
							missingLabels.push(assemblyService.getLabelFromPartType(key));
						}
						anyMissingAssemblies = true;
					} else {
						missingLabels.push(assemblyService.getLabelFromPartType(key));
						anyMissingAssemblies = true;
					}
				}
			}
			if (anyMissingAssemblies) {
				const partLabel = `${assemblyService.getLabelFromPartNumber(res.partNumber)}:`;
				dispatchMissingSubassemblies({ type: 'ADD', payload: [partLabel] });
			}

			if (anyMissingAssemblies) {
				dispatchMissingSubassemblies({ type: 'ADD', payload: missingLabels });
				setIssueCount((prev) => prev + missingLabels.length);
				setAssemblyComplete(false);
				setIconLevel('WARNING');
			}
		}

		checkAssembly(props.partId)
			.catch((err) => {
				rsToastify.error('Error checking assembly', 'Error');
				console.error(err);
			})
			.then(() => {
				setAuditComplete(true);
			});
	}, [isMounted, props.partId]);

	useEffect(() => {
		if (!auditComplete || clearPopup || assemblyComplete) return;
		if (['NOTICE', 'ICON', 'ICON_SUMMARY'].includes(props.mode)) return;
		let popupId = popupController.open<AuditPopupProps>(AuditPopup, {
			title: 'Assembly Incomplete',
			descriptor: 'Missing',
			auditErrors: generateAuditList(missingSubassemblies),
			message: `Do you still want to test this assembly?`,
			confirmButtonText: 'Continue',
			closeButtonText: 'Cancel',
			onConfirm: () => {
				setClearPopup(true);
			},
			onCancel: () => {
				setClearPopup(true);
				window.open('/assembly/details?pi=' + props.partId, '_blank');
			}
		});
		return () => {
			if (popupId) popupController.closeById(popupId);
		};
	}, [clearPopup, assemblyComplete, props.mode, missingSubassemblies, auditComplete]);

	useEffect(() => {
		if (!auditComplete) return;
		if (assemblyComplete) if (issueCount === 0) setIconLevel('SUCCESS');
	}, [issueCount, assemblyComplete, auditComplete]);

	useEffect(() => {
		if (!auditComplete || !popupOpenSelected) return;
		if (['NOTICE', 'POPUP'].includes(props.mode)) return;
		let popupId = popupController.open<AuditPopupProps>(AuditPopup, {
			title: 'Assembly Incomplete',
			auditErrors: generateAuditList(missingSubassemblies),
			message: `Select View Details to go to the Assembly Page.`,
			confirmButtonText: 'View Details',
			closeButtonText: 'Cancel',
			onConfirm: () => {
				setPopupOpenSelected(false);
				window.open('/assembly/details?pi=' + props.partId, '_blank');
			},
			onCancel: () => {
				setPopupOpenSelected(false);
			}
		});
		return () => {
			if (popupId) popupController.closeById(popupId);
		};
	}, [popupOpenSelected, assemblyComplete, props.mode, missingSubassemblies, auditComplete]);

	function generateAuditList(testStringList: string[]) {
		// format the tests to be human readable
		if (testStringList.length === 0) return [];
		if (testStringList.length === 1) return [{ group: testStringList[0], errors: [] }];
		let outputObjects: AuditErrorSection[] = [];
		let newoutputObject: AuditErrorSection = { group: '', errors: [] };
		testStringList.forEach((test, index) => {
			if (test.endsWith(':')) {
				// indicates a new group, if the old group name was anything other than '' then lets push it
				if (newoutputObject.group !== '') {
					outputObjects.push(newoutputObject);
					newoutputObject = { group: '', errors: [] };
				}
				newoutputObject.group = test.replace(':', '');
			} else {
				newoutputObject.errors.push(test);
			}
		});
		if (newoutputObject.group !== '') {
			outputObjects.push(newoutputObject);
			newoutputObject = { group: '', errors: [] };
		}
		// the nature of the search puts the leaves at the top of the list
		// Reverse them, so the biggest items appear first
		return outputObjects.reverse();
	}

	if (props.mode === 'NOTICE' || props.mode === 'BOTH') {
		return (
			<Box className={'rsAssemblyAuditCheck'}>
				{!assemblyComplete && (
					<AuditBanner
						level={'warn'}
						buttons={[]}
						disableAudio={true}
						disabled={false}
						id={'auditCheck'}
						position={0}
						text={`Missing Children:`}
						auditErrors={generateAuditList(missingSubassemblies)}
					/>
				)}
			</Box>
		);
	} else if (props.mode === 'POPUP' && !assemblyComplete && !clearPopup) {
		return <Box className={'rsAssemblyAuditCheck'} />;
	} else if (props.mode === 'ICON') {
		return (
			<Box className={'rsAssemblyAuditCheck'}>
				<Icon {...AuditIconMap[iconLevel]} />
			</Box>
		);
	}
	if (props.mode === 'ICON_SUMMARY') {
		return (
			<Box
				className={'rsAssemblyAuditCheck'}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => {
					setIsHovered(false);
				}}
				onClick={() => {
					if (issueCount > 0) {
						setPopupOpenSelected(true);
					}
				}}
			>
				<Icon cursorPointer={issueCount > 0} {...AuditIconMap[iconLevel]} />
				{issueCount ? `${issueCount}` : ''}
				{isHovered && issueCount > 0 && (
					<Box className={'toolTip'}>
						<Box className={'container'}>
							<AuditBanner
								level={'warn'}
								buttons={[]}
								disableAudio={true}
								disabled={false}
								id={'auditCheck'}
								position={0}
								text={`Missing Children:`}
								auditErrors={generateAuditList(missingSubassemblies)}
							/>
						</Box>
					</Box>
				)}
			</Box>
		);
	} else return <Box className={'rsAssemblyAuditCheck'} />;
};

export default AssemblyAuditCheck;
