import { Box, Img, Label } from '@redskytech/framework/ui';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import logo from '../../../public/pdfStatic/logo_black_wordmark.png';
import { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import { KitGroupType } from '../../services/kit/IKitService';
import globalState from '../../state/globalState';
import './KitCheckoutPdf.scss';
import KitCheckoutTable from './kitCheckoutTable/KitCheckoutTable';

export interface KitCheckoutPdfProps {
	serialNumber: string;
	salesOrder: string;
	kitCheckoutGroup?: { group: KitGroupType; idList: HardwareIdDecoded[] }[];
	kitCheckoutPdfRef: React.RefObject<HTMLDivElement>;
}

const KitCheckoutPdf: React.FC<KitCheckoutPdfProps> = (props) => {
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);

	function renderKitCheckoutGroups() {
		return props.kitCheckoutGroup?.map((group, index) => {
			return <KitCheckoutTable kitGroup={group} />;
		});
	}

	return (
		<Box className="rsKitCheckoutPdf" elementRef={props.kitCheckoutPdfRef}>
			<Box className="pdfHeader">
				<Box borderRight={'1px solid var(--Neutral-Grey-400, #CED4DA)'}>
					<Box p="0 8px 8px 0" borderBottom={'1px solid var(--Neutral-Grey-400, #CED4DA)'}>
						<Label variant={'h4'} weight={'semiBold'}>
							Final Kit Verification
						</Label>
					</Box>
					<Box display="grid" gridTemplateColumns="1fr 1fr" p={'8px 0'}>
						<Box>
							<Label variant="caption2" weight="regular">
								Serial Number
							</Label>
							<Label variant="caption1" weight="bold">
								{props.serialNumber}
							</Label>
						</Box>
						<Box>
							<Label variant="caption2" weight="regular">
								Sales Order
							</Label>
							<Label variant="caption1" weight="bold">
								{props.salesOrder}
							</Label>
						</Box>
						<Box>
							<Label variant="caption2" weight="regular">
								Checked By
							</Label>
							<Label variant="caption1" weight="bold">
								{user?.firstName} {user?.lastName}
							</Label>
						</Box>
						<Box>
							<Label variant="caption2" weight="regular">
								Date
							</Label>
							<Label variant="caption1" weight="bold">
								{new Date().toDateString()}
							</Label>
						</Box>
					</Box>
				</Box>
				<Box pb={8}>
					<Img
						src={'/pdfStatic/logo_black_wordmark.png'}
						alt="FLIR Black Logo"
						width="136px"
						height="31px"
						mb={8}
					/>
					<Box>
						<Label variant="caption2" weight="regular">
							100 Midland Road
						</Label>
						<Label variant="caption2" weight="regular">
							Oak Ridge, TN 37830
						</Label>
						<Label variant="caption2" weight="regular">
							T +1.865.220.8700x101
						</Label>
						<Label variant="caption2" weight="regular">
							F +1.865.220.7181
						</Label>
						<Label variant="caption2" weight="regular">
							email@teledyneflir.com
						</Label>
						<Label variant="caption2" weight="regular">
							[URL]
						</Label>
					</Box>
				</Box>
			</Box>
			{renderKitCheckoutGroups()}
		</Box>
	);
};

export default KitCheckoutPdf;
