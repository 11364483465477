import * as React from 'react';
import './WebVersionStatus.scss';
import { Box, Button, Icon, Label, rsToastify } from '@redskytech/framework/ui';
import { useEffect, useState } from 'react';
import colors from '../../themes/colors.scss?export';
import { ApiRequestV1 } from '../../generated/apiRequests';
import useIsMounted from '../../customHooks/useIsMounted';
export interface WebVersionStatusProps {}

const WebVersionStatus: React.FC<WebVersionStatusProps> = (_props) => {
	const [showOutOfDateBanner, setShowOutOfDateBanner] = useState<boolean>(false);
	const [hasOutOfDateBannerBeenDismissed, setHasOutOfDateBannerBeenDismissed] = useState<boolean>(false);
	const isMounted = useIsMounted();

	useEffect(() => {
		if (hasOutOfDateBannerBeenDismissed) return;
		if (showOutOfDateBanner) return;

		async function getAppDetails() {
			try {
				const versionDetails = await ApiRequestV1.getWebVersion().catch((e) => {
					rsToastify.error(
						`Failed to get latest Web App version from the cloud. ${e}`,
						'Error Getting Latest Version'
					);
				});
				// Break if the component has been unmounted before versionDetails is fetched.
				if (!isMounted) {
					return;
				}
				const currentVersionDetails = import.meta.env.PACKAGE_VERSION;

				const currentAppVersionArray = currentVersionDetails.split('.').map((num) => parseInt(num));
				const latestAppVersionArray = versionDetails?.version.split('.').map((num) => parseInt(num));
				if (currentAppVersionArray.length !== latestAppVersionArray?.length) {
					rsToastify.error(
						'App version syncing failed due to incompatibility',
						'App Version Sync Check Failure'
					);
					return;
				}
				// Go through the numbers, the first time one is bigger we can stop, if we get to one that is smaller, we show the banner
				for (let i = 0; i < currentAppVersionArray.length; i++) {
					if (currentAppVersionArray[i] > latestAppVersionArray[i]) {
						return;
					} else if (currentAppVersionArray[i] < latestAppVersionArray[i]) {
						setShowOutOfDateBanner(true);
					}
				}
			} catch (e) {
				rsToastify.error(
					`Failed to get latest Web App version from the cloud. ${e}`,
					'Error Getting Latest Version'
				);
			}
		}
		getAppDetails().catch(console.error);

		let intervalId: NodeJS.Timeout | undefined = undefined;
		const delayId = setTimeout(() => {
			intervalId = setInterval(() => {
				getAppDetails().catch(console.error);
			}, 60 * 1000);
		}, 10 * 1000);
		return () => {
			if (delayId) {
				clearTimeout(delayId);
			}
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [showOutOfDateBanner, hasOutOfDateBannerBeenDismissed, isMounted]);

	useEffect(() => {
		if (hasOutOfDateBannerBeenDismissed) {
			// it has been dismissed, so after 5 minutes we reset it. allowing the banner to be set again.
			const timerId = setTimeout(() => {
				if (isMounted) {
					setHasOutOfDateBannerBeenDismissed(false);
				}
			}, 5 * 60 * 1000);
			return () => {
				if (timerId) {
					clearTimeout(timerId);
				}
			};
		}
	}, [hasOutOfDateBannerBeenDismissed, isMounted]);

	function renderOutOfDateBanner() {
		return (
			<Box className={'outOfDateBanner'}>
				<Icon
					className={'closeBtn'}
					iconImg={'icon-close'}
					fontSize={16}
					onClick={() => {
						setHasOutOfDateBannerBeenDismissed(true);
						setShowOutOfDateBanner(false);
					}}
				/>
				<Icon iconImg={'icon-warning'} fontSize={64} color={colors.accentWarning} />
				<Label variant={'h1'} weight={'semiBold'} color={colors.accentWarningDark}>
					WARNING - Version Out of Date
				</Label>
				<Label variant={'subheader1'} weight={'regular'} textAlign={'center'} color={colors.accentWarningDark}>
					Refresh to get the latest updates. <br />
				</Label>
				<Button look={'outlinedPrimary'} onClick={() => window.location.reload()}>
					Refresh
				</Button>
			</Box>
		);
	}

	return <Box className={'rsVersionStatus'}>{showOutOfDateBanner && renderOutOfDateBanner()}</Box>;
};

export default WebVersionStatus;
