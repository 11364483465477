import * as React from 'react';
import './AssemblyInformationPaper.scss';
import { Box, Button, rsToastify } from '@redskytech/framework/ui';
import { DateUtils, StringUtils } from '../../../utils/utils';
import PaperHeaderBar from '../../../components/paperHeaderBar/PaperHeaderBar';
import router from '../../../utils/router';
import InfoItem from './InfoItem/InfoItem';
import serviceFactory from '../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';

interface AssemblyInformationPaperProps {
	assemblyDetails: Api.V1.Part.Get.Res;
	kitNumber: Api.V1.Kit.Get.Res['kitNumber'];
}

const AssemblyInformationPaper: React.FC<AssemblyInformationPaperProps> = (props) => {
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);
	const hasParent = !!props.assemblyDetails.parent.length;
	const hasChildren = !!props.assemblyDetails.children.length;

	function handleParentClick() {
		let parentId = props.assemblyDetails.parent[0].parentId;
		router.navigate('/assembly/details?pi=' + parentId).catch(console.error);
	}

	function getPaperTitle() {
		if (hasParent && hasChildren) {
			return 'Subassembly';
		}
		if (hasParent && !hasChildren) {
			return 'Component';
		}
		return 'Assembly';
	}

	function handleCopyHwidToClipboard() {
		rsToastify.success('Copied HWID to clipboard', 'Copied');
		const assemblyService = serviceFactory.get('AssemblyService');
		StringUtils.copyToClipboard(
			assemblyService.generateHardwareId(
				props.assemblyDetails.partNumber,
				props.assemblyDetails.serialNumber,
				props.assemblyDetails.hardwareRev
			)
		);
	}

	function renderCopyHwid(): React.ReactNode {
		return (
			<Button look={'outlinedPrimary'} onClick={handleCopyHwidToClipboard}>
				Copy HWID
			</Button>
		);
	}

	return (
		<PaperHeaderBar
			title={`${getPaperTitle()} Information`}
			className={'rsAssemblyInformationPaper'}
			rightNode={renderCopyHwid()}
		>
			<Box display={'grid'} gridTemplateColumns={'repeat(3, 1fr)'} gap={24}>
				<InfoItem title={'Name'} info={props.assemblyDetails.name} />
				<InfoItem title={'Serial #'} info={props.assemblyDetails.serialNumber} />
				<InfoItem title={'Part #'} info={props.assemblyDetails.partNumber} />
				<InfoItem title={'Created On'} info={DateUtils.displayDate(props.assemblyDetails.createdOn)} />
				<InfoItem
					title={'Created By'}
					info={`${props.assemblyDetails.createdFirstName} ${props.assemblyDetails.createdLastName}`}
				/>
				<InfoItem title={'Hardware Rev'} info={props.assemblyDetails.hardwareRev} />
				{!!props.kitNumber && (
					<InfoItem
						title={'Kit #'}
						info={props.kitNumber}
						onClick={() => {
							router.navigate(`/kit/checkout?kn=${props.kitNumber}`).catch(console.error);
						}}
					/>
				)}

				{hasParent && user?.role !== 'ngTechnician' && (
					<>
						<InfoItem
							title={'Parent Name'}
							info={props.assemblyDetails.parent[0].partName}
							onClick={handleParentClick}
						/>
						<InfoItem
							title={'Parent Serial #'}
							info={props.assemblyDetails.parent[0].partSerialNumber}
							onClick={handleParentClick}
						/>
						<InfoItem
							title={'Parent Part #'}
							info={props.assemblyDetails.parent[0].partPartNumber}
							onClick={handleParentClick}
						/>
					</>
				)}
			</Box>
		</PaperHeaderBar>
	);
};
export default AssemblyInformationPaper;
