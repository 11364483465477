import { useEffect, useState } from 'react';

export default function useIsMounted() {
	const [isMounted, setIsMounted] = useState<boolean>(true);
	useEffect(() => {
		setIsMounted(true);
		return () => setIsMounted(false);
	}, []);

	return isMounted;
}
