import * as React from 'react';
import { useEffect, useState } from 'react';
import './UserDetailsPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../components/pageHeader/PageHeader';
import { Button, RsFormControl, RsFormGroup, rsToastify, RsValidator, RsValidatorEnum } from '@redskytech/framework/ui';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import PaperHeaderBar from '../../components/paperHeaderBar/PaperHeaderBar';
import router from '../../utils/router';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { WebUtils } from '../../utils/utils';
import UserDetailsForm, { UserDetailsFormKeys } from '../../components/userDetailsForm/UserDetailsForm';

interface UserDetailsPageProps {}

const UserDetailsPage: React.FC<UserDetailsPageProps> = (props) => {
	const { userId } = router.getQueryParams<{ userId: number }>([
		{
			key: 'ui',
			default: 0,
			type: 'integer',
			alias: 'userId'
		}
	]);

	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(UserDetailsFormKeys.FIRST_NAME, '', [
				new RsValidator(RsValidatorEnum.REQ, 'First name is required.')
			]),
			new RsFormControl<string>(UserDetailsFormKeys.LAST_NAME, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Last name is required.')
			]),
			new RsFormControl<string>(UserDetailsFormKeys.EMAIL, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Email is required.'),
				new RsValidator(RsValidatorEnum.EMAIL, 'Email is invalid.')
			]),
			new RsFormControl<string>(UserDetailsFormKeys.ROLE, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Role is required.')
			]),
			new RsFormControl<string>(UserDetailsFormKeys.PASSWORD, '')
		])
	);

	useEffect(() => {
		if (!userId) return;
		async function getUserDetails() {
			try {
				let res = await ApiRequestV1.getUser({ id: userId });
				let updatedForm = formGroup.clone();
				updatedForm.get(UserDetailsFormKeys.FIRST_NAME).value = res.firstName;
				updatedForm.get(UserDetailsFormKeys.LAST_NAME).value = res.lastName;
				updatedForm.get(UserDetailsFormKeys.EMAIL).value = res.email;
				updatedForm.get(UserDetailsFormKeys.ROLE).value = res.role;
				setFormGroup(updatedForm.updateInitialValues());
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			}
		}
		getUserDetails().catch(console.error);
	}, [userId]);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	async function handleSave() {
		if (!(await formGroup.isValid())) {
			setFormGroup(formGroup.clone());
			rsToastify.error('Please fix the errors in the inputs.', 'Invalid Inputs');
			return;
		}
		let updatedValues = formGroup.toChangedModel<Omit<Api.V1.User.Patch.Req, 'id'>>();
		if (updatedValues.password) {
			updatedValues.password = await WebUtils.sha256Encode(updatedValues.password);
		}
		try {
			await ApiRequestV1.patchUser({ id: userId, ...updatedValues });
			let updatedForm = formGroup.clone();
			updatedForm.get(UserDetailsFormKeys.PASSWORD).value = '';
			setFormGroup(updatedForm.updateInitialValues());

			rsToastify.success('User updated successfully.');
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	return (
		<Page className={'rsUserDetailsPage'}>
			<PageHeader
				onBack={() => {
					router.navigate('/user/list').catch(console.error);
				}}
				title={'User Details'}
				isModified={formGroup.isModified()}
				rightNode={
					formGroup.isModified() && (
						<Button look={'containedPrimary'} onClick={handleSave}>
							Save Changes
						</Button>
					)
				}
			/>
			<PaperHeaderBar title={'Personal Information'}>
				<UserDetailsForm formGroup={formGroup} onUpdateControl={handleUpdateControl} />
			</PaperHeaderBar>
		</Page>
	);
};
export default UserDetailsPage;
