import * as React from 'react';
import './CarrierStatus.scss';
import { Box, Button, Icon, Label } from '@redskytech/framework/ui';
import { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import { CarrierType } from '../../services/calibration/ICalibrationService';
import colors from '../../themes/colors.scss?export';

export type SlotDetails = { slotNumber: number; status: 'GOOD' | 'WARNING' | 'FAILED' };

export interface CarrierStatusProps {
	type: CarrierType;
	hwid?: HardwareIdDecoded;
	onConfigure?: () => void;
	slots: SlotDetails[];
}

const CarrierStatus: React.FC<CarrierStatusProps> = (props) => {
	function getStatusLabelText(): string {
		if (props.type === 'IMU') {
			return 'IMU Carrier';
		} else if (props.type === 'SIB') {
			return 'SIB Carrier';
		} else {
			return 'MAG BARO Carrier';
		}
	}

	function getStatusIcon(status: SlotDetails['status']): React.ReactNode {
		if (status === 'GOOD') {
			return <Icon iconImg={'icon-check'} color={colors.accentSuccess} />;
		} else if (status === 'WARNING') {
			return <Icon iconImg={'icon-tools'} color={colors.accentWarning} />;
		} else {
			return <Icon iconImg={'icon-fail'} color={colors.accentError} />;
		}
	}

	function renderSlots(): React.ReactNode {
		let slots: React.ReactNode[] = [];
		for (let i = 1; i <= 16; i++) {
			let foundSlotData = props.slots.find((slot) => slot.slotNumber === i);
			if (foundSlotData) {
				let slotStatus = foundSlotData.status;
				slots.push(
					<Box
						key={i}
						className={`slot ${
							slotStatus === 'GOOD' ? 'good' : slotStatus === 'WARNING' ? 'warning' : 'failed'
						}`}
					>
						<Label variant={'body1'} weight={'bold'}>
							{i}
						</Label>
						{getStatusIcon(slotStatus)}
					</Box>
				);
			} else {
				slots.push(
					<Box key={i} className={'slot empty'}>
						<Label variant={'body1'} weight={'regular'}>
							{i}
						</Label>
					</Box>
				);
			}
		}
		return slots;
	}

	return (
		<Box className={'rsCarrierStatus'}>
			<Box display={'flex'} justifyContent={'space-between'} mb={24}>
				<Box>
					<Label variant={'body1'} weight={'semiBold'}>
						{getStatusLabelText()}
					</Label>
					{props.hwid && (
						<Label variant={'caption1'} weight={'regular'}>
							SN: {props.hwid.serialNumber}, PN: {props.hwid.partNumber}, HW:{props.hwid.hardwareRevision}
						</Label>
					)}
				</Box>
				{props.onConfigure && (
					<Button look={'outlinedPrimary'} className={'settingsButton'} onClick={props.onConfigure}>
						<Icon fontSize={24} iconImg={'icon-settings'} />
					</Button>
				)}
			</Box>
			<Box className={'slotGrid'}>{renderSlots()}</Box>
		</Box>
	);
};

export default CarrierStatus;
