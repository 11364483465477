import { Service } from '../Service';
import { ApiRequestV1 } from '../../generated/apiRequests';

export interface ShimVersionDetails {
	id: number;
	createdOn: string;
	modifiedOn: string;
	version: string;
	url: string;
}

export default class ShimService extends Service {
	static async getLatestVersion(): Promise<ShimVersionDetails> {
		return ApiRequestV1.getShimVersion();
	}
}
