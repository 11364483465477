import * as React from 'react';
import './InfoImagePopup.scss';
import { Box, Img, Popup, popupController, PopupProps } from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';

export interface InfoImagePopupProps extends PopupProps {
	imageUrl: string;
	partName: string;
}

const InfoImagePopup: React.FC<InfoImagePopupProps> = (props) => {
	return (
		<Popup {...props} preventCloseByBackgroundClick className={'rsInfoImagePopupContainer'}>
			<Box className={'rsInfoImagePopup'}>
				<PopupHeader
					title={props.partName}
					onClose={() => {
						popupController.close(InfoImagePopup);
					}}
				/>
				<Img src={props.imageUrl} alt={'Sample image'} width={500} height={'auto'} />
			</Box>
		</Popup>
	);
};

export default InfoImagePopup;
