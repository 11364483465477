import * as React from 'react';
import { Box } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useState } from 'react';
import EvaluationItem, {
	EvaluationGroup,
	EvaluationOptions
} from '../../../../components/evaluationItem/EvaluationItem';
import ServiceFactory from '../../../../services/serviceFactory';

interface PayloadVerifyEsadDetonationProps {}

type PayloadVerifyEsadDetonationEvaluationGroup = EvaluationGroup & {
	disarmEsad1ArmToDetonate: EvaluationOptions | undefined;
	disarmVsi1ArmToRed: EvaluationOptions | undefined;
	disarmEsad2ArmToDetonate: EvaluationOptions | undefined;
	disarmVsi2ArmToRed: EvaluationOptions | undefined;
	disarmEsad3ArmToDetonate: EvaluationOptions | undefined;
	disarmVsi3ArmToRed: EvaluationOptions | undefined;
};

type EvaluationKey = keyof PayloadVerifyEsadDetonationEvaluationGroup;

const PayloadVerifyEsadDetonation: React.FC<PayloadVerifyEsadDetonationProps> = () => {
	const TEST_NAME: TestKey = 'verifyEsadDetonation';
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const [evaluations, setEvaluations] = useState<PayloadVerifyEsadDetonationEvaluationGroup>({
		disarmEsad1ArmToDetonate: res ? res.data['disarmEsad1ArmToDetonate'] : undefined,
		disarmVsi1ArmToRed: res ? res.data['disarmVsi1ArmToRed'] : undefined,
		disarmEsad2ArmToDetonate: res ? res.data['disarmEsad2ArmToDetonate'] : undefined,
		disarmVsi2ArmToRed: res ? res.data['disarmVsi2ArmToRed'] : undefined,
		disarmEsad3ArmToDetonate: res ? res.data['disarmEsad3ArmToDetonate'] : undefined,
		disarmVsi3ArmToRed: res ? res.data['disarmVsi3ArmToRed'] : undefined
	});

	useEffect(() => {
		testFixtureService.validateEvaluationTest(TEST_NAME, evaluations);
	}, [evaluations]);

	function handleEvaluationClick(evaluation: EvaluationOptions, key: EvaluationKey) {
		setEvaluations((prevState) => {
			let updatedState = { ...prevState };
			updatedState[key] = evaluation;
			return updatedState;
		});
	}
	return (
		<Box className={'rsVerifyEsadReady'} display={'flex'} flexDirection={'column'} gap={16}>
			<EvaluationItem
				label={'Disarm #1: ESAD transitions from "Arm" to "Detonate"'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmEsad1ArmToDetonate');
				}}
				evaluation={evaluations.disarmEsad1ArmToDetonate}
			/>
			<EvaluationItem
				label={'Disarm #1: VSI shows green in "Ready" and red in "Arm"'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmVsi1ArmToRed');
				}}
				evaluation={evaluations.disarmVsi1ArmToRed}
			/>
			<EvaluationItem
				label={'Disarm #2: ESAD transitions from "Arm" to "Detonate"'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmEsad2ArmToDetonate');
				}}
				evaluation={evaluations.disarmEsad2ArmToDetonate}
			/>
			<EvaluationItem
				label={'Disarm #2: VSI shows green in "Ready" and red in "Arm"'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmVsi2ArmToRed');
				}}
				evaluation={evaluations.disarmVsi2ArmToRed}
			/>{' '}
			<EvaluationItem
				label={'Disarm #3: ESAD transitions from "Arm" to "Detonate"'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmEsad3ArmToDetonate');
				}}
				evaluation={evaluations.disarmEsad3ArmToDetonate}
			/>
			<EvaluationItem
				label={'Disarm #3: VSI shows green in "Ready" and red in "Arm"'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmVsi3ArmToRed');
				}}
				evaluation={evaluations.disarmVsi3ArmToRed}
			/>
		</Box>
	);
};
export default PayloadVerifyEsadDetonation;
