import * as React from 'react';
import './TestRunnerSection.scss';
import { Box, Button, Label, popupController, rsToastify } from '@redskytech/framework/ui';
import { Fixture, TestPrompt, TestResult } from '../../../services/testFixture/ITestFixtureService';
import TestList, { RunType } from '../../../components/testList/TestList';
import { ObjectUtils, StringUtils, WebUtils } from '../../../utils/utils';
import PromptItem from '../../../components/promptItem/PromptItem';
import ConfirmPopup, { ConfirmPopupProps } from '../../../popups/confirmPopup/ConfirmPopup';
import { RunOption } from '../../../components/testList/testItem/TestItem';
import { useEffect, useRef, useState } from 'react';
import serviceFactory from '../../../services/serviceFactory';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import PageHeader from '../../../components/pageHeader/PageHeader';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import ResultsList from '../../../components/resultsList/ResultsList';
import RtspVideoPlayer from '../../../components/rtspVideoPlayer/RtspVideoPlayer';
import { HardwareIdDecoded } from '../../../services/assembly/AssemblyService';
import {
	baseParts,
	commercialFirmwareAssemblyPartTypes,
	partAssemblies
} from '../../../services/assembly/assembly.data';
import { TestSettings } from '../../settingsPage/SettingsPage';
import { FirmwareSettings } from '../../../components/firmwareList/FirmwareList';
import UpdateSettingsPopup, { UpdateSettingsPopupProps } from '../../../popups/updateSettingsPopup/UpdateSettingsPopup';
import router from '../../../utils/router';
import { PortEntryData } from '../../../services/socketio/SocketioService';
import DeviceIdlePopup, { DeviceIdlePopupProps } from '../../../popups/deviceIdlePopup/DeviceIdlePopup';
import AssemblyAuditCheck from '../../../components/assemblyAuditCheck/AssemblyAuditCheck';

interface TestRunnerSectionProps {
	testFixture: Fixture;
	assemblyHwid: HardwareIdDecoded;
	parentAssemblyHwid?: HardwareIdDecoded;
	onDone: () => void;
}

const NON_TEST_RUNNING_TIMEOUT_MS = 20 * 60 * 1000;

const TestRunnerSection: React.FC<TestRunnerSectionProps> = (props) => {
	const testerShimStatus = useRecoilValue(globalState.testerShimStatus);
	const socketioService = serviceFactory.get('SocketioService');
	const assemblyService = serviceFactory.get('AssemblyService');
	const [assemblyDetails, setAssemblyDetails] = useState<any>();
	const [currentRunningIndex, setCurrentRunningIndex] = useState<number | undefined>();
	const [isModified, setIsModified] = useState<boolean>(false);
	const [testResults, setTestResults] = useRecoilState<TestResult[]>(globalState.testResults);
	const [isPaused, setIsPaused] = useState<boolean>(false);
	const [currentSelectedIndex, setCurrentSelectedIndex] = useState<number>(0);
	const [testPrompts, setTestPrompts] = useRecoilState<TestPrompt[]>(globalState.testPrompts);
	const firmwareSettings = useRecoilValue<FirmwareSettings>(globalState.firmwareSettings);
	const [runType, setRunType] = useState<RunType>();
	const pauseAtIndex = useRef<number | undefined>();
	const startAtIndex = useRef<number>(0);
	const [connectionGuid] = useState<string>(() => StringUtils.generateGuid());
	const [rtspVideoUrl, setRtspVideoUrl] = useRecoilState<{ url: string; xRes: number; yRes: number } | undefined>(
		globalState.rtspVideoUrl
	);
	const [currentPlayingUrl, setCurrentPlayingUrl] = useState<string>('');
	const [isPlayingVideo, setIsPlayingVideo] = useState<boolean>(false);
	const [terminatedTestCode, setTerminatedTestCode] = useRecoilState<1 | 2 | undefined>(
		globalState.terminatedTestCode
	);
	const [connectionStatus, setConnectionStatus] = useState<'CONNECTED' | 'CONNECTING' | 'DISCONNECTED'>(
		'DISCONNECTED'
	);
	const testSettings = useRecoilValue<TestSettings>(globalState.testSettings);
	const [isUpdateSettingsPopupVisible, setIsUpdateSettingsPopupVisible] = useState<boolean>(false);

	useEffect(() => {
		async function getAssemblyPartNumber() {
			let assemblyPart = await ApiRequestV1.getPartByNumbers({
				partNumber: props.assemblyHwid.partNumber,
				serialNumber: props.assemblyHwid.serialNumber
			});
			setAssemblyDetails(assemblyPart);
		}
		getAssemblyPartNumber();
	}, []);

	useEffect(() => {
		if (isUpdateSettingsPopupVisible) return;

		let shouldShowPopup = false;
		// Check for any empty settings.
		if (Object.values(testSettings).includes('')) shouldShowPopup = true;
		if (Object.values(firmwareSettings).includes('')) shouldShowPopup = true;

		if (shouldShowPopup) {
			popupController.open<UpdateSettingsPopupProps>(UpdateSettingsPopup, {});
			setIsUpdateSettingsPopupVisible(true);
		}
	}, [testSettings, firmwareSettings, isUpdateSettingsPopupVisible]);

	useEffect(() => {
		if (!isModified || connectionStatus === 'CONNECTED') return;
		let connectedResult = testResults.find((result) => result.testName === 'applyPower');
		setConnectionStatus(connectedResult?.passed ? 'CONNECTED' : 'DISCONNECTED');
	}, [testResults]);

	// Check for beforeunload event
	useEffect(() => {
		if (!isModified) return;
		function onBeforeUnload(e: BeforeUnloadEvent) {
			e.preventDefault();
			e.returnValue = 'Are you sure you want to leave without saving your changes?';
		}

		window.addEventListener('beforeunload', onBeforeUnload, { capture: true });
		return () => {
			window.removeEventListener('beforeunload', onBeforeUnload, { capture: true });
		};
	}, [isModified]);

	// Check for leaving page with unsaved changes, this used to be a hook, but we need to also send the shim an exit code if connected
	useEffect(() => {
		let id = router.subscribeToBeforeRouterNavigate(() => {
			const args = ['-t', 'sessionEnd'];
			let assemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(props.assemblyHwid.partNumber);

			// if the assembly is an NG we need to add the '-n' arg to the list
			if (assemblyType && ['NG_PENETRATOR_PAYLOAD_ASSEMBLY', 'NG_FRAG_PAYLOAD_ASSEMBLY'].includes(assemblyType)) {
				args.push('-n');
			}

			return new Promise((resolve) => {
				if (!isModified) {
					if (connectionStatus === 'CONNECTED')
						socketioService.runPythonScript(props.testFixture.scriptName, ...args);
					resolve(false);
					return;
				}
				popupController.open<ConfirmPopupProps>(ConfirmPopup, {
					title: 'Unsaved Changes',
					message: 'Are you sure you want to leave without saving your changes?',
					confirmButtonText: 'Stay',
					closeButtonText: 'Leave',
					onConfirm: () => {
						resolve(true);
					},
					onCancel: () => {
						if (connectionStatus === 'CONNECTED')
							socketioService.runPythonScript(props.testFixture.scriptName, ...args);

						resolve(false);
					}
				});
			});
		});
		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
		};
	}, [connectionStatus, isModified]);

	// Create a hook that monitors if they are connected and not running tests, so we can force shut off the power supply
	useEffect(() => {
		let watchIntervalId: NodeJS.Timeout | undefined;
		if (connectionStatus === 'CONNECTED' && currentRunningIndex === undefined) {
			let nonTestRunningStartTime = Date.now();
			let isPopupVisible = false;
			watchIntervalId = setInterval(() => {
				if (isPopupVisible) return;
				if (Date.now() - nonTestRunningStartTime > NON_TEST_RUNNING_TIMEOUT_MS) {
					isPopupVisible = true;
					popupController.open<DeviceIdlePopupProps>(DeviceIdlePopup, {
						onPowerOff: () => {
							socketioService.runPythonScript(props.testFixture.scriptName, ...['-t', 'sessionEnd']);
						},
						onKeepOn: () => {
							isPopupVisible = false;
							nonTestRunningStartTime = Date.now();
						},
						onRestorePower: () => {
							nonTestRunningStartTime = Date.now();
							rsToastify.info('Wait a couple seconds to run power checks.', 'Restoring Power...');
							isPopupVisible = false;
							startSession();
						}
					});
				}
			}, 5 * 1000);
		}
		return () => {
			if (watchIntervalId) clearInterval(watchIntervalId);
		};
	}, [connectionStatus, currentRunningIndex]);

	// Create a unique state machine for when rtsp video is used
	useEffect(() => {
		if (!rtspVideoUrl) {
			setCurrentPlayingUrl('');
			setIsPlayingVideo(false);
			return;
		}

		if (!currentPlayingUrl) {
			setCurrentPlayingUrl(rtspVideoUrl.url);
			setIsPlayingVideo(true);
		} else if (currentPlayingUrl !== rtspVideoUrl.url) {
			(async function () {
				// We need the player to see a stop for a bit before we can change the url
				setIsPlayingVideo(false);
				await WebUtils.sleep(250);
				setCurrentPlayingUrl(rtspVideoUrl.url);
				setIsPlayingVideo(true);
			})();
		}
	}, [rtspVideoUrl]);

	useEffect(() => {
		// Reset when we first mount
		setTestPrompts([]);
		setTestResults([]);
		setRtspVideoUrl(undefined);
		setTerminatedTestCode(undefined);
		// Get settings from shim if possible.
		if (testerShimStatus.status !== 'DISCONNECTED') {
			socketioService.getFwSettingsData();
		}
	}, []);

	useEffect(() => {
		if (!terminatedTestCode && terminatedTestCode !== 1) return;
		setTerminatedTestValues('Error: Test fixture failure.');
	}, [terminatedTestCode]);

	function getHwidParams(hwid: HardwareIdDecoded) {
		let argPartNumber = hwid.partNumber.split('-')[0];
		let hwRevMajorMinor = hwid.hardwareRevision.split('.');
		return [
			'-p1',
			argPartNumber,
			'-p2',
			hwRevMajorMinor.length < 2 ? hwid.hardwareRevision : hwRevMajorMinor[0],
			'-p3',
			hwRevMajorMinor.length < 2 ? hwid.hardwareRevision : hwRevMajorMinor[1],
			'-p4',
			hwid.serialNumber
		];
	}

	async function getTestArgs(testName: string, testArgs: string[]): Promise<string[]> {
		const args = [...testArgs];
		// check if test needs additional args
		const assemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(props.assemblyHwid.partNumber);
		switch (assemblyType) {
			case 'NG_PENETRATOR_PAYLOAD_ASSEMBLY':
			case 'NG_FRAG_PAYLOAD_ASSEMBLY':
				if (testName === 'partNumber') {
					const additionalArgs = getHwidParams(props.assemblyHwid);
					args.push(...additionalArgs);
				}
				break;
			case 'PENETRATOR_PAYLOAD_ASSEMBLY':
			case 'FRAG_PAYLOAD_ASSEMBLY':
			case 'INERT_FRAG_PAYLOAD_ASSEMBLY':
			case 'INERT_PENETRATOR_PAYLOAD_ASSEMBLY':
			case 'TRAINER_PAYLOAD_ASSEMBLY':
				if (testName === 'pipsSerial' || testName === 'paramSerial') {
					const additionalArgs = getHwidParams(props.assemblyHwid);
					if (testName === 'pipsSerial') {
						let assemblyPart = await ApiRequestV1.getPartByNumbers({
							partNumber: props.assemblyHwid.partNumber,
							serialNumber: props.assemblyHwid.serialNumber
						});
						let pipsPart = assemblyPart.children.find((child) =>
							baseParts['PIPS_BOARD'].partNumbers.includes(child.partNumber)
						);
						if (pipsPart) {
							additionalArgs.push(...['-p5', pipsPart.serialNumber]);
						}
					}
					args.push(...additionalArgs);
				}
				break;
			case 'MILITARY_AIR_VEHICLE_ASSEMBLY':
				if (testName === 'writeSn') {
					args.push(...getHwidParams(props.assemblyHwid));
					// We need to dive down the parent->child relationship tree to find the main board part and get its serial number
					// Vehicle -> Chassis -> Main Board
					const militaryVehicleAssemblyDetails = await ApiRequestV1.getPartByNumbers({
						partNumber: props.assemblyHwid.partNumber,
						serialNumber: props.assemblyHwid.serialNumber
					});
					const airVehicleInfo = militaryVehicleAssemblyDetails.children.find((child) =>
						partAssemblies['AIR_VEHICLE_ASSEMBLY'].partNumbers.includes(child.partNumber)
					);
					if (!airVehicleInfo) {
						rsToastify.error('Could not find air Vehicle in Assembly tree.', 'Failed to get chassis part');
						throw new Error('Failed to get Vehicle Assembly');
					}

					const vehicleAssemblyDetails = await ApiRequestV1.getPartByNumbers({
						partNumber: airVehicleInfo.partNumber,
						serialNumber: airVehicleInfo.serialNumber
					});

					const chassisPartInfo = vehicleAssemblyDetails.children.find((child) =>
						partAssemblies['CHASSIS_MAIN_ASSEMBLY'].partNumbers.includes(child.partNumber)
					);

					if (!chassisPartInfo) {
						rsToastify.error('Could not find chassis part in assembly tree.', 'Failed to get chassis part');
						throw new Error('Failed to get chassis part');
					}
					const chassisPartDetails = await ApiRequestV1.getPartByNumbers({
						partNumber: chassisPartInfo.partNumber,
						serialNumber: chassisPartInfo.serialNumber
					});
					const mainBoardPartInfo = chassisPartDetails.children.find((child) =>
						partAssemblies['MAIN_BOARD_ASSEMBLY'].partNumbers.includes(child.partNumber)
					);

					if (!mainBoardPartInfo) {
						rsToastify.error(
							'Could not find Main Board part in assembly tree.',
							'Failed to get Main Board part'
						);
						throw new Error('Failed to get Main Board part');
					}
					args.push(...['-p5', mainBoardPartInfo.serialNumber]);
				}
				break;
			case 'AIR_VEHICLE_ASSEMBLY':
				if (testName === 'writeSn' || testName === 'writeVehicleName' || testName === 'verifyHwid') {
					// in the case where we got a military parentID, then we really want to use the info from the parent to write and read the serial number.
					if (props.parentAssemblyHwid) {
						const parentAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(
							props.parentAssemblyHwid.partNumber
						);
						if ('MILITARY_AIR_VEHICLE_ASSEMBLY' == parentAssemblyType)
							args.push(...getHwidParams(props.parentAssemblyHwid));
					} else {
						args.push(...getHwidParams(props.assemblyHwid));
					}
					// We need to dive down the parent->child relationship tree to find the main board part and get its serial number
					// Vehicle -> Chassis -> Main Board
					const vehicleAssemblyDetails = await ApiRequestV1.getPartByNumbers({
						partNumber: props.assemblyHwid.partNumber,
						serialNumber: props.assemblyHwid.serialNumber
					});
					const chassisPartInfo = vehicleAssemblyDetails.children.find((child) =>
						partAssemblies['CHASSIS_MAIN_ASSEMBLY'].partNumbers.includes(child.partNumber)
					);
					if (!chassisPartInfo) {
						rsToastify.error('Could not find chassis part in assembly tree.', 'Failed to get chassis part');
						throw new Error('Failed to get chassis part');
					}
					const chassisPartDetails = await ApiRequestV1.getPartByNumbers({
						partNumber: chassisPartInfo.partNumber,
						serialNumber: chassisPartInfo.serialNumber
					});
					const mainBoardPartInfo = chassisPartDetails.children.find((child) =>
						partAssemblies['MAIN_BOARD_ASSEMBLY'].partNumbers.includes(child.partNumber)
					);

					if (!mainBoardPartInfo) {
						rsToastify.error(
							'Could not find Main Board part in assembly tree.',
							'Failed to get Main Board part'
						);
						throw new Error('Failed to get Main Board part');
					}
					args.push(...['-p5', mainBoardPartInfo.serialNumber]);
				} else if (testName === 'verifyRadio' || testName === 'radioMultipoint') {
					let radioMhz = parseInt(testSettings.radioMhz) * 1000;
					args.push(...['-p1', radioMhz.toString()]);
				}
				break;
			case 'FRAG_CENTERSTAGE_ASSEMBLY':
			case 'PENETRATOR_CENTERSTAGE_ASSEMBLY':
			case 'INERT_FRAG_CENTERSTAGE_ASSEMBLY':
			case 'INERT_PENETRATOR_CENTERSTAGE_ASSEMBLY':
			case 'TRAINER_CENTERSTAGE_ASSEMBLY':
				if (testName === 'sibEeprom') {
					let assemblyPart = await ApiRequestV1.getPartByNumbers({
						partNumber: props.assemblyHwid.partNumber,
						serialNumber: props.assemblyHwid.serialNumber
					});
					let sibPart = assemblyPart.children.find((child) =>
						baseParts['SIB'].partNumbers.includes(child.partNumber)
					);
					if (sibPart) {
						let hwRevMajorMinor = sibPart.hardwareRev.split('.');
						args.push(
							...[
								'-p1',
								sibPart.partNumber,
								'-p2',
								hwRevMajorMinor.length < 2 ? sibPart.hardwareRev : hwRevMajorMinor[0],
								'-p3',
								hwRevMajorMinor.length < 2 ? sibPart.hardwareRev : hwRevMajorMinor[1],
								'-p4',
								sibPart.serialNumber,
								'-p5',
								// TODO: update path - this won't work yet
								`/tmp/boardTempCalFixture/${'trayNumber'}/SIB/${sibPart.partNumber}-${
									sibPart.hardwareRev
								}-${sibPart.serialNumber}.json`
							]
						);
					}
				}
				break;
			case 'CHASSIS_MAIN_ASSEMBLY':
				if (testName === 'paramSerial') {
					args.push(...['-p1', props.assemblyHwid.serialNumber]);
				} else if (testName === 'radioFirmware') {
					let radioMhz = parseInt(testSettings.radioMhz) * 1000;
					args.push(...['-p1', radioMhz.toString()]);
				} else if (testName === 'hwVersionCheck') {
					let assemblyPart = await ApiRequestV1.getPartByNumbers({
						partNumber: props.assemblyHwid.partNumber,
						serialNumber: props.assemblyHwid.serialNumber
					});
					const mainBoardPartInfo = assemblyPart.children.find((child) =>
						partAssemblies['MAIN_BOARD_ASSEMBLY'].partNumbers.includes(child.partNumber)
					);

					if (!mainBoardPartInfo) {
						rsToastify.error(
							'Could not find Main Board part in assembly tree.',
							'Failed to get Main Board part'
						);
						throw new Error('Failed to get Main Board part');
					}
					// Sadly the database saved as hardwareRev, but the script expects hardwareRevision
					args.push(
						...getHwidParams({ ...mainBoardPartInfo, hardwareRevision: mainBoardPartInfo.hardwareRev })
					);
				}
				break;
		}

		return args;
	}

	async function triggerTest(index?: number) {
		if (index === undefined) return;
		setTerminatedTestCode(undefined);
		// set selected step
		setCurrentSelectedIndex((prevState) => {
			// if state was on step that just finished
			if (prevState === index || prevState === index - 1) {
				return index;
			}
			return prevState;
		});
		const currentTest = props.testFixture.tests[index];
		const args = await getTestArgs(currentTest.testName, currentTest.pythonArgs);
		socketioService.runPythonScript(props.testFixture.scriptName, ...args);
		setIsModified(true);
	}

	useEffect(() => {
		triggerTest(currentRunningIndex).catch(console.error);
	}, [currentRunningIndex]);

	useEffect(() => {
		if (currentRunningIndex === undefined && pauseAtIndex.current === undefined) {
			setTestPrompts([]);
		}
	}, [currentRunningIndex]);

	useEffect(() => {
		// set next running index if we got a result
		if (!ObjectUtils.isArrayWithData(testResults) || currentRunningIndex === undefined) return;
		const result = testResults.find(
			(res) => res.testName.split('_')[0] === props.testFixture.tests[currentRunningIndex].testName
		);
		if (!result) return;
		if (result.continuable) {
			// don't continue if failed - even if continuable
			if (!result.passed && runType === 'firstFail') {
				setCurrentRunningIndex(undefined);
				return;
			}
			setCurrentRunningIndex((prev) => {
				if (prev === undefined) return 0;
				const nextIndex = prev + 1;

				// handle the last step
				if (nextIndex >= props.testFixture.tests.length) {
					startAtIndex.current = 0;
					pauseAtIndex.current = undefined;
					// If we have run all the tests, and this is the last test, prompt a toast
					// This is helpful for the typical run all case.
					if (hasRunAllTests()) {
						rsToastify.success(`${props.testFixture.name} has finished running.`, 'Test Complete');
					}
					return undefined;
				}
				// handle pause
				if (!!pauseAtIndex.current && nextIndex === pauseAtIndex.current) {
					startAtIndex.current = nextIndex;
					pauseAtIndex.current = undefined;
					return undefined;
				}
				return nextIndex;
			});
		} else {
			// end the test
			setCurrentRunningIndex(undefined);
		}
	}, [testResults]);

	function hasRunAllTests(): boolean {
		for (let test of props.testFixture.tests) {
			const existsInResults = testResults.some((obj) => obj.testName.split('_')[0] === test.testName);
			if (!existsInResults) {
				return false;
			}
		}
		return true;
	}

	function getOverallTestStatus(): 'PASS' | 'FAIL' | 'INCOMPLETE' {
		if (testResults.length - 1 < props.testFixture.tests.length || !hasRunAllTests()) {
			return 'INCOMPLETE';
		}
		if (testResults.some((result) => !result.passed)) {
			return 'FAIL';
		}
		return 'PASS';
	}

	async function handleDisconnectAndSave() {
		try {
			const existingTestNames = new Set(testResults.map((result) => result.testName));
			let resultsToPost: TestResult[] = [
				...testResults,
				...props.testFixture.tests
					.filter((test) => !existingTestNames.has(test.testName))
					.map((test) => {
						let result: TestResult = {
							testName: test.testName,
							hasNotRan: true,
							passed: false,
							continuable: true,
							timeStamp: new Date().toLocaleTimeString([], {
								hour: '2-digit',
								minute: '2-digit',
								second: '2-digit',
								hour12: true
							}),
							error: ''
						};
						return result;
					})
			];

			let res = await ApiRequestV1.postResult({ data: JSON.stringify(resultsToPost) });

			await ApiRequestV1.postTestResult({
				partId: props.testFixture.partId!,
				connectionGuid,
				testName: props.testFixture.name,
				status: getOverallTestStatus(),
				hasPassed: testResults.every((res) => res.passed),
				isComplete: hasRunAllTests(),
				resultId: res.id
			});
			rsToastify.success('Test results saved successfully.', 'Test Results Saved');

			// Disconnect test
			if (connectionStatus === 'CONNECTED') {
				const args = ['-t', 'sessionEnd'];
				let assemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(props.assemblyHwid.partNumber);

				// if the assembly is an NG we need to add the '-n' arg to the list
				if (
					assemblyType &&
					['NG_PENETRATOR_PAYLOAD_ASSEMBLY', 'NG_FRAG_PAYLOAD_ASSEMBLY'].includes(assemblyType)
				) {
					args.push('-n');
				}

				socketioService.runPythonScript(props.testFixture.scriptName, ...args);
			}
			props.onDone();
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown'), 'Server Error');
		}
	}

	function runTest(type: RunType) {
		setRunType(type);
		if (testerShimStatus.status === 'DISCONNECTED') {
			rsToastify.error('Tester shim is not connected.', 'Tester Shim Not Connected');
			return;
		}
		if (startAtIndex.current === currentRunningIndex) {
			triggerTest(startAtIndex.current).catch(console.error);
		} else {
			setCurrentRunningIndex(startAtIndex.current);
		}
		setIsPaused(false);
	}

	function handlePauseTest() {
		// TODO: figure out how to stop currently running test
		//       for now it will appear to pause, but will keep
		//       running last step until finished.
		if (isPaused) {
			// continue test
			setIsPaused(false);
			return;
		}
		if (!currentRunningIndex) return;
		setIsPaused(true);
		if (testPrompts.length) {
			// need to finish step before going to the next one
			return;
		}
		startAtIndex.current = currentRunningIndex + 1;
		setCurrentRunningIndex(undefined);
	}

	function setTerminatedTestValues(errorMsg: string, startIndex?: number) {
		// Should stop current running test and mark it as failed.
		if (currentRunningIndex === undefined) {
			if (connectionStatus === 'CONNECTING') {
				// error connecting
				setConnectionStatus('DISCONNECTED');
				setTestResults((prev) => {
					return [
						{
							testName: 'sessionStart',
							passed: false,
							continuable: true,
							timeStamp: new Date().toLocaleTimeString([], {
								hour: '2-digit',
								minute: '2-digit',
								second: '2-digit',
								hour12: true
							}),
							error: errorMsg
						},
						...prev
					];
				});
				setTerminatedTestCode(undefined);
			}
			return;
		}
		const abortedTestIndex = currentRunningIndex;
		startAtIndex.current = startIndex !== undefined ? startIndex : abortedTestIndex;
		setCurrentRunningIndex(undefined);
		setIsPlayingVideo(false);
		setIsPaused(false);
		setTestPrompts([]);
		setTestResults((prev) => {
			return [
				{
					testName: props.testFixture.tests[abortedTestIndex].testName,
					passed: false,
					continuable: true,
					timeStamp: new Date().toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
						hour12: true
					}),
					error: errorMsg
				},
				...prev
			];
		});
	}

	function handleAbortTest() {
		handlePauseTest();
		popupController.open<ConfirmPopupProps>(ConfirmPopup, {
			title: 'Abort?',
			message: 'Are you sure you want to stop testing?',
			onConfirm: () => {
				try {
					socketioService.abortTest();
					setTerminatedTestValues('Test was aborted.', 0);
					rsToastify.success('The test was successfully aborted.', 'Test Aborted');

					// Clean up a lot of test stuff
					setRtspVideoUrl(undefined);
					setIsPlayingVideo(false);
					setCurrentPlayingUrl('');
				} catch (e) {
					rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Failed Aborting Test');
				}
			},
			confirmButtonText: 'Abort'
		});
	}

	function handleRunOptionClick(option: RunOption, stepIndex: number, runType: RunType) {
		switch (option) {
			case 'from':
				startAtIndex.current = stepIndex;
				runTest(runType);
				break;
			case 'only':
				startAtIndex.current = stepIndex;
				pauseAtIndex.current = stepIndex + 1;
				runTest(runType);
				break;
			case 'to':
				startAtIndex.current = 0;
				pauseAtIndex.current = stepIndex + 1;
				runTest(runType);
				break;
		}
	}

	function startSession() {
		let portData: PortEntryData = {
			VTO_PORT: {
				desc: 'The list_port match string for VTO ftdi serial.',
				set: testSettings.vtoId
			},
			VTO_PORT_SN: {
				desc: 'The serial number of FTDI USB to UART conversion cable for VTO spoof.',
				set: testSettings.vtoSerial
			},
			WAKE_PORT: {
				desc: 'ID for the battery wake port.',
				set: testSettings.batteryId
			},
			WAKE_PORT_SN: {
				desc: 'Serial number for the battery wake port.',
				set: testSettings.batterySerial
			},
			HOB_PORT: {
				desc: 'The list_port match string for HOB/LRF ftdi serial.',
				set: testSettings.hobId
			},
			HOB_PORT_SN: {
				desc: 'The serial number of FTDI USB to UART conversion cable for HOB/LRF serial.',
				set: testSettings.hobSerial
			},
			ORIENT_PORT: {
				desc: 'The manufacturing number of FTDI USB to UART conversion cable for Orientation serial.',
				set: testSettings.orientId
			},
			ORIENT_PORT_SN: {
				desc: 'The serial number of FTDI USB to UART conversion cable for Orientation serial.',
				set: testSettings.orientSerial
			}
		};
		const args = ['-t', 'sessionStart', '-p1', props.assemblyHwid.partNumber];
		let assemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(props.assemblyHwid.partNumber);
		let firmwareToLoad = '';

		// if the assembly is an NG we need to add the '-n' arg to the list
		if (assemblyType && ['NG_PENETRATOR_PAYLOAD_ASSEMBLY', 'NG_FRAG_PAYLOAD_ASSEMBLY'].includes(assemblyType)) {
			args.push('-n');
		}

		// add firmware to load if applicable
		if (assemblyType && partAssemblies[assemblyType].requiresFirmware) {
			// At this point, we only do military Firmware, we need to whitelist commercial firmware usage by part type.
			if (commercialFirmwareAssemblyPartTypes.includes(assemblyType)) {
				if (firmwareSettings.commercialFirmware === '') {
					rsToastify.error('No Commercial firmware selected.', 'Commercial Firmware Setting Required');
					return;
				}
				firmwareToLoad = firmwareSettings.commercialFirmware;
			} else {
				if (firmwareSettings.militaryFirmware === '') {
					rsToastify.error('No Military firmware selected.', 'Military Firmware Setting Required');
					return;
				}
				firmwareToLoad = firmwareSettings.militaryFirmware;
			}
		}
		socketioService.runSessionStart(props.testFixture.scriptName, portData, firmwareToLoad, ...args);
	}

	function renderPrompts() {
		if (!ObjectUtils.isArrayWithData(testPrompts)) return;
		return [...testPrompts]
			.sort((a, b) => {
				return a.position - b.position;
			})
			.map((prompt) => {
				return <PromptItem key={JSON.stringify(prompt)} {...prompt} disabled={isPaused} />;
			});
	}

	function renderPageHeaderCenterMessage() {
		let hwidLabel = (
			<Label variant={'subheader2'} weight={'regular'}>
				{props.parentAssemblyHwid
					? `PN1:${props.parentAssemblyHwid.partNumber} SN1:${props.parentAssemblyHwid.serialNumber} REV1:${props.parentAssemblyHwid.hardwareRevision}`
					: `PN1:${props.assemblyHwid.partNumber} SN1:${props.assemblyHwid.serialNumber} REV1:${props.assemblyHwid.hardwareRevision}`}
			</Label>
		);

		if (isPaused)
			return (
				<Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
					{hwidLabel}
					<Label variant={'subheader2'} weight={'regular'}>
						Paused
					</Label>
				</Box>
			);
		if (currentRunningIndex || testResults.length) {
			let results = testResults.filter((result) => result.testName !== 'applyPower');
			let completed = props.testFixture.tests.reduce((acc, test1) => {
				if (results.some((test2) => test2.testName.split('_')[0] === test1.testName)) {
					return acc + 1;
				}
				return acc;
			}, 0);

			return (
				<Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
					{hwidLabel}
					<Label
						variant={'subheader2'}
						weight={'regular'}
					>{`${completed}/${props.testFixture.tests.length} completed`}</Label>
				</Box>
			);
		}
		return hwidLabel;
	}

	function renderPageHeaderRightButtons() {
		const hasResults = !!testResults.length;
		if (connectionStatus !== 'CONNECTED') {
			return (
				<Box display={'flex'} gap={16}>
					{connectionStatus === 'DISCONNECTED' && isModified && (
						<Button look={'outlinedPrimary'} onClick={handleDisconnectAndSave}>
							Save & Close
						</Button>
					)}
					<Button
						look={'containedPrimary'}
						onClick={() => {
							if (testerShimStatus.status !== 'CONNECTED_DATA') {
								rsToastify.error('Tester shim is not connected.', 'Tester Shim Not Connected');
								return;
							}
							setIsModified(true);
							setConnectionStatus('CONNECTING');
							startSession();
						}}
						disabled={connectionStatus === 'CONNECTING'}
					>
						{connectionStatus === 'CONNECTING' ? 'Connecting' : 'Connect'}
					</Button>
				</Box>
			);
		}
		return (
			<Button
				look={'containedPrimary'}
				disabled={currentRunningIndex !== undefined}
				onClick={() => {
					if (!hasResults) props.onDone();
					else handleDisconnectAndSave().catch(console.error);
				}}
			>
				{hasResults ? 'Disconnect & Save' : 'Disconnect'}
			</Button>
		);
	}

	function renderVideoPlayer() {
		return (
			<RtspVideoPlayer
				url={currentPlayingUrl}
				xRes={rtspVideoUrl?.xRes || 640}
				yRes={rtspVideoUrl?.yRes || 480}
				play={true}
				onDisconnected={() => {
					rsToastify.error('The video stream was disconnected.', 'Video Stream Disconnected');
				}}
				onClick={(x, y) => {
					socketioService.sendPromptResponse({
						name: 'command',
						command: 'videoClick',
						data: {
							id: Date.now().toString(),
							x: x.toString(),
							y: y.toString()
						}
					});
				}}
			/>
		);
	}

	return (
		<Box className={'rsTestRunnerSection'}>
			<PageHeader
				title={`${props.testFixture.name}`}
				isModified={isModified}
				centerNode={renderPageHeaderCenterMessage()}
				rightNode={renderPageHeaderRightButtons()}
			/>

			<Box className={'content'}>
				<TestList
					onAbortTest={handleAbortTest}
					onPauseTest={handlePauseTest}
					onRunTest={(type) => runTest(type)}
					testList={props.testFixture.tests}
					currentRunningIndex={currentRunningIndex}
					currentSelectedIndex={currentSelectedIndex}
					onRunOptionClick={handleRunOptionClick}
					onTestItemClick={(stepIndex) => {
						setCurrentSelectedIndex(stepIndex);
					}}
					isPaused={isPaused}
					isConnected={connectionStatus === 'CONNECTED'}
				/>
				<Box className={'testContent'}>
					{assemblyDetails && <AssemblyAuditCheck mode={'BOTH'} partId={assemblyDetails.id} />}
					<Label variant={'h3'} weight={'semiBold'}>
						{props.testFixture.tests[currentRunningIndex ?? currentSelectedIndex]?.label}
					</Label>
					{!!testPrompts.length && <Box className={'messageContainer'}>{renderPrompts()}</Box>}
					{isPlayingVideo && renderVideoPlayer()}
					{!!testResults.length && <ResultsList results={testResults} />}
				</Box>
			</Box>
		</Box>
	);
};

export default TestRunnerSection;
