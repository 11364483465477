import * as React from 'react';
import { useState } from 'react';
import { Box, Checkbox, RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';

interface CameraInspectionProps {}

enum FormKeys {
	EO_FEED_VISIBLE = 'eoPayloadCameraFeedVisible',
	IR_FEED_VISIBLE = 'irPayloadCameraFeedVisible',
	CAMERA_POINTING = 'cameraPointing'
}

const CameraInspection: React.FC<CameraInspectionProps> = () => {
	const TEST_NAME: TestKey = 'cameraInspection';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.EO_FEED_VISIBLE, res ? res.data[FormKeys.EO_FEED_VISIBLE] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			]),
			new RsFormControl<boolean>(FormKeys.IR_FEED_VISIBLE, res ? res.data[FormKeys.IR_FEED_VISIBLE] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			]),
			new RsFormControl<boolean>(FormKeys.CAMERA_POINTING, res ? res.data[FormKeys.CAMERA_POINTING] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			])
		])
	);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME);
	}

	return (
		<Box className={'rsCameraInspection'} display={'flex'} flexDirection={'column'} gap={16}>
			<Checkbox
				labelText={'EO payload camera video feed visible on both tablet and laptop'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.EO_FEED_VISIBLE)}
				updateControl={handleUpdateControl}
			/>
			<Checkbox
				labelText={'IR payload camera video feed visible on both tablet and laptop'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.IR_FEED_VISIBLE)}
				updateControl={handleUpdateControl}
			/>
			<Checkbox
				labelText={'Click 4 separate Points on the tablet EO video feed to verify general payload pointing'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.CAMERA_POINTING)}
				updateControl={handleUpdateControl}
			/>
		</Box>
	);
};
export default CameraInspection;
