import * as React from 'react';
import { Box } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useState } from 'react';
import EvaluationItem, {
	EvaluationOptions,
	EvaluationGroup
} from '../../../../components/evaluationItem/EvaluationItem';
import ServiceFactory from '../../../../services/serviceFactory';

interface VerifyEsadReadyProps {}

type VerifyEsadEvaluationGroup = EvaluationGroup & {
	verifyEsadToWam1: EvaluationOptions | undefined;
	verifyEsadToWam2: EvaluationOptions | undefined;
	verifyEsadToWam3: EvaluationOptions | undefined;
};

type EvaluationKey = keyof VerifyEsadEvaluationGroup;

const VerifyEsadReady: React.FC<VerifyEsadReadyProps> = () => {
	const TEST_NAME: TestKey = 'verifyEsadTransitionToWam';
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const [evaluations, setEvaluations] = useState<VerifyEsadEvaluationGroup>({
		verifyEsadToWam1: res ? res.data['verifyEsadToWam1'] : undefined,
		verifyEsadToWam2: res ? res.data['verifyEsadToWam2'] : undefined,
		verifyEsadToWam3: res ? res.data['verifyEsadToWam3'] : undefined
	});

	useEffect(() => {
		testFixtureService.validateEvaluationTest(TEST_NAME, evaluations);
	}, [evaluations]);

	function handleEvaluationClick(evaluation: EvaluationOptions, key: EvaluationKey) {
		setEvaluations((prevState) => {
			let updatedState = { ...prevState };
			updatedState[key] = evaluation;
			return updatedState;
		});
	}

	return (
		<Box className={'rsVerifyEsadReady'} display={'flex'} flexDirection={'column'} gap={16}>
			<EvaluationItem
				label={'Launch #1: ESAD transitions to “WAM”'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'verifyEsadToWam1');
				}}
				evaluation={evaluations.verifyEsadToWam1}
			/>
			<EvaluationItem
				label={'Launch #2: ESAD transitions to “WAM”'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'verifyEsadToWam2');
				}}
				evaluation={evaluations.verifyEsadToWam2}
			/>
			<EvaluationItem
				label={'Launch #3: ESAD transitions to “WAM”'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'verifyEsadToWam3');
				}}
				evaluation={evaluations.verifyEsadToWam3}
			/>
		</Box>
	);
};
export default VerifyEsadReady;
