import { useEffect, useState } from 'react';
import { RsFormControl, RsFormGroup } from '@redskytech/framework/ui';

import { DataTableFilterMeta } from 'primereact/datatable';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';

export default function useDataTableFilters(
	initialFilters: DataTableFilterMeta,
	initialFiltersFormGroup: RsFormGroup,
	updateFilters?: (field: string, value: any) => DataTableFilterMeta,
	initialGlobalSearchValue?: string | undefined
) {
	const [globalSearch, setGlobalSearch] = useState<string>(initialGlobalSearchValue || '');
	const [filtersFormGroup, setFiltersFormGroup] = useState<RsFormGroup>(initialFiltersFormGroup);
	const [filters, setFilters] = useState<DataTableFilterMeta>(initialFilters);
	const [activeFilters, setActiveFilters] = useState<string[]>([]);

	useEffect(() => {
		const filtersWithValues: string[] = [];
		for (let filter in filters) {
			const currentFilter: any = filters[filter];
			if (currentFilter?.constraints[0]?.value) filtersWithValues.push(filter);
		}
		setActiveFilters(filtersWithValues);
	}, [filters]);

	function getUpdatedFilters(field: string, value: any) {
		const filter: any = filters[field];
		const constraint = [
			{
				value: value,
				matchMode: filter?.constraints[0]?.matchMode
			}
		];
		return {
			...filters,
			[field]: {
				...filter,
				constraints: constraint
			}
		};
	}

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFiltersFormGroup(filtersFormGroup.clone().update(control));
	}

	function handleClearAllFilters() {
		setGlobalSearch('');
		setFilters(initialFilters);
		filtersFormGroup.resetToInitialValue();
	}

	function handleFilterClear(field: string) {
		const updatedControl = filtersFormGroup.get(field);
		updatedControl.value = '';
		handleUpdateControl(updatedControl);
		const updatedFilters = updateFilters ? updateFilters(field, null) : getUpdatedFilters(field, null);
		setFilters(updatedFilters);
	}

	function handleFilterApply(field: string) {
		const value = filtersFormGroup.get(field).value;
		if (!value) {
			// Allow clear by empty value
			handleFilterClear(field);
			return;
		}
		const updatedFilters = updateFilters ? updateFilters(field, value) : getUpdatedFilters(field, value);
		setFilters(updatedFilters);
	}

	return {
		filters,
		activeFilters,
		globalSearch,
		setGlobalSearch,
		handleClearAllFilters,
		handleFilterClear,
		handleFilterApply,
		handleUpdateControl,
		filtersFormGroup
	};
}
