import React from 'react';
import './AppBar.scss';
import UserBadge from '../userBadge/UserBadge';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { Box, Img } from '@redskytech/framework/ui';
import { Link } from '@redskytech/framework/996';
import logo from '../../images/logo_wordmark.svg?width=194&webp&imagetools';
import TesterStatus from '../testerStatus/TesterStatus';
import { StringUtils } from '../../utils/utils';
import WebVersionStatus from '../webVersionStatus/WebVersionStatus';

const AppBar: React.FC = () => {
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);

	function renderLoggedInItems() {
		if (!user) return null;

		return (
			<Box className={'loggedInSection'}>
				{/* The order of TesterStatus and WebVersionStatus
				 here affects which error is on top when both are active
				 The last one is the one that will be on top! */}
				<TesterStatus />
				<WebVersionStatus />
				<UserBadge
					userName={`${user?.firstName} ${user?.lastName}`}
					imageUrl={''}
					superText={StringUtils.capitalizeFirst(user?.role)}
				/>
			</Box>
		);
	}

	return (
		<Box className="rsAppBar" pl={24} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
			<Box className="topName">
				<Link path={'/dashboard'} className={'linkWrapper'}>
					<Img src={logo} alt={'company logo'} width={194} height={32} disableImageKit />
				</Link>
			</Box>
			{renderLoggedInItems()}
		</Box>
	);
};

export default AppBar;
