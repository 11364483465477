import * as React from 'react';
import {
	Box,
	LabelInputText,
	RsFormControl,
	RsFormGroup,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';

interface LiveVehicleHeightSensorTestingProps {
	testKey: TestKey;
	targetHeight: number;
	absHeightErrorMax: number;
	relativeError: [number, number];
}

enum FormKeys {
	HOB_READING = 'hobReading',
	AGL_READING = 'aglReading',
	SENSOR_DELTA = 'sensorDeltaHobMinusAgl'
}

const LiveVehicleHeightSensorTesting: React.FC<LiveVehicleHeightSensorTestingProps> = (props) => {
	const TEST_NAME: TestKey = props.testKey;
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.HOB_READING, res ? res.data[FormKeys.HOB_READING] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return testFixtureService.validateResultInRange(
						control,
						props.targetHeight - props.absHeightErrorMax,
						props.targetHeight + props.absHeightErrorMax
					);
				})
			]),

			new RsFormControl<string>(FormKeys.AGL_READING, res ? res.data[FormKeys.AGL_READING] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return testFixtureService.validateResultInRange(
						control,
						props.targetHeight - props.absHeightErrorMax,
						props.targetHeight + props.absHeightErrorMax
					);
				})
			]),
			new RsFormControl<string>(FormKeys.SENSOR_DELTA, res ? res.data[FormKeys.SENSOR_DELTA] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return testFixtureService.validateResultInRange(
						control,
						props.relativeError[0],
						props.relativeError[1]
					);
				})
			])
		])
	);

	// Inside WindSpeed component
	useEffect(() => {
		setFormGroup(
			new RsFormGroup([
				new RsFormControl<string>(FormKeys.HOB_READING, res ? res.data[FormKeys.HOB_READING] : '', [
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return testFixtureService.validateResultInRange(
							control,
							props.targetHeight - props.absHeightErrorMax,
							props.targetHeight + props.absHeightErrorMax
						);
					})
				]),

				new RsFormControl<string>(FormKeys.AGL_READING, res ? res.data[FormKeys.AGL_READING] : '', [
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return testFixtureService.validateResultInRange(
							control,
							props.targetHeight - props.absHeightErrorMax,
							props.targetHeight + props.absHeightErrorMax
						);
					})
				]),
				new RsFormControl<string>(FormKeys.SENSOR_DELTA, res ? res.data[FormKeys.SENSOR_DELTA] : '', [
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return testFixtureService.validateResultInRange(
							control,
							props.relativeError[0],
							props.relativeError[1]
						);
					})
				])
			])
		);
	}, [props.relativeError, props.targetHeight, props.absHeightErrorMax]);

	useEffect(() => {
		const formGroupClone = formGroup.cloneDeep();
		const hobReading = formGroupClone.get(FormKeys.HOB_READING).value;
		const aglReading = formGroupClone.get(FormKeys.AGL_READING).value;
		let hobValue: number;
		let aglValue: number;
		if (typeof hobReading === 'number') {
			// control.value is a number
			hobValue = hobReading;
		} else if (typeof hobReading === 'string') {
			// control.value is a string
			hobValue = parseFloat(hobReading);
		} else {
			hobValue = NaN;
		}
		if (typeof aglReading === 'number') {
			// control.value is a number
			aglValue = aglReading;
		} else if (typeof aglReading === 'string') {
			// control.value is a string
			aglValue = parseFloat(aglReading);
		} else {
			aglValue = NaN;
		}

		if (!isNaN(hobValue) && !isNaN(aglValue)) {
			const sensorDelta = hobValue - aglValue;
			const sensorDeltaControl = formGroupClone.get(FormKeys.SENSOR_DELTA);
			sensorDeltaControl.value = sensorDelta.toString();
			handleUpdateControl(sensorDeltaControl);
		}
	}, [formGroup.get(FormKeys.HOB_READING).value, formGroup.get(FormKeys.AGL_READING).value]);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME);
	}

	return (
		<Box className={'rsLiveVehicleHeightSensorTesting'} display={'flex'} flexDirection={'column'} gap={16}>
			<Box display={'grid'} gridTemplateColumns={'1fr'} gap={16}>
				At altitude, WAM, and then arm the ESAD with payload pointing down at 80deg
			</Box>
			<Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={16}>
				<LabelInputText
					labelTitle={'HOB Value'}
					inputMode={'text'}
					type={'text'}
					control={formGroup.get(FormKeys.HOB_READING)}
					updateControl={handleUpdateControl}
				/>
				<LabelInputText
					labelTitle={'AGL Value'}
					inputMode={'text'}
					type={'text'}
					control={formGroup.get(FormKeys.AGL_READING)}
					updateControl={handleUpdateControl}
				/>
			</Box>
		</Box>
	);
};
export default LiveVehicleHeightSensorTesting;
