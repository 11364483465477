import * as React from 'react';
import './FileUploadProgressPopup.scss';
import { Box, Label, Popup, PopupProps } from '@redskytech/framework/ui';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';

export interface FileUploadProgressPopupProps extends PopupProps {
	totalFiles: number;
}

const FileUploadProgressPopup: React.FC<FileUploadProgressPopupProps> = (props) => {
	const [percentage, setPercentage] = useState<number>(0);
	const uploadedFileData = useRecoilValue(globalState.uploadedFileData);

	useEffect(() => {
		setPercentage(Math.floor((uploadedFileData.length / props.totalFiles) * 100));
	}, [uploadedFileData]);

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsFileUploadProgressPopup'}>
				<Label variant={'subheader2'} weight={'regular'} mb={16} textAlign={'center'}>
					Uploading Result Files
				</Label>
				<ProgressBar percentage={percentage} animated striped hideIndicator />
				<Label
					variant={'caption1'}
					weight={'regular'}
					mt={8}
					textAlign={'center'}
				>{`${percentage}% Complete`}</Label>
			</Box>
		</Popup>
	);
};
export default FileUploadProgressPopup;
