import * as React from 'react';
import { useState, useEffect } from 'react';
import SelectableInputText from '../../components/selectableInputText/SelectableInputText';
import { Page, Link } from '@redskytech/framework/996';
import {
	Box,
	InputTextarea,
	Label,
	Checkbox,
	RsFormControl,
	rsToastify,
	InputText,
	RsFormGroup,
	Button,
	LabelSelect,
	RsValidatorEnum,
	RsValidator,
	Select
} from '@redskytech/framework/ui';
import { WebUtils } from '../../utils/utils';
import PageHeader from '../../components/pageHeader/PageHeader';
import serviceFactory from '../../services/serviceFactory';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import './TabletSetupPage.scss';
import { baseParts } from '../../services/assembly/assembly.data';

interface TabletSetupPageProps {}

interface ChecklistItem {
	key: string;
	value: boolean | string;
}

interface AssemblyTasksChecked {
	checklist: ChecklistItem[];
}

enum FormKeys {
	SERIAL_NUMBER = 'serialNumber',
	LOAD_TABLET_IMAGE = 'loadTabletImage',
	ACTIVATE_WINDOWS = 'activateWindowsKey',
	UPDATE_SCREEN_RESOLUTION = 'setScreenResolution',
	UPDATE_DISPLAY_SCALING = 'setDisplayScaling',
	ADD_OPERATING_MANUAL = 'addOperatingManualToDesktop',
	VERIFY_VERSION = 'verifyR1GCSVersion',
	VERIFY_SIMULATION_PURCHASED = 'simulationPurchasedVerified',
	SIMULATION_PURCHASED = 'simulationPurchased',
	VERIFY_SIMULATION_ENABLED = 'simulationModeEnabledVerified',
	SIMULATION_ENABLED = 'simulationModeEnabled',
	VERIFY_SIMULATION_TESTED = 'simulationModeTestedVerified',
	SIMULATION_TESTED = 'simulationModeTested',
	CONNECT_VEHICLE = 'connectToVehicle',
	ADD_FRIENDLY_NAME = 'addFriendlyName',
	FRIENDLY_NAME = 'friendlyName',
	CLEAN_AND_INSPECT = 'wipeCleanInspectRepackage',
	NOTES = 'notes'
}

const TabletSetupPage: React.FC<TabletSetupPageProps> = (props) => {
	const [isQuickLookupSelected, setIsQuickLookupSelected] = useState<boolean>(false);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.SERIAL_NUMBER, ''),
			new RsFormControl<string>(FormKeys.NOTES, '')
		])
	);

	const [checkListGroup, setCheckListGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.LOAD_TABLET_IMAGE, false),
			new RsFormControl<boolean>(FormKeys.ACTIVATE_WINDOWS, false),
			new RsFormControl<boolean>(FormKeys.UPDATE_SCREEN_RESOLUTION, false),
			new RsFormControl<boolean>(FormKeys.UPDATE_DISPLAY_SCALING, false),
			new RsFormControl<boolean>(FormKeys.ADD_OPERATING_MANUAL, false),
			new RsFormControl<boolean>(FormKeys.VERIFY_VERSION, false),
			new RsFormControl<boolean>(FormKeys.VERIFY_SIMULATION_PURCHASED, false),
			new RsFormControl(FormKeys.SIMULATION_PURCHASED, '', [new RsValidator(RsValidatorEnum.REQ, 'Required')]),
			new RsFormControl<boolean>(FormKeys.VERIFY_SIMULATION_ENABLED, false),
			new RsFormControl(FormKeys.SIMULATION_ENABLED, '', [new RsValidator(RsValidatorEnum.REQ, 'Required')]),
			new RsFormControl<boolean>(FormKeys.VERIFY_SIMULATION_TESTED, false),
			new RsFormControl(FormKeys.SIMULATION_TESTED, '', [new RsValidator(RsValidatorEnum.REQ, 'Required')]),
			new RsFormControl<boolean>(FormKeys.CONNECT_VEHICLE, false),
			new RsFormControl<boolean>(FormKeys.ADD_FRIENDLY_NAME, false),
			new RsFormControl<string>(FormKeys.FRIENDLY_NAME, ''),
			new RsFormControl<boolean>(FormKeys.CLEAN_AND_INSPECT, false)
		])
	);

	const isModified = checkListGroup.isModified() || formGroup.isModified();
	let serialNumber = formGroup.get<string>(FormKeys.SERIAL_NUMBER).value;

	useEffect(() => {
		if (serialNumber === '') {
			setIsQuickLookupSelected(false);
			resetFields();
		}
	}, [serialNumber]);

	async function handleQuickLookUp(value: string, enterPressed: boolean): Promise<'VALID' | string> {
		if (value === '') {
			handleDiscard();
			setIsQuickLookupSelected(false);
			return 'VALID';
		}
		if (enterPressed) {
			resetFields(true);
			setIsQuickLookupSelected(true);
			try {
				const res = await ApiRequestV1.getPartByNumbers({
					partNumber: baseParts['TABLET'].partNumbers[0],
					serialNumber: value
				});

				if (res) {
					let notesFormControl = formGroup.get(FormKeys.NOTES);
					if (res.notes) {
						notesFormControl.value = res.notes;
						setFormGroup(formGroup.clone().update(notesFormControl));
					}
					if (res.assemblyTasksChecked) {
						let parsed: AssemblyTasksChecked = JSON.parse(
							res.assemblyTasksChecked.replace(/^"(.*)"$/, '$1')
						);
						if (parsed.checklist) {
							parsed.checklist.forEach((item) => {
								const clone = checkListGroup.get(item.key);
								if (clone) {
									clone.value = item.value;
									setCheckListGroup(checkListGroup.clone().update(clone));
								}
							});
						}
						return 'VALID';
					}
				}
			} catch (e) {
				resetFields(true);
			}
		}
		return 'VALID';
	}

	function handleUpdateFormControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	// Only update verify check boxes when dropdowns are changed for simulation state
	function handleUpdateSimState(control: RsFormControl<IRsFormControl>) {
		if (
			!(
				control.key === FormKeys.SIMULATION_PURCHASED ||
				control.key === FormKeys.SIMULATION_ENABLED ||
				control.key === FormKeys.SIMULATION_TESTED
			)
		)
			return;

		let purchaseState = checkListGroup.get(FormKeys.SIMULATION_PURCHASED).value;
		let enabledState = checkListGroup.get(FormKeys.SIMULATION_ENABLED).value;
		let testedState = checkListGroup.get(FormKeys.SIMULATION_TESTED).value;

		if (control.key === FormKeys.SIMULATION_PURCHASED) {
			purchaseState = control.value;
		} else if (control.key === FormKeys.SIMULATION_ENABLED) {
			enabledState = control.value;
		} else if (control.key === FormKeys.SIMULATION_TESTED) {
			testedState = control.value;
		}

		// If the states are the same, then set the verify checkboxes to true
		// all three have to have the same state  for us to set the items to true
		if (
			((purchaseState === 'Yes') === (enabledState === 'Yes')) ===
			((testedState === 'Yes') === (enabledState === 'Yes'))
		) {
			checkListGroup.getControls().forEach((control) => {
				if (
					control.key === FormKeys.VERIFY_SIMULATION_PURCHASED ||
					control.key === FormKeys.VERIFY_SIMULATION_ENABLED ||
					control.key === FormKeys.VERIFY_SIMULATION_TESTED
				) {
					control.value = true;
					setCheckListGroup(checkListGroup.clone().update(control));
				}
			});
		} else {
			checkListGroup.getControls().forEach((control) => {
				if (
					control.key === FormKeys.VERIFY_SIMULATION_PURCHASED ||
					control.key === FormKeys.VERIFY_SIMULATION_ENABLED ||
					control.key === FormKeys.VERIFY_SIMULATION_TESTED
				) {
					control.value = false;
					setCheckListGroup(checkListGroup.clone().update(control));
				}
			});
		}
		setCheckListGroup(checkListGroup.clone().update(control));
	}

	function handleUpdateCheckListControl(control: RsFormControl<IRsFormControl>) {
		setCheckListGroup(checkListGroup.clone().update(control));
	}

	function resetFields(keepSerial = false) {
		setCheckListGroup(checkListGroup.clone().resetToInitialValue());
		if (keepSerial) {
			const notesControl = formGroup.get(FormKeys.NOTES);
			notesControl.value = '';
			setFormGroup(formGroup.clone().update(notesControl));
		} else {
			setFormGroup(formGroup.clone().resetToInitialValue());
		}
	}

	function handleDiscard() {
		resetFields();
	}

	async function handleSave() {
		await onSaveClicked();
	}

	async function handleSaveAndClose() {
		await onSaveClicked();
		resetFields();
	}

	async function onSaveClicked() {
		let assemblyTasksChecked: { checklist: { key: string; value: any }[] } = { checklist: [] };
		checkListGroup.getControls().forEach((control) => {
			assemblyTasksChecked.checklist.push({ key: control.key, value: control.value });
		});
		const notes = formGroup.get<string>(FormKeys.NOTES).value;
		const allTasksCompleted = assemblyTasksChecked.checklist.every((item) => !!item.value);

		const assemblyTasksCheckedString = `"${JSON.stringify(assemblyTasksChecked)}"`;
		const request = {
			notes: notes,
			assemblyTasksChecked: assemblyTasksCheckedString,
			serialNumber: serialNumber.toString(),
			name: 'Tablet',
			children: [],
			partNumber: baseParts['TABLET'].partNumbers[0],
			hardwareRevision: ''
		};
		try {
			let res = await ApiRequestV1.postPartAssemble(request);
			if (res) {
				if (allTasksCompleted) {
					rsToastify.success('Successfully saved!', 'Saved Tablet');
				} else {
					rsToastify.warning('Saved current progress. Tests are incomplete.', 'Saved Tablet');
				}
			}
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function renderRightHeaderNode() {
		return (
			<Box display={'flex'} gap={8}>
				{isModified && isQuickLookupSelected && serialNumber && (
					<>
						<Button look={'outlinedPrimary'} onClick={handleDiscard}>
							Discard
						</Button>
						<Button look={'outlinedPrimary'} onClick={handleSave}>
							Save
						</Button>
						<Button look={'containedPrimary'} onClick={handleSaveAndClose}>
							Save & Close
						</Button>
					</>
				)}
			</Box>
		);
	}

	function renderForm() {
		return (
			<>
				{isModified && isQuickLookupSelected && serialNumber && (
					<Box className={'tabletFormContainer'}>
						<Box className={'dividerLine'} />
						<Box className={'checklistContainer'}>
							<Label variant={'subheader2'} weight={'regular'}>
								Setup Checklist
							</Label>
							<Box className={'checkBoxContainer'}>
								<Checkbox
									height={40}
									look={'containedPrimary'}
									labelText={'Load tablet image'}
									control={checkListGroup.get(FormKeys.LOAD_TABLET_IMAGE)}
									updateControl={handleUpdateCheckListControl}
								/>
							</Box>
							<Box className={'checkBoxContainer'}>
								<Checkbox
									height={40}
									look={'containedPrimary'}
									labelText={'Activate windows key'}
									control={checkListGroup.get(FormKeys.ACTIVATE_WINDOWS)}
									updateControl={handleUpdateCheckListControl}
								/>
							</Box>
							<Box className={'checkBoxContainer'}>
								<Checkbox
									height={40}
									look={'containedPrimary'}
									labelText={'Change screen resolution to 1440x900'}
									control={checkListGroup.get(FormKeys.UPDATE_SCREEN_RESOLUTION)}
									updateControl={handleUpdateCheckListControl}
								/>
							</Box>
							<Box className={'checkBoxContainer'}>
								<Checkbox
									height={40}
									look={'containedPrimary'}
									labelText={'Change scaling to 100% and sign out to set'}
									control={checkListGroup.get(FormKeys.UPDATE_DISPLAY_SCALING)}
									updateControl={handleUpdateCheckListControl}
								/>
							</Box>
							<Box className={'checkBoxContainer'}>
								<Checkbox
									height={40}
									look={'containedPrimary'}
									labelText={'Add operating manual pdf to the desktop'}
									control={checkListGroup.get(FormKeys.ADD_OPERATING_MANUAL)}
									updateControl={handleUpdateCheckListControl}
								/>
							</Box>
							<Box className={'checkBoxContainer'}>
								<Checkbox
									look={'containedPrimary'}
									labelText={'Verify R1GCS is the latest version'}
									control={checkListGroup.get(FormKeys.VERIFY_VERSION)}
									updateControl={handleUpdateCheckListControl}
								/>
							</Box>
							<Box className={'checkBoxContainer'}>
								<Checkbox
									look={'containedPrimary'}
									labelText={'Does the customer order include Simulation mode?'}
									control={checkListGroup.get(FormKeys.VERIFY_SIMULATION_PURCHASED)}
									updateControl={handleUpdateSimState}
									disabled={true}
									checked={checkListGroup.get(FormKeys.VERIFY_SIMULATION_PURCHASED).value === true}
								/>
								<Select<{ label: string; value: string }>
									isCreatable
									className={'simulationPurchasedSelect'}
									//labelTitle={'Location'}
									placeholder={'Select'}
									options={[
										{ label: 'Yes', value: 'Yes' },
										{ label: 'No', value: 'No' }
									]}
									control={checkListGroup.get(FormKeys.SIMULATION_PURCHASED)}
									updateControl={handleUpdateSimState}
								/>
							</Box>
							<Box className={'checkBoxContainer'}>
								<Checkbox
									look={'containedPrimary'}
									labelText={'Is Simulation mode key installed?'}
									control={checkListGroup.get(FormKeys.VERIFY_SIMULATION_ENABLED)}
									updateControl={handleUpdateSimState}
									disabled={true}
									checked={checkListGroup.get(FormKeys.VERIFY_SIMULATION_ENABLED).value === true}
								/>
								<Select<{ label: string; value: string }>
									isCreatable
									className={'simulationEnabled'}
									//labelTitle={'Location'}
									placeholder={'Select'}
									options={[
										{ label: 'Yes', value: 'Yes' },
										{ label: 'No', value: 'No' }
									]}
									control={checkListGroup.get(FormKeys.SIMULATION_ENABLED)}
									updateControl={handleUpdateSimState}
								/>
							</Box>
							<Box className={'checkBoxContainer'}>
								<Checkbox
									look={'containedPrimary'}
									labelText={'Ran Simulation to verify functionality?'}
									control={checkListGroup.get(FormKeys.VERIFY_SIMULATION_TESTED)}
									updateControl={handleUpdateSimState}
									disabled={true}
									checked={checkListGroup.get(FormKeys.VERIFY_SIMULATION_TESTED).value === true}
								/>
								<Select<{ label: string; value: string }>
									isCreatable
									className={'simulationEnabled'}
									//labelTitle={'Location'}
									placeholder={'Select'}
									options={[
										{ label: 'Yes', value: 'Yes' },
										{ label: 'N/A', value: 'No' }
									]}
									control={checkListGroup.get(FormKeys.SIMULATION_TESTED)}
									updateControl={handleUpdateSimState}
								/>
							</Box>
							<Box className={'checkBoxContainer'}>
								<Checkbox
									look={'containedPrimary'}
									labelText={'Connect to vehicle and check tethering'}
									control={checkListGroup.get(FormKeys.CONNECT_VEHICLE)}
									updateControl={handleUpdateCheckListControl}
								/>
							</Box>
							<Box className={'checkBoxContainer'}>
								<Checkbox
									look={'containedPrimary'}
									labelText={'Add friendly name'}
									control={checkListGroup.get(FormKeys.ADD_FRIENDLY_NAME)}
									updateControl={handleUpdateCheckListControl}
								/>
								<InputText
									inputMode={'text'}
									placeholder={'Friendly Name'}
									control={checkListGroup.get(FormKeys.FRIENDLY_NAME)}
									updateControl={handleUpdateCheckListControl}
								/>
							</Box>
							<Box className={'checkBoxContainer'}>
								<Checkbox
									look={'containedPrimary'}
									labelText={'Wipe clean of finger prints, visually inspect, and repackage'}
									control={checkListGroup.get(FormKeys.CLEAN_AND_INSPECT)}
									updateControl={handleUpdateCheckListControl}
								/>
							</Box>
						</Box>
						<Box className={'dividerLine'} />
						<Box className={'notesContainer'} display={'grid'} gap={16}>
							<Label variant={'subheader2'} weight={'regular'}>
								Notes
							</Label>
							<InputTextarea
								control={formGroup.get(FormKeys.NOTES)}
								updateControl={handleUpdateFormControl}
							/>
						</Box>
					</Box>
				)}
			</>
		);
	}

	return (
		<Page className={'rsTabletSetupPage'}>
			<PageHeader
				title={'Tablet Setup'}
				rightNode={renderRightHeaderNode()}
				isModified={!!(isModified && isQuickLookupSelected && serialNumber)}
			/>
			<Box className={'content'}>
				<Link path={'/tablet-setup'} className={'workInstructionsLink'}>
					<Label variant={'body1'} weight={'regular'}>
						Work Instructions
					</Label>
				</Link>
				<Box>
					<SelectableInputText
						control={formGroup.get(FormKeys.SERIAL_NUMBER)}
						updateControl={(updatedControl) => {
							handleUpdateFormControl(updatedControl);
						}}
						labelTitle={'Tablet'}
						isSelected={isQuickLookupSelected}
						onBlurOrEnter={handleQuickLookUp}
						onClick={() => {}}
					/>
				</Box>
				{renderForm()}
			</Box>
		</Page>
	);
};

export default TabletSetupPage;
