import http from '../../utils/http';
import { Service } from '../Service';
const pre = '/report';

export default class ReportService extends Service {
	async downloadCSV(data: CustomTypes.CustomCsvReportRequest, reportName: string): Promise<any> {
		const response = await http.post<Blob, CustomTypes.CustomCsvReportRequest>(`${pre}`, data);
		const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
		const link = document.createElement('a');
		link.href = url;
		console.log(response.headers);
		link.setAttribute('download', `${reportName}.csv`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
}
