import * as React from 'react';
import './ConfirmPopup.scss';
import { Box, Button, Label, Popup, popupController, PopupProps } from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';

export interface ConfirmPopupProps extends PopupProps {
	title: string;
	onConfirm: () => void;
	message: string;
	onCancel?: () => void;
	confirmButtonText?: string;
	closeButtonText?: string;
	disableAutoClose?: boolean;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = (props) => {
	const fullProps = popupController.convertProps(props);

	function handleCancel() {
		if (props.onCancel) props.onCancel();
		if (!props.disableAutoClose) popupController.closeById(fullProps.popupId);
	}

	function handleConfirm() {
		if (props.onConfirm) props.onConfirm();
		if (!props.disableAutoClose) popupController.closeById(fullProps.popupId);
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsConfirmPopup'}>
				<PopupHeader title={props.title} onClose={handleCancel} />
				<Label variant={'body1'} weight={'regular'} mt={24}>
					{props.message}
				</Label>
				<Box display={'flex'} gap={16} mt={24}>
					<Button look={'outlinedPrimary'} onClick={handleCancel} fullWidth>
						{props.closeButtonText || 'Cancel'}
					</Button>
					<Button look={'containedPrimary'} onClick={handleConfirm} fullWidth>
						{props.confirmButtonText || 'Confirm'}
					</Button>
				</Box>
			</Box>
		</Popup>
	);
};
export default ConfirmPopup;
