import { Box, Icon, Popup, popupController, PopupProps } from '@redskytech/framework/ui';
import * as React from 'react';
import { useEffect } from 'react';
import router from '../../utils/router';
import './FamilyTreePopup.scss';
import FamilyTreeTile from './familyTreeTile/FamilyTreeTile';

export interface FamilyTreePopupProps extends PopupProps {
	familyTreeData: CustomTypes.PartFamilyTreeResponse['familyTree'];
	currentViewedItemId: number;
}

const FamilyTreePopup: React.FC<FamilyTreePopupProps> = (props) => {
	useEffect(() => {
		const id = router.subscribeToAfterRouterNavigate(() => {
			popupController.closeAll();
		});

		return () => {
			router.unsubscribeFromAfterRouterNavigate(id);
		};
	}, []);

	function renderFamilyTree() {
		if (!props.familyTreeData) return;

		const family = props.familyTreeData;
		const isCurrentView = family.id === props.currentViewedItemId;
		return (
			<ul>
				<FamilyTreeTile
					familyTreeData={family}
					onClick={() => router.navigate(`/assembly/details?pi=${family.id}`)}
					isCurrentView={isCurrentView}
				/>
				{family?.children && family.children.length > 0 && buildFamilyTree(family.children)}
			</ul>
		);
	}

	function buildFamilyTree(family: CustomTypes.PartFamilyTreeResponse['familyTree'][]) {
		return family
			.map((subFamily) => {
				if (!subFamily) return null;
				const isCurrentView = subFamily.id === props.currentViewedItemId;

				return (
					<ul>
						<li key={`${subFamily.partNumber}_${subFamily.id}`}>
							<FamilyTreeTile
								familyTreeData={subFamily}
								onClick={() => router.navigate(`/assembly/details?pi=${subFamily.id}`)}
								isCurrentView={isCurrentView}
							/>
							{subFamily.children && subFamily.children.length > 0 && buildFamilyTree(subFamily.children)}
						</li>
					</ul>
				);
			})
			.filter(Boolean);
	}

	return (
		<Popup {...props}>
			<Box className={'rsFamilyTreePopup'}>
				<Icon iconImg={'icon-close'} onClick={() => popupController.closeAll()} cursorPointer />
				{renderFamilyTree()}
			</Box>
		</Popup>
	);
};

export default FamilyTreePopup;
