import * as React from 'react';
import { Box } from '@redskytech/framework/ui';
import EvaluationItem, {
	EvaluationOptions,
	EvaluationGroup
} from '../../../../components/evaluationItem/EvaluationItem';
import { useEffect, useState } from 'react';
import { TestKey } from '../FlightTestSection';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import ServiceFactory from '../../../../services/serviceFactory';

interface PayloadVideoQualityProps {}

type PayloadVideoEvaluationGroup = EvaluationGroup & {
	irVideoNoBlur: EvaluationOptions | undefined;
	eoVideoNoBlur: EvaluationOptions | undefined;
	irVideoNoSnow: EvaluationOptions | undefined;
	eoVideoNoSnow: EvaluationOptions | undefined;
};

type EvaluationKey = keyof PayloadVideoEvaluationGroup;

const PayloadVideoQuality: React.FC<PayloadVideoQualityProps> = () => {
	const TEST_NAME: TestKey = 'payloadVideoQuality';
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const [evaluations, setEvaluations] = useState<PayloadVideoEvaluationGroup>({
		irVideoNoBlur: res ? res.data['irVideoNoBlur'] : undefined,
		eoVideoNoBlur: res ? res.data['eoVideoNoBlur'] : undefined,
		irVideoNoSnow: res ? res.data['irVideoNoSnow'] : undefined,
		eoVideoNoSnow: res ? res.data['eoVideoNoSnow'] : undefined
	});

	useEffect(() => {
		testFixtureService.validateEvaluationTest(TEST_NAME, evaluations);
	}, [evaluations]);

	function handleEvaluationClick(evaluation: EvaluationOptions, key: EvaluationKey) {
		setEvaluations((prevState) => {
			let updatedState = { ...prevState };
			updatedState[key] = evaluation;
			return updatedState;
		});
	}

	return (
		<Box className={'rsPayloadVideoQuality'} display={'flex'} flexDirection={'column'} gap={16}>
			<EvaluationItem
				label={'Verify no IR video blur during min. watts flight and high-speed box flight'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'irVideoNoBlur');
				}}
				evaluation={evaluations.irVideoNoBlur}
			/>
			<EvaluationItem
				label={'No Snowy Video in IR'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'irVideoNoSnow');
				}}
				evaluation={evaluations.irVideoNoSnow}
			/>
			<EvaluationItem
				label={'Verify no EO video blur during both high-speed engagements and disengagements'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'eoVideoNoBlur');
				}}
				evaluation={evaluations.eoVideoNoBlur}
			/>
			<EvaluationItem
				label={'No Snowy Video in EO'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'eoVideoNoSnow');
				}}
				evaluation={evaluations.eoVideoNoSnow}
			/>
		</Box>
	);
};
export default PayloadVideoQuality;
