import * as React from 'react';
import './UserBadge.scss';
import { Label, Avatar } from '@redskytech/framework/ui';
import colors from '../../themes/colors.scss?export';

interface UserBadgeProps {
	userName: string;
	superText: string;
	imageUrl?: string;
}

const UserBadge: React.FC<UserBadgeProps> = (props) => {
	return (
		<div className={'rsUserBadge'}>
			<Avatar
				widthHeight={40}
				image={props.imageUrl}
				name={props.userName}
				color={colors.neutralGrey900}
				bgColor={colors.primaryBlue100}
			/>
			<Label className={'role'} variant={'caption1'} weight={'regular'} color={colors.primaryBlue100}>
				{props.superText}
			</Label>
			<Label className={'userName'} variant={'body1'} weight={'regular'} color={colors.neutralWhite}>
				{props.userName}
			</Label>
		</div>
	);
};

export default UserBadge;
