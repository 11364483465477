import * as React from 'react';
import './FlightTestSection.scss';
import VerifyEsadReady from './flightTests/VerifyEsadReady';
import VehicleHeightSensorTesting from './flightTests/VehicleHeightSensorTesting';
import LoadFlightLog from './flightTests/LoadFlightLog';
import HighSpeedBoxFlight from './flightTests/HighSpeedBoxFlight';
import PayloadVideoQuality from './flightTests/PayloadVideoQuality';
import CameraInspection from './flightTests/CameraInspection';
import MagSpin from './flightTests/MagSpin';
import WindSpeed from './flightTests/WindSpeed';
import RunPreflight from './flightTests/RunPreflight';
import HighSpeedEngagements from './flightTests/HighSpeedEngagement';
import LoadVideo from './flightTests/LoadVideo';
import MinWattsProfileFlight from './flightTests/MinWattsProfileFlight';
import ReviewPlottingResults from './flightTests/ReviewPlottingResults';
import MagCorrectionValidation from './flightTests/MagCorrectionValidation';
import VehicleRadioRangeTesting from './flightTests/VehicleRadioRangeTesting';

export type VehiclePreFlightTestKey = 'windSpeed' | 'cameraInspection';

export type VehicleFlightTestKey =
	| 'runPreflight'
	| 'magSpin'
	| 'verifyEsadTransitionToWam'
	| 'magCorrectionValidation'
	| 'tenMeterHeightSensorTesting'
	| 'eightyMeterHeightSensorTesting'
	| 'radioRangeTesting'
	| 'minWattsProfileFlight'
	| 'highSpeedBoxFlight'
	| 'highSpeedEngagements';

export type VehiclePostFlightTestKey = 'loadVideo' | 'loadFlightLog' | 'reviewPlottingResults' | 'payloadVideoQuality';

export type VehiclePostFlightTestKeyNoVideo = 'loadFlightLog' | 'reviewPlottingResults' | 'payloadVideoQuality';

export type VehicleTestKey = VehiclePreFlightTestKey | VehicleFlightTestKey | VehiclePostFlightTestKey;

const vehicleTestKeys: VehicleTestKey[] = [
	'windSpeed',
	'cameraInspection',
	'runPreflight',
	'magSpin',
	'verifyEsadTransitionToWam',
	'magCorrectionValidation',
	'tenMeterHeightSensorTesting',
	'eightyMeterHeightSensorTesting',
	'radioRangeTesting',
	'minWattsProfileFlight',
	'highSpeedBoxFlight',
	'highSpeedEngagements',
	'loadVideo',
	'loadFlightLog',
	'reviewPlottingResults',
	'payloadVideoQuality'
];

type AssertAllKeys<T extends readonly string[]> = T[number] extends VehicleTestKey ? true : never;
const assertAllKeys: AssertAllKeys<typeof vehicleTestKeys> = true;

export { vehicleTestKeys };

export const vehiclePreFlightTestData: { [key in VehiclePreFlightTestKey]: JSX.Element } = {
	windSpeed: <WindSpeed minMax={[0.0, 10.0]} />,
	cameraInspection: <CameraInspection />
};

export const vehicleInFlightTestData: { [key in VehicleFlightTestKey]: JSX.Element } = {
	runPreflight: <RunPreflight key={'vehPreflight'} targetPayloadType={'TRAINER_PAYLOAD_ASSEMBLY'} />,
	magSpin: <MagSpin />,
	verifyEsadTransitionToWam: <VerifyEsadReady />,
	magCorrectionValidation: <MagCorrectionValidation />,
	tenMeterHeightSensorTesting: (
		<VehicleHeightSensorTesting
			targetHeight={10}
			absHeightErrorMax={5}
			relativeError={[-2, 2]}
			testKey={'tenMeterHeightSensorTesting'}
		/>
	),
	eightyMeterHeightSensorTesting: (
		<VehicleHeightSensorTesting
			targetHeight={80}
			absHeightErrorMax={10.0}
			relativeError={[-5, 3]}
			testKey={'eightyMeterHeightSensorTesting'}
		/>
	),
	radioRangeTesting: (
		<VehicleRadioRangeTesting
			sideAbsRssiBestWorst={[85, 93]}
			alignedAbsRssiBestWorst={[80, 80]}
			frontToBackRssiDeltaMax={3}
			leftToRightRssiDeltaMin={4}
			throughputMinMax={[-1000, 1000]}
			absoluteRssiBestThreshold={50}
		/>
	),
	minWattsProfileFlight: <MinWattsProfileFlight />,
	highSpeedBoxFlight: <HighSpeedBoxFlight />,
	highSpeedEngagements: <HighSpeedEngagements />
};

export const vehiclePostFlightTestData: { [key in VehiclePostFlightTestKey]: JSX.Element } = {
	loadVideo: <LoadVideo />,
	loadFlightLog: <LoadFlightLog />,
	reviewPlottingResults: <ReviewPlottingResults />,
	payloadVideoQuality: <PayloadVideoQuality />
};

export const vehiclePostFlightTestDataNoVideo: { [key in VehiclePostFlightTestKeyNoVideo]: JSX.Element } = {
	loadFlightLog: <LoadFlightLog />,
	reviewPlottingResults: <ReviewPlottingResults />,
	payloadVideoQuality: <PayloadVideoQuality />
};

export const vehicleFlightTestData = {
	...vehiclePreFlightTestData,
	...vehicleInFlightTestData,
	...vehiclePostFlightTestData
};

export const vehicleFlightTestDataNoVideo = {
	...vehiclePreFlightTestData,
	...vehicleInFlightTestData,
	...vehiclePostFlightTestDataNoVideo
};

// Adjust the flightTestData object to include the appropriate test list based on the current HWID
