import * as React from 'react';
import './FlightTestSection.scss';
import FcuGpsValidation from './flightTests/FcuGpsValidation';
import RadioRangeTesting from './flightTests/RadioRangeTesting';
import FcuCameraInspection from './flightTests/FcuCameraInspection';
export type FcuPreFlightTestKey = 'cameraInspection' | 'gpsValidation';

export type FcuFlightTestKey = 'radioRangeTesting';

export type FcuTestKey = FcuPreFlightTestKey | FcuFlightTestKey;

const fcuTestKeys: FcuTestKey[] = ['cameraInspection', 'gpsValidation', 'radioRangeTesting'];

type AssertAllKeys<T extends readonly string[]> = T[number] extends FcuTestKey ? true : never;
const assertAllKeys: AssertAllKeys<typeof fcuTestKeys> = true;

export { fcuTestKeys };

export const fcuPreFlightTestData: { [key in FcuPreFlightTestKey]: JSX.Element } = {
	cameraInspection: <FcuCameraInspection />,
	gpsValidation: <FcuGpsValidation satellitesMinMax={[10, 60]} />
};

export const fcuInFlightTestData: { [key in FcuFlightTestKey]: JSX.Element } = {
	radioRangeTesting: (
		<RadioRangeTesting
			sideLeftAbsRssiBestWorstFcu={[87, 95]}
			sideRightAbsRssiBestWorstFcu={[87, 95]}
			alignedAbsRssiBestWorstFcu={[92, 92]}
			frontToBackRssiDeltaMaxFcu={10}
			leftToRightRssiDeltaMinFcu={0}
			sideAbsRssiBestWorst={[87, 95]}
			alignedAbsRssiBestWorst={[92, 92]}
			frontToBackRssiDeltaMax={10}
			leftToRightRssiDeltaMin={0}
			throughputMinMax={[-1000, 1000]}
			absoluteRssiBestThreshold={50}
		/>
	)
};

export const fcuFlightTestData = {
	...fcuPreFlightTestData,
	...fcuInFlightTestData
};

// Adjust the flightTestData object to include the appropriate test list based on the current HWID
