import * as React from 'react';
import './AssignCarrierPopup.scss';
import {
	Box,
	Button,
	Label,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	rsToastify
} from '@redskytech/framework/ui';
import { CarrierType } from '../../services/calibration/ICalibrationService';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import SelectableInputText from '../../components/selectableInputText/SelectableInputText';
import { useRef, useState } from 'react';
import serviceFactory from '../../services/serviceFactory';

export interface AssignCarrierPopupProps extends PopupProps {
	carrierType: CarrierType;
	carrierPosition: number;
	hwid?: string;
	onDone: (carrier: Api.V1.Carrier.By.Numbers.Get.Res | undefined) => void;
}

const AssignCarrierPopup: React.FC<AssignCarrierPopupProps> = (props) => {
	const assemblyService = serviceFactory.get('AssemblyService');
	const calibrationService = serviceFactory.get('CalibrationService');

	const [inputControl, setInputControl] = useState<RsFormControl<string>>(
		new RsFormControl<string>('carrier', props.hwid || '')
	);
	const [showAssembleCarrierButton, setShowAssembleCarrierButton] = useState<boolean>(false);
	const [carrier, setCarrier] = useState<Api.V1.Carrier.By.Numbers.Get.Res | undefined>();
	const doneButtonRef = useRef<HTMLButtonElement>(null);

	async function handleBlurOrEnter(value: string, enterPressed: boolean): Promise<'VALID' | string> {
		setCarrier(undefined);
		setShowAssembleCarrierButton(false);

		if (!value) {
			return 'VALID';
		}

		// Make sure this is a valid part for this carrier
		// First get base part type
		const hardwareIdDecoded = assemblyService.decodeHardwareId(value);
		if (!hardwareIdDecoded) {
			rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
			return 'Invalid Hardware ID';
		}

		let carrierTypeFromPartNumber = calibrationService.getCarrierTypeFromPartNumber(hardwareIdDecoded.partNumber);
		if (!carrierTypeFromPartNumber) {
			rsToastify.error(`Invalid Part number entered.`, 'Invalid Part Number');
			return `Invalid Part number entered.`;
		}

		// Check if this part is allowed for this carrier type
		if (carrierTypeFromPartNumber !== props.carrierType) {
			rsToastify.error(`Carrier type does not match slot type chosen.`, 'Invalid Carrier Type');
			return `Carrier is not an ${props.carrierType} carrier. Correct hardware identifier or close this popup and select the correct tray position.`;
		}

		// Now go see if this part is in our database
		const foundCarrier = await calibrationService.lookupCarrierByNumbers(
			hardwareIdDecoded.partNumber,
			hardwareIdDecoded.serialNumber
		);
		if (!foundCarrier) {
			rsToastify.error(
				`Carrier not found in database. Correct hardware identifier or assemble the carrier.`,
				'Invalid Carrier Type'
			);
			setShowAssembleCarrierButton(true);
			return `Carrier not found in database. Correct hardware identifier or assemble the carrier.`;
		}

		setCarrier(foundCarrier);

		if (enterPressed && doneButtonRef.current) {
			doneButtonRef.current.focus();
		}

		return 'VALID';
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick className={'rsAssignCarrierPopupContainer'}>
			<Box className={'rsAssignCarrierPopup'}>
				<PopupHeader
					title={`${props.carrierType} Carrier Tray ${props.carrierPosition}`}
					onClose={() => {
						popupController.close(AssignCarrierPopup);
					}}
				/>
				<Label variant={'body1'} weight={'regular'}>
					Scan hardware identifier and insert in {props.carrierType} slot {props.carrierPosition}.
				</Label>
				<SelectableInputText
					control={inputControl}
					updateControl={(updatedControl) => setInputControl(updatedControl)}
					labelTitle={`${props.carrierType} Carrier Tray ${props.carrierPosition}`}
					isSelected={true}
					onBlurOrEnter={handleBlurOrEnter}
					onClick={() => {}}
				/>
				{showAssembleCarrierButton && (
					<Button look={'outlinedPrimary'} fullWidth onClick={() => {}}>
						Assemble Carrier
					</Button>
				)}
				<Box className={'buttonGroup'}>
					<Button
						look={'outlinedPrimary'}
						onClick={() => popupController.close(AssignCarrierPopup)}
						fullWidth
					>
						Cancel
					</Button>
					<Button
						elementRef={doneButtonRef}
						look={'containedPrimary'}
						onClick={() => {
							if (!inputControl.value) props.onDone(undefined);
							else props.onDone(carrier!);
							popupController.close(AssignCarrierPopup);
						}}
						fullWidth
						disabled={!carrier}
					>
						Done
					</Button>
				</Box>
			</Box>
		</Popup>
	);
};

export default AssignCarrierPopup;
